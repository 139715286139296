// src/pages/UserProfilePage.tsx
import React, { useEffect, useState } from 'react';
import { Layout, Card, Form, Input, Button, Row, Col, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import useReduxUser from '../../hoc/hooks/useUser';
import { IoWalletOutline } from "react-icons/io5";
import Text from '../../style/styledComponent/Text';
import useReduxDrawer from '../../hoc/hooks/useDrawer';
import { ModalTopUp } from '../../shared/modal';
const { Content } = Layout;

export const UserProfilePage: React.FC = () => {
  const [form] = Form.useForm();
  const [, setLoading] = useState(false);
  const { user, getDetailUser,dataUser } = useReduxUser();
  const { ModalTopUpOpen, isOpenModalTopUp } = useReduxDrawer();

  const fetchGetUser= async () => {
    setLoading(true);
    try {
      const userId = user?.uid
      await getDetailUser(userId);
    } catch (error) {
      // console.error('Error:', error);
      // message.error('Failed to fetch API keys');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleFinish = (values: any) => {
    setLoading(true);
    // Simulate a server request
    setTimeout(() => {
      // console.log('Updated user profile:', values);
      setLoading(false);
    }, 1000);
  };

  const onClickModalTopUp = () => {
    ModalTopUpOpen(true);
  };

  const avatarUrl = dataUser?.photoURL;
  return (
    <Layout style={{ padding: '44px', minHeight: '100vh',background: "#272B34", }}>
      <Content >
        <Card style={{ marginBottom: '24px', background: "#363A45", color:'white' }}>
          <Row gutter={16}>
            <Col span={10} style={{textAlign:'center'}}>
             
            <div style={{ color:'white', border: '1px solid #ccc', padding: '20px',alignContent:'center', height: '100%', borderRadius:'8px' }}>
                <Text style={{ color: 'white', marginBottom: '10px', fontSize: '20px',fontWeight:600 }}>Account Balance</Text>
                <div style={{ display: 'flex', alignItems: 'center',marginBottom: '10px',placeContent:'center' }}>
                  <IoWalletOutline size={40} style={{ color: 'white', fontSize: '20px', marginRight: '10px' }} />
                  <Text style={{ color: 'white', fontSize: '20px' }}>{`Rp. ${dataUser?.balance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                </div>
                <Button type="primary" onClick={onClickModalTopUp}>
                  Top Up Balance
                </Button>
              </div>
            </Col>
            <Col span={13} style={{textAlign:'center'}}>
            {
              !user ?   <Avatar size={120} icon={<UserOutlined />} /> :
                <Avatar size={120} src={avatarUrl} />
              }
              <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                initialValues={{
                  username: dataUser?.displayName,
                  email: dataUser?.email,
                  phoneNumber: dataUser?.phoneNumber || '-'
               
                }}
              >
                <Form.Item
                  name="username"
                  label={<Text>User Name</Text>}
                  rules={[{ message: 'Please input your username!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={<Text>Email</Text>}
                  rules={[{ message: 'Please input your email!' }, { type: 'email', message: 'The input is not valid E-mail!' }]}
                >
               <Input />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  label={<Text>Number</Text>}
                  rules={[{ message: 'Please input your first name!' }]}
                >
            <Input />
                </Form.Item>
           
              </Form>
            </Col>
          </Row>
        </Card>
      </Content>
      <ModalTopUp visibled={isOpenModalTopUp}/>
    </Layout>
  );
};

