import React, { useState } from 'react';
import { Space, Button, Tooltip } from 'antd';
import { LuLayers } from 'react-icons/lu';
import { CompBaseMaps } from '../baseMapsTools';
import TourGuide from '../tour/tour';
interface DrawToolsSpaceProps {
    drawerOpen: boolean;
}

export const BaseMapsTools: React.FC<DrawToolsSpaceProps> = ({ drawerOpen }) => {
    const [showBaseMaps, setShowBaseMaps] = useState(false);

    const handleBaseMapsButtonClick = () => {
        setShowBaseMaps(!showBaseMaps);
    };
    return (
        <React.Fragment>

            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    left: drawerOpen ? '390px' : '15px',
                    top: 92,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip
                    title={<p style={{color:'black'}}>BaseMap</p>}
					placement="right"
					color='white'
                >
                    <Button
                      id='feature-1'
                        style={{
                            backgroundColor: 'white', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "30px",
                            width: "32px",
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                        icon={
                            <span className='anticon'>
                                <LuLayers />
                            </span>
                        }
                        onClick={handleBaseMapsButtonClick}
                    >
                    </Button>
                </Tooltip>
            </Space>
             {showBaseMaps && <CompBaseMaps drawerOpen={drawerOpen} />}
             <TourGuide/>
        </React.Fragment>
    )
}