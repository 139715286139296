// store/pixel.store.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDrawPixelData, PixelData } from '../../interface';

interface PixelState {
  pixelData: [] | null;
  pixelcategory: PixelData | null;
  pixelSubcategory: PixelData | null;
  pixelDrawCategory : IDrawPixelData | null;
  geoParamsPixel : any | null;
  staticDataSample: any | null;
  staticDataInsightSample: any | null;
  staticDataSurveySample: any | null;
  fetching: boolean;
  error: boolean;
  isloading: boolean; // Add loading property
  paramsData: string;
}

const initialState: PixelState = {
  pixelData: null,
  pixelcategory: null,
  pixelSubcategory: null,
  pixelDrawCategory: null,
  geoParamsPixel: null,
  staticDataSurveySample: null,
  staticDataSample: null,
  staticDataInsightSample: null,
  fetching: false,
  error: false,
  isloading: true, // Initialize loading state
  paramsData: 'insight_data'
};

const pixelSlice = createSlice({
  name: 'pixel',
  initialState,
  reducers: {
    setPixelData: (state, action: PayloadAction<[]>) => {
      state.pixelData = action.payload;
    },
 
    fetchPixelDataStart: (state) => {
      state.isloading = true; // Set loading to true when fetching starts
      state.error = false;
    },
    fetchPixelDataSuccess: (state, action: PayloadAction<PixelData>) => {
      state.isloading = false; 
      state.pixelcategory = action.payload;
    },
    fetchPixelDataFailure: (state) => {
      state.isloading = false; 
      state.error = true;
    },
    fetchPixelSubCategoryDataSuccess: (state, action: PayloadAction<PixelData>) => {
      state.isloading = false; 
      state.pixelSubcategory = action.payload;
    },
    fetchPixelDrawDataSuccess: (state, action: PayloadAction<IDrawPixelData>) => {
      state.isloading = false; 
      state.pixelDrawCategory = action.payload;
    },
    fetchPixelByIdDataSuccess: (state, action: PayloadAction<any>) => {
      state.isloading = false; 
      state.geoParamsPixel = action.payload;
    },
    getDataSampleSuccess: ( state, action: PayloadAction<any>) => {
      state.isloading = false; 
      state.staticDataSample = action.payload;
    },
    getDataSampleInsightSuccess: ( state, action: PayloadAction<any>) => {
      state.isloading = false; 
      state.staticDataInsightSample = action.payload;
    },
    getDataSampleSurveySuccess: ( state, action: PayloadAction<any>) => {
      state.isloading = false; 
      state.staticDataSurveySample = action.payload;
    },
    getParamsData: (state, action: PayloadAction<any>) => {
      state.paramsData = action.payload;
    },
  },
  
  
});

export const { 
  setPixelData, 
  fetchPixelSubCategoryDataSuccess,
  fetchPixelDataStart,
  fetchPixelDataSuccess,
  fetchPixelDataFailure,
  fetchPixelDrawDataSuccess,
  fetchPixelByIdDataSuccess,
  getDataSampleSuccess,
  getDataSampleInsightSuccess,
  getDataSampleSurveySuccess,
  getParamsData
 } = pixelSlice.actions;
 
export default pixelSlice.reducer;
