// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBP5LRKPwlWjhG1ETGDcIWylTWlUUa7jgg",
  authDomain: "petain-386503.firebaseapp.com",
  projectId: "petain-386503",
  storageBucket: "petain-386503.appspot.com",
  messagingSenderId: "152549214031",
  appId: "1:152549214031:web:b6b3d7dc3d1df38c5f673a",
  databaseURL:'https://petain-386503-default-rtdb.firebaseio.com',
  measurementId: "G-L9F8PCV1NJ"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
const auth = getAuth(firebaseApp);

export { db, auth, googleProvider, appleProvider };