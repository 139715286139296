import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../store/config.strore';
import paymentApi from '../../service/paymentApi';
import { fetchDataSucces, fetchDataFailure, fetchDataStart,createtopUpPayment } from '../store/payment.store';

export const paymentCreateInvoice = (data: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(fetchDataStart());
        const payment = await paymentApi.postPayment(data);
        dispatch(fetchDataSucces(payment));
        return payment; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error fetching pixel data:', error);
        dispatch(fetchDataFailure());
        throw error; // Ensure the error is thrown
    }
};

export const paymentTopUp = (token:string, data: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(fetchDataStart());
        const topUp = await paymentApi.topUpPayment(token, data);
        dispatch(createtopUpPayment(topUp));
        return topUp; // Ensure the payment data is returned
    } catch (error) {
        console.error('Error fetching topup data:', error);
        dispatch(fetchDataFailure());
        throw error; // Ensure the error is thrown
    }
};
