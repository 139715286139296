import React from 'react';
import { Space, Button, Tooltip } from 'antd';
import { PiPolygon } from "react-icons/pi"; 
interface DrawToolsSpaceProps {
    drawerOpen: boolean;
    onclickPolygon: () => void; // Definisikan properti onOpenDrawer
}

export const DrawMapsTools: React.FC<DrawToolsSpaceProps> = ({ drawerOpen, onclickPolygon }) => {
    return (
        <React.Fragment>

            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    left: drawerOpen ? '390px' : '15px',
                    top: 58,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip
                 title={<p style={{color:'black'}}>Draw Polygon</p>}
                 placement="right"
                 color='white'
                >
                    <Button
                        id='start-point'
                        style={{
                            backgroundColor: 'white', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "30px",
                            width: "32px",
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                        onClick={onclickPolygon}
                        icon={
                            <span className='anticon'>
                                <PiPolygon />
                            </span>
                        }
                    >
                    </Button>
                </Tooltip>
            </Space>
        </React.Fragment>
    )
}