/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Space, Button, Tooltip } from 'antd';

import useReduxDrawer from '../../hoc/hooks/useDrawer';
interface DrawToolsSpaceProps {
    drawerOpen: boolean;
}

export const CompBaseMaps: React.FC<DrawToolsSpaceProps> = ({ drawerOpen }) => {
	const { setBaseMaps } = useReduxDrawer ();
    const handleBasemapChange = (basemapUrl: string) => {
        setBaseMaps(basemapUrl) // Kirim aksi Redux untuk mengatur basemap baru
    };
    return (
        <React.Fragment>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    left: drawerOpen ? '440px' : '60px',
                    top: 92,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip
                    title="Light"
                    placement="top"
                >
                    <Button
                    disabled
                        style={{
                            backgroundColor: 'white', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "30px",
                            width: "32px",
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                        // eslint-disable-next-line jsx-a11y/alt-text
                        icon={
                            <img 
                                alt='white'
                                src='https://a.basemaps.cartocdn.com/rastertiles/light_all/4/13/7.png'
                                style={{
                                     width: 24, height: 24, borderRadius: 4 
                                    }} />
                            }
                            onClick={() => handleBasemapChange('https://a.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png')}
                    >
                    </Button>
                </Tooltip>
            </Space>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    left: drawerOpen ? '475px' : '95px',
                    top: 92,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip
                    title="OSM"
                    placement="top"
                >
                    <Button
                        disabled
                        style={{
                            backgroundColor: 'white', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "30px",
                            width: "32px",
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                        icon={
                            <img 
                                alt='OSM'
                                src='https://a.tile.openstreetmap.org/5/25/16.png'
                                style={{
                                     width: 24, height: 24, borderRadius: 4 
                                    }} />
                            }
                            onClick={() => handleBasemapChange('https://tile.openstreetmap.org/{z}/{x}/{y}.png')}
                    >
                    </Button>
                </Tooltip>
            </Space>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    left: drawerOpen ? '510px' : '130px',
                    top: 92,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip
                    title="google maps"
                    placement="top"
                >
                    <Button
                     disabled
                        style={{
                            backgroundColor: 'white', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "30px",
                            width: "32px",
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                        icon={
                            <img 
                                alt='Satelite'
                                src='https://mt0.google.com/vt/lyrs=s&x=25&y=16&z=5'
                                style={{
                                     width: 24, height: 24, borderRadius: 4 
                                    }} />
                            }
                            onClick={() => handleBasemapChange('https://mt0.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')}
                          
                    >
                    </Button>
                </Tooltip>
            </Space>
        </React.Fragment>
    )
}