import React from 'react';
import { Space } from 'antd';
import { ToogleMenu } from '../../shared/drawtools/toogle.menu';
import { MyData } from '../../shared/map/drawer/drawer.mydata';

export const MenuMyData: React.FC<any> = () => {
   

    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '120px',
                    top: '3%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div
                    style={{
                        background: '#1E334D',
                        border: '1px #d9d9d9',
                        color: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        width: '300px',
                        padding: '15px',
                        height: 'calc(100vh - 50px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                   <MyData/>

                    <ToogleMenu />
                </div>

            </Space>
        </React.Fragment>
    );
};
