import React from "react";
import Container from "../../../../style/styledComponent/Container";
import Text from "../../../../style/styledComponent/Text";
import { fadein } from "../../../../style/keyframes";
// import { useNavigate } from 'react-router-dom';
interface TitleProps {
    title: any;
    subTitle: any
 }
 export const Title: React.FC<TitleProps> = ({title, subTitle}) => {
    // const navigate = useNavigate();
    return (
        <Container
        dir={"column"}
        css={{
            textAlign:'left',
            width: "100%",
            paddingTop:"2rem",
            opacity: "0",
            animation: `1s ease-in 1s 1 both ${fadein}`,
        }}
    >
        <Text
            css={{
                color:"black",
                display3: "800",
                "@bp2": {
                    display2: "800",
                },
                "@bp4": {
                    display3: "800",
                },
                "@bp5": {
                    headline1: "800",
                },
            }}
        >
     {title}
        </Text>

        <Text
            css={{
                color:"black",
                paddingTop: "$1",
                paddingBottom:"$1",
                alignSelf:"left",
                width:"700px",
                headline5: "600",
                "@bp2": {
                    paddingTop: "$1",
                    subhead2: "500",
                    alignSelf:"left",
                },
                "@bp4": {
                    paddingTop: "$1",
                    alignSelf:"left",
                    subhead2: "500",
                },
                "@bp5": {
                    subhead2: "500",
                    width:"350px",
                    alignSelf:"left",
                },
            }}
        >
       {subTitle}
        </Text>

    </Container>
    
    )
}
