import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
    tourActionStart,
    tourActionNextStep,
    tourActionPrevStep,
    tourActionEnd
} from '../action/tour.action';

const useReduxTour = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
        stepIndex,
        tourVisible
    } = useSelector((state: RootState) => state.tour);


    const tourStart = async () => {
        dispatch(tourActionStart());
    };

    const tourNextStep = async () => {
        dispatch(tourActionNextStep());
    };

    const tourPrevStep = async () => {
        dispatch(tourActionPrevStep());
    };

    const tourEnd = async () => {
        dispatch(tourActionEnd());
    };


    return {
        stepIndex,
        tourVisible,
        tourStart,
        tourEnd,
        tourNextStep,
        tourPrevStep
    }; // Return pixelState as well if needed
};

export default useReduxTour;
