import { TableComponent } from '../../shared/table/tableData';
function SectionOrder() {
    return (
        <>
            <div style={{ padding: 44, background: "#272B34", }}>
                <TableComponent />
            </div>
        </>
    )
}
export default SectionOrder;