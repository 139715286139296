import { ThunkAction } from 'redux-thunk'
import catalogApi from '../../service/catalog'
import { Action } from 'redux'
import { RootState } from '../store/config.strore';
import {
    setCatalog,
    setCatalogGeometry,
    setCataloglocation,
    setCatalogStart
} from '../store/catalog.store';

export const getCatalogAction = (params: string, RequestBody: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogStart());
        const data: any = await catalogApi.getCatalogApi(params, RequestBody);
        if (data) {
            dispatch(setCatalog(data));
        }

    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const getCatalogGeometryAction = (RequestBody: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCatalogGeometry(RequestBody));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};

export const getCatalogLocationAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch(setCataloglocation(data));
    } catch (error) {
        console.error('Error fetching pixel data:', error);
    }
};