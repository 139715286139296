import React, { useState } from "react";
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
import Text from "../../../../style/styledComponent/Text";
import { IoIosPeople } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';
import { Title } from "../title/title";
import { LuMonitorSmartphone } from "react-icons/lu";

import mapid from "../../../../asesst/Icons/product/mapid.png"; 

const staticData= [
    {
        id: uuidv4(),
        title: 'MAPID',
        description: 'MAPID is a location intelligence platform that connects various location data from trusted sources. The term itself comes from a map where representation of areas are shown in physical features and id as country code of Indonesia.',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: mapid, // Uncomment if using another image,
        category: 'Platform GIS'
    },
];

// const categories = ['People Movement', 'Address', 'Demography'];
const categoriesData = [
    { name: 'Platform GIS', icon: <LuMonitorSmartphone size={20} /> },
];

export const CardCatalogCurated = () => {
    const [selectedCategories, setSelectedCategories] = useState<string[]>(categoriesData.map(cat => cat.name));

    const handleCheckboxChange = (category: string) => {
        setSelectedCategories(prevState =>
            prevState.includes(category)
                ? prevState.filter(cat => cat !== category)
                : [...prevState, category]
        );
    };

    const filteredData = staticData.filter(item => selectedCategories.includes(item.category));


    return (
        <>
            <Title title="Curated Platforms" subTitle="  Access the best in geospatial technology! Our Curated GIS Platforms offer top-tier solutions like ESRI and SuperMap, all available under the GeoSquare.ai umbrella for your convenience." />

            <div style={{
                 animation: `1s ease-in 1s 1 both ${fadein}`,
            }}>
                {/* <fieldset className="checkbox-group"> */}
                {/* <legend className="checkbox-group-legend">Choose</legend> */}
                {/* <div style={{ display: 'flex', gap: '20px' }}> */}
                <div className="category-grid" >
                    {categoriesData.map(category => (
                        <div className="checkbox" key={category.name}>
                            <label className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    checked={selectedCategories.includes(category.name)}
                                    onChange={() => handleCheckboxChange(category.name)}
                                />
                                <span className="checkbox-tile">
                                    <span className="checkbox-icon">
                                        {category.icon}
                                    </span>
                                    <span className="checkbox-label">{category.name}</span>
                                </span>
                            </label>
                        </div>
                    ))}
                </div>

                {/* </fieldset> */}
            </div>

            <Container
                css={{
                    display: "grid",
                    width: "100%",
                    paddingTop: '20px',
                    animation: `1s ease-in 1s 1 both ${fadein}`,
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gap: "1rem",
                    flex: "1"
                }}
            >
                {filteredData.map(item => (
                    <Container
                        key={item.id}
                        css={{
                            backgroundColor: 'white',
                            padding: '1rem',
                            borderRadius: '8px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            flexDirection: 'column',
                            alignItems: 'left',
                            textAlign: 'left',
                        }}
                    >
                       <img alt="mapid" src={mapid}  style={{
                        height:'100px',
                        width:"100px"
                       }}/>
                  
                        <Text
                            css={{
                                display: 'block',
                                headline4: '700',
                                marginBottom: '0.5rem',
                                color: '#3983e0',
                            }}
                        >
                            {item.title}
                        </Text>
                        <Text css={{ fontSize: '1rem', color: 'gray' }}>
                            {item.description}
                        </Text>
                    </Container>
                ))}
            </Container>
        </>
    );
};
