// store/map.store.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';

interface AppState {
  gjs: FeatureCollection | null;
  gjsProperties: number | null;
  isLoadingData: boolean;
  progressData: number
}

const initialState: AppState = {
  gjs: null,
  gjsProperties: 0,
  isLoadingData:false,
  progressData: 0
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setGjsData: (state, action: PayloadAction<FeatureCollection>) => {
      state.gjs = action.payload;
    },
    setGjsPropsData: (state, action: PayloadAction<number>) => {
      state.gjsProperties = action.payload;
    },
    setIsloadingData: (state, action: PayloadAction<any>) => {
      state.isLoadingData = action.payload;
    },
    setProgressData: (state, action: PayloadAction<any>) => {
      state.progressData = action.payload;
    },
  },
});

export const { setGjsData, setGjsPropsData, setIsloadingData,setProgressData } = appSlice.actions;
export default appSlice.reducer;
