
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

interface Api {
  post: <T>(url: string, data: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  get: <T>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
}

const geoAxios: AxiosInstance = axios.create({
  baseURL: 'https://geosquare-backend-56l5zknzoq-et.a.run.app',
  // baseURL: 'http://localhost:3000',
});

const beAxios: AxiosInstance = axios.create({
  baseURL: 'https://api.geosquare.ai', // Replace with the actual base URL for the catalog API
});

const api: Api = (() => {
  const post: Api['post'] = (url, data, config) => geoAxios.post(url, data, config);
  const get: Api['get'] = (url, config) => geoAxios.get(url, config);

  return {
    post,
    get,
  };
})();

const catalogApi: Api = (() => {
  const post: Api['post'] = (url, data, config) => beAxios.post(url, data, config);
  const get: Api['get'] = (url, config) => beAxios.get(url, config);

  return {
    post,
    get,
  };
})();

export { api, catalogApi };
