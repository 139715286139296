import React from 'react';
import { Space, Button, Tooltip } from 'antd';
import useReduxPixel from '../../hoc/hooks/usePixel';
import { PiPolygon } from "react-icons/pi";
interface DrawToolsSpaceProps {
  drawerOpen: boolean;

}


export const LegendTools: React.FC<DrawToolsSpaceProps> = ({ drawerOpen, }) => {
  const {
    pixelDrawCategory,

  } = useReduxPixel()

  let totalSquare = pixelDrawCategory?.data?.general?.business_vitality[0]?.price?.total_pixel ||
    pixelDrawCategory?.data?.people?.female[0]?.price?.total_pixel ||
    pixelDrawCategory?.data?.aerial_survey_mapping?.land_area_survey[0]?.price?.total_pixel

  function isCurency(totalSquare: any) {
    if (totalSquare) {
      return totalSquare.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      return 0
    }
  }

  return (
    <React.Fragment>
      <Space
        direction='vertical'
        style={{
          position: 'absolute',
          right: '15px',
          bottom: 24,
          zIndex: 100,
          transition: '0.25s ease-in-out',
        }}
      >
        <Tooltip title="Keterangan Tombol">
          <Button
            style={{
              height: '32px',
              width: '250px',
              backgroundColor: 'white', // Ubah warna tombol menjadi putih
              color: 'black', // Ubah warna teks menjadi hitam
            }}
            type="primary"

          >
            {pixelDrawCategory ? `${isCurency(totalSquare)} squares selected.` :
              (
                <span className='anticon'>
                  <PiPolygon /> {' '}  Draw Polygon First to Get Data
                </span>
              )
            }


            {/* 124.000 square selected. */}
          </Button>

        </Tooltip>

      </Space>
    </React.Fragment>
  )
}