export const legendType = [
    'people',
    'demography',
    'safety',
    'food_beverage',
    'retail',
    'amenity',
    'planning',
    'hi-res-demography',
    'accessibility',
    'address'
    // Add more types as needed
] as const;

type LegendType = typeof legendType[number];

export const LegendColors: Record<LegendType, { value: number; color: string }[]> = {
    'people': [
        { value: 1, color: '#110c1f' },
        { value: 2, color: '#21172c' },
        { value: 3, color: '#452442' },
        { value: 4, color: '#5a2a49' },
        { value: 5, color: '#6f304f' },
        { value: 6, color: '#993d53' },
        { value: 7, color: '#bd524d' },
        { value: 8, color: '#cb6147' },
        { value: 9, color: '#d87041' },
        { value: 10, color: '#e69431' },
        // Add more colors as needed
    ],
    'address': [
        { value: 1, color: '#440154' },
        { value: 2, color: '#472878' },
        { value: 3, color: '#3e4a89' },
        { value: 4, color: '#31688e' },
        { value: 5, color: '#26838e' },
        { value: 6, color: '#1e9e89' },
        { value: 7, color: '#35b779' },
        { value: 8, color: '#6cce59' },
        { value: 9, color: '#b4de2c' },
        { value: 10, color: '#fde725' },
        // Add more colors as needed
    ],
    'accessibility': [
        { value: 1, color: '#242119' },
        { value: 2, color: '#433427' },
        { value: 3, color: '#6e4433' },
        { value: 4, color: '#844c35' },
        { value: 5, color: '#9a5436' },
        { value: 6, color: '#b96e35' },
        { value: 7, color: '#d18c37' },
        { value: 8, color: '#e4ae3b' },
        { value: 9, color: '#f1d242' },
        { value: 10, color: '#f8f74c' },
        // Add more colors as needed
    ],
    'planning': [
        { value: 1, color: '#110c1f' },
        { value: 2, color: '#21172c' },
        { value: 3, color: '#452442' },
        { value: 4, color: '#5a2a49' },
        { value: 5, color: '#6f304f' },
        { value: 6, color: '#993d53' },
        { value: 7, color: '#bd524d' },
        { value: 8, color: '#cb6147' },
        { value: 9, color: '#d87041' },
        { value: 10, color: '#e69431' },
        // Add more colors as needed
    ],
    'demography': [
        { value: 1, color: '#3f3b6d' },
        { value: 2, color: '#5c4273' },
        { value: 3, color: '#88567b' },
        { value: 4, color: '#9a627f' },
        { value: 5, color: '#ab6e83' },
        { value: 6, color: '#c88a8c' },
        { value: 7, color: '#deaa9a' },
        { value: 8, color: '#e7bba5' },
        { value: 9, color: '#f0ccaf' },
        { value: 10, color: '#ffeecc' },
    ],
    'safety': [
        { value: 10, color: '#0b1a2e' },
        { value: 9, color: '#16293d' },
        { value: 8, color: '#2b485d' },
        { value: 7, color: '#36596e' },
        { value: 6, color: '#41697e' },
        { value: 5, color: '#598ca0' },
        { value: 4, color: '#74b0c0' },
        { value: 3, color: '#83c3d0' },
        { value: 2, color: '#91d6e0' },
        { value: 1, color: '#b2fdff' },
    ],
    'food_beverage': [
        { value: 1, color: '#4d2925' },
        { value: 2, color: '#956c56' },
        { value: 3, color: '#fff0b7' },
    ],
    'retail': [
        { value: 1, color: '#242119' },
        { value: 2, color: '#9a5436' },
        { value: 3, color: '#f8f74c' },
    ],
    'amenity': [
        { value: 1, color: '#4a3a4b' },
        { value: 2, color: '#6e434d' },
        { value: 3, color: '#8d4d50' },
        { value: 4, color: '#9b5453' },
        { value: 5, color: '#a95a56' },
        { value: 6, color: '#c2695d' },
        { value: 7, color: '#d77b67' },
        { value: 8, color: '#e79074' },
        { value: 8, color: '#f1a885' },
        { value: 8, color: '#f3c29a' },
    ],
    'hi-res-demography': [
        { value: 1, color: '#112255' },
        { value: 2, color: '#213876' },
        { value: 3, color: '#3d5090' },
        { value: 4, color: '#5d6aa1' },
        { value: 5, color: '#8184a8' },
        { value: 6, color: '#a4a0a3' },
        { value: 7, color: '#c5be91' },
        { value: 8, color: '#e4de6d' },
        { value: 8, color: '#ffff11' },

    ],
    // Add more legend types and their colors as needed
};