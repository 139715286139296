import React, { useEffect, useRef, useState } from 'react';
import { Button, Table, Input, Space,message,Alert,Tooltip } from 'antd';
import useReduxOneApi from '../../../../hoc/hooks/useOneApi';
import type { InputRef } from 'antd';
import { FaRegCopy } from "react-icons/fa6";

const { Search } = Input;

const columns = [
    {
        title: 'Data ID',
        dataIndex: 'data_id',
        key: 'data_id',
        render: (text: string) => (
          <span >
            <Alert
            style={{width:'200px'}}
              message={text}
              type="info"
              action={
                <Tooltip 
                title={<p style={{color:'black'}}>Copy Text</p>}
                placement="top"
                color='white'
                >
                <FaRegCopy
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigator.clipboard.writeText(text).then(() => {
                      message.success('Data ID copied to clipboard');
                    });
                  }}
                />
                       </Tooltip>
              }
            />
          </span>
        ),
      },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  // Add more columns as needed based on your data structure
];

export const TableCategory: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const { catalogData,isloading, getCatalogData } = useReduxOneApi();
  const searchInputRef = useRef<InputRef>(null); // Create a ref for the search input

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCatalogData(); // Ensure the catalog data is fetched
      } catch (error) {
        console.error('Failed to fetch geo transactions:', error);
      } 
    };

    fetchData();
  }, [getCatalogData]);

  useEffect(() => {
    if (catalogData) {
      setData(catalogData?.data);
      setFilteredData(catalogData?.data);
    }
  }, [catalogData]);

  const handleSearch = (value: string) => {
    const filtered = data.filter((item: any) =>
      Object.keys(item).some(key =>
        String(item[key]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const handleReset = () => {
    setFilteredData(data);
    if (searchInputRef.current && searchInputRef.current.input) {
      searchInputRef.current.input.value = ''; // Clear the search input value
    }
  };

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Search
          placeholder="Search..."
          onSearch={handleSearch}
          style={{ width: 200 }}
          ref={searchInputRef} // Attach the ref to the Search component
        />
        <Button onClick={handleReset}>Reset</Button>
      </Space>
      <Table  pagination={{ pageSize: 5 }} columns={columns} dataSource={filteredData} loading={isloading} rowKey="data_id" />
    </div>
  );
}
