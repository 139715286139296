import React, { useState } from "react";
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
import Text from "../../../../style/styledComponent/Text";
import insightCard from '../../../../asesst/Icons/product/business-scene-top-view.jpg';
import { IoIosPeople } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';
import { Title } from "../title/title";

// import { FaRegAddressBook } from "react-icons/fa";
import { FaHouseCircleCheck } from "react-icons/fa6";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { BsShop } from "react-icons/bs";


const staticData= [
    {
        id: uuidv4(),
        title: 'Business Vitality',
        description: 'An insight evaluating various factors to determine if a location is conducive to business activities, assessing potential for growth and profitability.',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'General'
    },
    {
        id: uuidv4(),
        title: 'Economic Prosperity',
        description: 'Level of economic development and potential for future growth within different areas, based on economic indicators and trends.',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'General'
    },
    {
        id: uuidv4(),
        title: 'Coffee Shop',
        description: 'Prime locations for general retail businesses.',
        icon: <BsShop size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Retail'
    },
    {
        id: uuidv4(),
        title: 'Potential Property Development',
        description: 'Optimal locations for property or real estate development by analyzing market trends, local regulations, and geographic desirability to minimize investment risks.',
        icon: <FaHouseCircleCheck size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Property'
    },
    {
        id: uuidv4(),
        title: 'Flood Risk Area',
        description: 'Evaluating financial risks using spatial parameters, offering insights on the geographical aspects that affect financial stability and opportunities, aiding in more secure investment decisions.',
        icon: <HiOutlineBanknotes size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Finance and Banking'
    },
   
];
const categoriesData = [
    { name: 'Finance and Banking', icon: <HiOutlineBanknotes size={20} /> },
    { name: 'Property', icon: <FaHouseCircleCheck size={20} /> },
    { name: 'Retail', icon: <BsShop size={20} /> },
    { name: 'General', icon: <FaHouseCircleCheck size={20} /> },
];

export const CardCatalogInsight = () => {
    const [selectedCategories, setSelectedCategories] = useState<string[]>(categoriesData.map(cat => cat.name));

    const handleCheckboxChange = (category: string) => {
        setSelectedCategories(prevState =>
            prevState.includes(category)
                ? prevState.filter(cat => cat !== category)
                : [...prevState, category]
        );
    };

    const filteredData = staticData.filter(item => selectedCategories.includes(item.category));


    return (
        <>
            <Title title="GeoSquare Insights" subTitle="  Dive into the world of data like never before! GeoSquare Insights transforms complex geospatial data into easy-to-understand visual stories, helping you make smarter decisions faster." />

            <div style={{
                 animation: `1s ease-in 1s 1 both ${fadein}`,
            }}>
                    <div className="category-grid" >
                    {categoriesData.map(category => (
                        <div className="checkbox" key={category.name}>
                            <label className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    checked={selectedCategories.includes(category.name)}
                                    onChange={() => handleCheckboxChange(category.name)}
                                />
                                <span className="checkbox-tile">
                                    <span className="checkbox-icon">
                                        {category.icon}
                                    </span>
                                    <span className="checkbox-label">{category.name}</span>
                                </span>
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            <Container
                css={{
                    display: "grid",
                    width: "100%",
                    paddingTop: '20px',
                    animation: `1s ease-in 1s 1 both ${fadein}`,
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gap: "1rem",
                    flex: "1"
                }}
            >
                {filteredData.map(item => (
                    <Container
                        key={item.id}
                        css={{
                            backgroundColor: 'white',
                            padding: '1rem',
                            borderRadius: '8px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            flexDirection: 'column',
                            alignItems: 'left',
                            textAlign: 'left',
                        }}
                    >
                          <span className="card-icon">
                          {item.icon && item.icon}
                          </span>
                  
                        <Text
                            css={{
                                display: 'block',
                                headline4: '700',
                                marginBottom: '0.5rem',
                                color: '#3983e0',
                            }}
                        >
                            {item.title}
                        </Text>
                        <Text css={{ fontSize: '0.875rem', color: 'gray' }}>
                            {item.description}
                        </Text>
                    </Container>
                ))}
            </Container>
        </>
    );
};
