// src/components/TourGuide.tsx
import React from 'react';
import { Tour, TourStepProps } from 'antd';
import useReduxTour from '../../hoc/hooks/useTour';
import step1Image from '../../asesst/Icons/tour/step1.png'
import step2Image from '../../asesst/Icons/tour/step2.png'
import step3Image from '../../asesst/Icons/tour/step3.gif'
import step4Image from '../../asesst/Icons/tour/step4.png'
const TourGuide = () => {
  const { stepIndex, tourVisible, tourNextStep, tourPrevStep, tourEnd } = useReduxTour();
//   console.log("tourVisible",stepIndex);
  
  const getTarget = (id: string): HTMLElement => {
    const element = document.getElementById(id);
    if (!element) {
      throw new Error(`Element with id "${id}" not found`);
    }
    return element;
  };

  const steps: TourStepProps[] = [
    {
        title: 'Drawing Polygon',
        description: (
            <>
            <p>1. Draw lines on the map.</p>
            <p>2. Double-click once you have finished drawing the line.</p>
            <p>3. Then the data catalog will appear in the sidebar that has been drawn.</p>
        </>
        ),
        placement: 'right',
        cover: (
            <img
              alt="tour.png"
              src={step1Image}
            />
          ),
        target: () => getTarget('start-point'),
      },
      {
        title: 'Change BaseMap',
        placement: 'right',
        cover: (
            <img
              alt="tour.png"
              src={step2Image}
            />
          ),
        description: 'To change the basemap according to your needs.',
        target: () => getTarget('feature-1'),
      },
      {
        title: 'Location',
        description: 'To return to the last position of the polygon you created.',
        placement: 'right',
        cover: (
            <img
              alt="tour.png"
              src={step3Image}
            />
          ),
        target: () => getTarget('feature-2'),
      },
      {
        title: 'Chat AI',
        description: 'You can directly request recommendations according to your needs by chatting with AI',
        target: () => getTarget('end-point'),
        placement: 'right',
        cover: (
            <img
            // height={400}
            // width={300}
              alt="tour.png"
              src={step4Image}
            />
          ),
      },
  ];
  const handleTourEnd = () => {
    tourEnd();
    // Set flag in local storage when the tour is completed
    localStorage.setItem('tourCompleted', 'true');
  };
  return (
    <Tour
      steps={steps}
      current={stepIndex}
      open={tourVisible}
      onClose={() => handleTourEnd()}
      indicatorsRender={(current, total) => (
        <span>
          {current + 1} / {total}
        </span>
      )}
      onChange={(current) => {
        if (current > stepIndex) {
          tourNextStep();
        } else {
          tourPrevStep();
        }
      }}
    />
  );
};

export default TourGuide;
