import React from "react";

import Container from "../../style/styledComponent/Container";
import { NavBar } from "../../component/page/shared/nav";
import Footers from "../../component/page/shared/footer/footer";
import { CardCatalogInsight } from "../../component/page/shared/card/cardCatalogInsight";

function InsightPage() {

    return (
        <>
           
            <Container id='wrapper' data-testid="app" css={{
                position: "relative",
                padding: " $2 $10",
                "@bp1": {
                    padding: " $2 $3",
                },
                "@bp2": {
                    padding: " $2 $5",
                },
                "@bp5": {
                    padding: " $2 $3",
                },
                backgroundSize: "cover",
                height: "100vh",
                overflowY: "scroll", // Enable vertical scroll
                "&::-webkit-scrollbar": {  // Hide scrollbar for Chrome and Safari
                    width: "0px",
                    background: "transparent",
                    display: "none",
                },
            }}>
                    <NavBar style={{ background: "#142942", top: '20px', zIndex: 1000, height: "84px" }} />
          <CardCatalogInsight/>
          <Footers />
            </Container>
        </>
    );
}

export default InsightPage;
