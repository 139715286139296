import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { 
    //  getPixelActionCreator, 
     getPixelSubCategoryAction,
     getPixelDrawAction, 
     getPixelByiD,
     getSampleStaticData,
     getSampleStaticDataInsight,
     getSampleStaticDataSurvey,
     getParamData
    } from '../action/pixel.action';

const useReduxPixel = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
         pixelSubcategory, 
         pixelcategory, 
         isloading, 
         pixelDrawCategory ,
         geoParamsPixel,
         staticDataSample,
         staticDataInsightSample,
         staticDataSurveySample,
         paramsData
        }  = useSelector((state: RootState) => state.pixel);
// console.log("isloading",isloading);

    // const fetchPixel = async () => {
    //     dispatch(getPixelActionCreator());
    // };

    const fetchPixelCategory = async (id: string) => {
        dispatch(getPixelSubCategoryAction(id));
    };

    const drawPixelCategory = async (params: string, RequestBody: any) => {
        dispatch(getPixelDrawAction(params, RequestBody));
    };

    const getPixelById = async (id: string, data_id: string) => {
        dispatch(getPixelByiD(id, data_id));
    };

    const getStaticData = async (data: any) => {
        dispatch((getSampleStaticData(data)));
    };
    const getStaticDataInsight = async (data: any) => {
        dispatch((getSampleStaticDataInsight(data)));
    };
    const getStaticDataSurvey = async (data: any) => {
        dispatch((getSampleStaticDataSurvey(data)));
    };

    const getParamsData = async (paramsData: string) => {
        dispatch(getParamData(paramsData));
    };

    return {
        pixelcategory, 
        pixelSubcategory, 
        fetchPixelCategory, 
        isloading, 
        // fetchPixel, 
        drawPixelCategory, 
        pixelDrawCategory,
        getPixelById,
        geoParamsPixel,
        getStaticData,
        staticDataSample,
        getStaticDataInsight,
        staticDataInsightSample,
        getStaticDataSurvey,
        staticDataSurveySample,
        getParamsData,
        paramsData
    }; // Return pixelState as well if needed
};

export default useReduxPixel;
