
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../store/config.strore';
import pixelApi from '../../service/pixelApi';
import apiKey from '../../service/apikeys';
import { 
    getDetailDataCatalog,
    getResponseApiPoint,
    getResponseApiPolygon,
    fetchDataFailure,
    fetchDataStart,
    fetchKeyDataSucces,
    fetchListKeyDataSucces,
    fetchListApilogs
 } from '../store/oneApi.store';

  export const getCatalog = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
      try {
        const data: any = await pixelApi.getCatalog();
        dispatch(getDetailDataCatalog(data)); 
      } catch (error) {
        console.error('Error fetching pixel data:', error);
        // dispatch(fetchPixelDataFailure());
      }
    };
    export const getRespPoint = (data:any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
        try {
          dispatch(getResponseApiPoint(data)); 
        } catch (error) {
          console.error('Error fetching pixel data:', error);
          // dispatch(fetchPixelDataFailure());
        }
      };
    export const getRespPollygon = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
        try {
          dispatch(getResponseApiPolygon(data)); 
        } catch (error) {
          console.error('Error fetching pixel data:', error);
          // dispatch(fetchPixelDataFailure());
        }
      };
    export const createApiKeys = ( data: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
        // console.log(dataPrompt);
          try {
          dispatch(fetchDataStart());
          
            const apikey: any = await apiKey.generateKeys(data);
            dispatch(fetchKeyDataSucces(apikey)); 
        
          } catch (error) {
            console.error('Error fetching pixel data:', error);
            dispatch(fetchDataFailure());
          }
    };
  export const getApiKeys = (data: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
      try {
        dispatch(fetchDataStart());
        const apikey = await apiKey.getKeys(data);
        dispatch(fetchListKeyDataSucces(apikey));
        return apikey; // Return the data
      } catch (error) {
        console.error('Error fetching API keys:', error);
        dispatch(fetchDataFailure());
        throw error; // Throw error to be caught in the calling function
      }
    };

  export const getLogs = (token: string, body: any): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
      try {
        dispatch(fetchDataStart());
        const apilog = await apiKey.getApiLogs(token, body);
        dispatch(fetchListApilogs(apilog));
        return apilog; // Return the data
      } catch (error) {
        console.error('Error fetching API keys:', error);
        dispatch(fetchDataFailure());
        throw error; // Throw error to be caught in the calling function
      }
    };



