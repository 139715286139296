import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { CSSProperties } from 'react';
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
import dataIcon from '../../../../asesst/new/icon/mage_dashboard-plus.svg';
import bgImage from '../../../../asesst/new/img/illustrationbg.svg';

const { Title, Text } = Typography;



const SectionGranullar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
            flexDirection: windowWidth <= 768 ? 'column' : 'row',
            textAlign: windowWidth <= 768 ? 'center' : 'left',

        },
        icon: {
            marginRight: windowWidth <= 768 ? '' : '10px',
            marginBottom: windowWidth <= 768 ? '10px' : '0',
            color: '#316DBA',
        },
        integrationText: {
            color: '#316DBA',
            marginRight: '10px',
            fontSize: '24px',
            fontWeight: '800',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        title: {
            fontFamily: '"Jakarta Sans", sans-serif',
            marginRight: windowWidth <= 768 ? '0%' : '65%',
            fontWeight: '800',
            fontSize: windowWidth <= 768 ? '24px' : '38px',
            color: '#333',
            marginTop: '10px',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        description: {
            fontFamily: '"Jakarta Sans", sans-serif',
            fontSize: '16px',
            color: '#5A5A5A',
            display: 'block',
            width: windowWidth <= 768 ? '100%' : '500px',
            lineHeight: '1.5',
            marginTop: '10px',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        button: {
            width: '175px',
            height: '44px',
            marginTop: '20px',
            backgroundColor: '#316DBA',
            borderColor: '#316DBA',
            color: 'white',
        },
        mapContainer: {
            position: 'relative',
        },
        mapImage: {
            width: '100%',
            height: 'auto',
        },
    };
    return (
        <Container
            dir={"column"}
            css={{
                opacity: "0",
                animation: `1s ease-in 1s 1 both ${fadein}`,
                width: "100%",
                // marginTop: '50px',
                // marginBottom: '30px',
                padding: windowWidth <= 768 ? '20px' : '$2 $10',
                backgroundColor: 'white',
                backgroundImage: `url(${bgImage})`,
                backgroundSize: windowWidth <= 768 ? 'contain' : 'revert',
                backgroundPosition: windowWidth <= 768 ? 'center' : 'right',
                backgroundRepeat: 'no-repeat', // Prevent the background from repeating
                paddingRight: '50px', // Add padding to the right
            }}
        >
            <div style={{ height: '530px', marginTop:'10px', marginBottom:'30px' }}>
                <Container css={{ padding: '50px 0' }}>
                    <Container
                        dir={"column"}
                        css={{
                            width: "100%",
                            height: "100%",
                            marginTop: windowWidth <= 768 ? '80%' : '0%',
                            opacity: "0",
                            padding: '50px 0',
                            animation: `1s ease-in 1s 1 both ${fadein}`,
                            alignItems: windowWidth <= 768 ? 'center' : 'flex-start',
                        }}
                    >
                        <div style={styles.integrationHeader}>
                            <img alt="example" src={dataIcon} style={styles.icon} />
                            <Text style={styles.integrationText}>Granular</Text>
                        </div>

                        <Title style={styles.title}>Pay only for what you exactly need!</Title>
                        <Text style={styles.description}>
                            Access as small as a 5x5m area and<br></br> focus on just one theme at a time.
                        </Text>
                    </Container>
                </Container>
            </div>
        </Container>
    );
};

export default SectionGranullar;
