import React from "react";
import { NavBar } from "../../component/page/shared/nav";
import Container from "../../style/styledComponent/Container";
// import SectionPlace from "../../layout/pageLayout/component/componentHome/section.place";
import ChatLogo from "../../component/page/shared/chat/chat.input";

function SolutionPage() {

    return (
        <>
           
            <Container id='wrapper' data-testid="app" css={{
                position: "relative",
                padding: " $2 $10",
                "@bp1": {
                    padding: " $2 $3",
                },
                "@bp2": {
                    padding: " $2 $5",
                },
                "@bp5": {
                    padding: " $2 $3",
                },
                backgroundSize: "cover",
                height: "100vh",
                overflowY: "scroll", // Enable vertical scroll
            }}>
    <NavBar style={{ background: "#142942", top: '20px', zIndex: 1000, height: "84px" }} />
                <ChatLogo/>
            </Container>
        </>
    );
}

export default SolutionPage;
