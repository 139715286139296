import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IoNotificationsOutline } from "react-icons/io5";
import logogeosquare from "../asesst/Icons/product/Geosquare-logo.png";
import SectionOrder from '../component/page/component/componentHome/section.dashboard.general';
import { UserProfilePage } from './user';
import { DashboardChart } from '../component/page/shared/chart';
import { GenerateApiKeysPage } from './api';
import Text from '../style/styledComponent/Text';
import { IoWalletOutline, IoHomeOutline } from "react-icons/io5";
import useReduxUser from '../hoc/hooks/useUser';
import { FaRegUser } from "react-icons/fa6";
import { LuLineChart } from "react-icons/lu";
import { IoKeyOutline } from "react-icons/io5";
import { IoBagCheckOutline } from "react-icons/io5";

const { Content, Sider, Header } = Layout;

const DashboardPage = () => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState('profile'); // Set default selected key to 'profile'
  const { user, getDetailUser, dataUser } = useReduxUser();
  const fetchGetUser = async () => {
    try {
      const userId = user?.uid
      await getDetailUser(userId);
    } catch (error) {
      console.error('Error:', error);
      // message.error('Failed to fetch API keys');
    }
  };

  useEffect(() => {
    fetchGetUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const handleClickBack = () => {
    navigate('/');
  };

  const handleMenuClick = (e: any) => {
    setSelectedMenuItem(e.key);
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'order':
        return (
          <SectionOrder />
        )
      case 'profile':
        return (
            <UserProfilePage />

        );

      case 'dashmonitor':
        return (
          <DashboardChart />
        );
      case 'apikeys':
        return (
          <GenerateApiKeysPage />
        );
      case 'preorder':
        return (
          <div style={{ padding: 24 }}>
            <SectionOrder />
          </div>
        );
      default:
        return (
          <UserProfilePage />
        );
    }
  };

  const getTitle = () => {
    switch (selectedMenuItem) {
      case 'order':
        return 'Table Information Order';
      case 'profile':
        return 'Account Profile';
      case 'account':
        return 'Account Profile';
      case 'dashmonitor':
        return 'API Dashboard Monitor';
      case 'apikeys':
        return 'API KEY';
      case 'preorder':
        return 'Table Information Pre Order';
      default:
        return 'Account Profile';
    }
  };


  return (
    <Layout
      style={{
        width: '100%',
        height: '100vh'
      }}
    >
      <Sider
        trigger={null}
        collapsible
        width={250}
        style={{
          background: "#363A45",
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0
        }}
      >
        <div
          onClick={handleClickBack}
          style={{
            marginBottom: '16px',
            color: 'rgba(255, 255, 255, 0.65)',
            paddingLeft: '24px',
            paddingTop: '20px',
            cursor: 'pointer'
          }}
        >
          <img
            src={logogeosquare}
            alt="GeoSquare.ai"
            style={{ cursor: "pointer", width: "124px", height: "auto" }}
          />
        </div>
        <Menu
          style={{ background: "#363A45", color: 'white' }}
          selectedKeys={[selectedMenuItem]} // Custom selectedKeys
          defaultSelectedKeys={['profile']}
          onClick={handleMenuClick}
        >
          <Menu.Item
            key="account"
            icon={<FaRegUser size={20} />}
            className={selectedMenuItem === 'account' ? 'selected-menu-item' : 'custom-menu-item'}
          >
            Account
          </Menu.Item>
          <Menu.Item
            key="dashmonitor"
            icon={<LuLineChart size={20} />}
            className={selectedMenuItem === 'dashmonitor' ? 'selected-menu-item' : 'custom-menu-item'}
          >
            Monitoring API
          </Menu.Item>
          <Menu.Item
            key="apikeys"
            icon={<IoKeyOutline size={20} />}
            className={selectedMenuItem === 'apikeys' ? 'selected-menu-item' : 'custom-menu-item'}
          >
            API key
          </Menu.Item>
          <Menu.Item
            key="order"
            icon={<IoBagCheckOutline size={20} />}
            className={selectedMenuItem === 'order' ? 'selected-menu-item' : 'custom-menu-item'}
          >
            Order Catalog
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: 250, height: '100vh', width: '100%', overflow: 'auto' }}>
        <Header style={{
          position: 'sticky', top: 0, zIndex: 1, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: "#363A45"
          , padding: '0 24px', color: 'white'
        }}>
          <Text style={{ color: 'white', fontSize: '20px', fontWeight: 600 }}>
            {getTitle()}
          </Text>
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <div style={{ display: 'flex', alignItems: 'center', placeContent: 'center' }}>
              <IoWalletOutline size={24} style={{ color: 'white', fontSize: '20px', marginRight: '10px' }} />
              <Text style={{ color: 'white', fontSize: '20px', marginRight: '10px', cursor: 'pointer' }}>{`Rp. ${dataUser?.balance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',' || 0)}</Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', placeContent: 'center', marginRight: '10px', marginLeft: '10px' }}>
              <IoNotificationsOutline size={24} style={{ color: 'white', fontSize: '20px', marginRight: '10px' }} />

            </div>
            <div onClick={handleClickBack} style={{ display: 'flex', alignItems: 'center', placeContent: 'center', marginRight: '10px' }}>
              <IoHomeOutline size={24} style={{ color: 'white', fontSize: '20px', marginRight: '10px' }} />
            </div>
          </div>
        </Header>
        <Content >
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
