import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import maplibregl, { Map as MapboxMap, LngLatBoundsLike } from 'maplibre-gl';
import { createMapLibreGlMapController } from "@maptiler/geocoding-control/maplibregl-controller";
import { GeocodingControl } from "@maptiler/geocoding-control/react";
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import "@maptiler/geocoding-control/style.css";
import './map.css';
import "@maptiler/sdk/dist/maptiler-sdk.css";
import useReduxPixel from '../../../../hoc/hooks/usePixel';
import { DrawMapsTools, GeoMapsTools } from '../../../../shared/drawtools';
import useReduxDrawer from '../../../../hoc/hooks/useDrawer';
import LoaderMap from '../../../../shared/loader/loaderMap';
import 'chart.js/auto';
import Chart from 'chart.js/auto';
import { LegendToolsDashboard } from '../../../../shared/legend/legendMapDashboard';
import useReduxMap from '../../../../hoc/hooks/useMap';
import { LoaderProgress } from '../../../../shared/loader/loaderProgress';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import PetainGrid from '../../../../utils/petain-grid/petain-grid';
import { loadParquet } from '../../../../utils/parquet';
import { LegendColors } from '../../../../utils/legendType/legend-type';
import * as turf from '@turf/turf';
let chartInstance: Chart | null = null;
interface PolygonData {
  id: number;
  coordinates: [number, number][];
  baby_boomers: number;
  millenials: number;
  male: number;
  gen_z: number;
  gen_x: number;
  families_with_kids: number;
  female: number;
  value: number;
  color: string;
  afternoon: number;
  morning: number;
  evening: number;
  night: number;
  valueOpacitiy: any;
  data: any;
  gid:any;
}

export const legendType = [
  'people',
  'demography',
  'safety',
  'food_beverage',
  'retail',
  'amenity',
  'planning',
  'hi-res-demography',
  'accessibility',
  'address'
  // Add more types as needed
] as const;

type LegendType = typeof legendType[number];

const getLegendColor = (value: any, legendColors: any[]) => {
  const colorItem = legendColors.find((item: { value: any; }) => item.value === value);
  return colorItem ? colorItem.color : '#ffffff'; // Default color if not found
}
export const Map: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [API_KEY] = useState<string>('IkttUJmMfqWCx0g43vGM');
  const mapContainer = useRef<HTMLDivElement | null>(null);
  // const [file, setFile] = useState<File | null>(null);
  const [polygons, setPolygons] = useState<PolygonData[]>([]);
  const [legendData, setLegendData] = useState<any>(null);
  const [dataLink, setDataLink] = useState<any>(null);
  const map = useRef<MapboxMap | null>(null);
  // const layerAdded = useRef<boolean>(false);
  const [lng] = useState<number>(106.82643616574086,);
  const [lat] = useState<number>(-6.182085408588648);
  const [mapController, setMapController] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [zoom] = useState<number>(10);
  const popup = useRef<maplibregl.Popup | null>(null);
  const drawControl = useRef<any>(null);
  const [geom, setGeom] = useState<any | null>(null);
  // const chartInstanceRef = useRef<Chart<'pie', any[], any> | null>(null);
  const {
    isOpen,
    drawerOpen,
    basemapUrl,
    setGeomData,
    // isOpenCardMap,
    // setGeoJsonData,
    geojsonData,
    geoLink
  } = useReduxDrawer();
// console.log(geojsonData);

  const { isLoadingData, progressData } = useReduxMap();
  // const { user, modalLoginOpen } = useReduxUser();
  const {
    drawPixelCategory,
    paramsData
  } = useReduxPixel()

  const groupFeatures = (geojson: any) => {
    const grouped: {
      color: string;
      value: number;
    }[] = [];


    const groupMap: { [key: string]: any[] } = {};
    geojson?.features.forEach((feature: any) => {
      const { value, color } = feature.properties;
      const key = `${color}-${value}`;

      if (!groupMap[key]) {
        groupMap[key] = [];
      }
      groupMap[key].push(feature);
    });

    for (const key in groupMap) {
      const [color, value] = key.split('-');
      grouped.push({
        color: color,
        value: parseInt(value),
      });
    }
    grouped.sort((a, b) => a.value - b.value);
    return grouped;
  };

  useEffect(() => {
    const data = groupFeatures(dataLink)
    setLegendData(data)
  }, [dataLink]);


  useEffect(() => {
    if (mapContainer.current) {
      setLoading(true);
      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: 'https://api.maptiler.com/maps/streets/style.json?key=IkttUJmMfqWCx0g43vGM', // Replace with your Mapbox or MapTiler style URL
        center: [lng, lat],
        zoom: zoom,
        attributionControl: false // Menonaktifkan kontrol atribusi
      });

      map.current.on('load', () => {
        setLoading(false);
        drawControl.current = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: false,
            trash: false,
          },
          styles: [
            {
              "id": "gl-draw-polygon-fill",
              "type": "fill",

              "paint": {
                "fill-color": "#3489EB",
                // "fill-outline-color": "#3489EB",
                "fill-opacity": 0.1
              }
            },
            {
              "id": "gl-draw-polygon-stroke-active",
              "type": "line",

              "layout": {
                "line-cap": "round",
                "line-join": "round"
              },
              "paint": {
                "line-color": "#3489EB",
                "line-dasharray": [0.2, 0.2],
                "line-width": 1
              }
            },
            {
              "id": "gl-draw-polygon-and-line-vertex-active",
              "type": "circle",

              "paint": {
                "circle-radius": 3,
                "circle-color": "#3489EB",
              }
            }
          ]
        });

        if (map.current) {
          setMapController(createMapLibreGlMapController(map.current, maplibregl));
          map.current.addControl(drawControl.current, 'top-left');
          const controlContainer = map.current.getContainer().querySelector('.mapboxgl-ctrl-group') as HTMLElement;
          const newStyles = {
            marginTop: '20px',
            marginLeft: '10px',
          };
          if (controlContainer) {
            Object.assign(controlContainer.style, newStyles);
          }
        }
      });
    }


    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basemapUrl]);

  const calculateCentroid = (coordinates: [number, number][]): [number, number] => {
    const numPoints = coordinates.length;
    let x = 0;
    let y = 0;
    coordinates.forEach(coord => {
      x += coord[0];
      y += coord[1];
    });
    return [x / numPoints, y / numPoints];
  };

  const showPopup = (coordinates: [number, number], properties: any) => {
    const data = properties

    const dataValue: { key: string; value: any }[] = [];
    const dataWithoutGid: { key: string; value: any }[] = [];
    const dataColor: { key: string; value: any }[] = [];
    const dataPie: { newKey: string; value: any }[] = [];

    for (const [key, value] of Object.entries(data)) {
      if (key === 'value') {
        dataValue.push({ key, value });
      }
      else if (key !== 'gid' && key !== 'color' && key !== 'line_color' && key !== 'value') {
        const newKey = key.replace(/^[^_]*_/, '');
        dataPie.push({ newKey, value });
      }
      else if (key === 'color') {
        dataColor.push({ key, value });
      }
      else {
        dataWithoutGid.push({ key, value });
      }
    }
    const color = dataColor.find((item: { key: string; }) => item.key === 'color')?.value;

    const infoAlertContent = dataValue.map(({ key, value }) => (
      <div>
        <table
          style={{
            border: "border-collapse: collapse;",
            width: "100%",
            maxWidth: '600px'
          }}>
          <tr>
            <td
              style={{
                border: '1px solid black',
                padding: '8px',
                textAlign: 'left'
              }}
            >
              <p style={{ fontSize: 20, fontWeight: 'bold' }}>score</p>
            </td>
            <td
              style={{
                border: '1px solid black',
                padding: '8px',
                textAlign: 'left'
              }} >
              <button
                style={{
                  color: 'white',
                  backgroundColor: `${color}`,
                  opacity: 0.7,
                  padding: '10px 30px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
              >
                <p style={{ fontSize: 20, fontWeight: 'bold' }}>{value}</p>
              </button>
            </td>
          </tr>
        </table>
      </div>
    ));

    // Mengubah JSX menjadi string HTML
    const alertContentHTML = infoAlertContent.map((jsxElement) => {
      const tempElement = document.createElement('div');
      ReactDOM.render(jsxElement, tempElement); // Render JSX to a temporary DOM element
      return tempElement.innerHTML;
    }).join('');
  
    if (!popup.current) {
      popup.current = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false
      });
    }
    // <canvas id="pieChart" width="600" height="200"></canvas>

    popup.current.setLngLat(coordinates)
      .setHTML(
        `<div style="display: flex; flex-direction: column; align-items: center; gap: 10px; width: 100%;">
        <h3>Data Info</h3> 
  
        ${alertContentHTML}
      </div>`
      )
      .addTo(map.current!);

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    };
  }

  const hidePopup = () => {
    if (popup.current) {
      popup.current.remove();
    }
  };

  useEffect(() => {
    if (dataLink) {
      setLoading(true);
      try {
        setPolygons(dataLink.features.map((feature: any, index: number) => ({
          id: index,
          coordinates: feature.geometry.coordinates[0],
        })));

      } catch (error) {
        console.error('Error parsing GeoJSON features:');
      } finally {
        setLoading(false);
      }
    }
    if (geojsonData) {
      setLoading(true);
      try {
        setPolygons(geojsonData.features.map((feature: any, index: number) => ({
          id: index,
          coordinates: feature.geometry.coordinates[0],
        })));

      } catch (error) {
        console.error('Error parsing GeoJSON features:');
      } finally {
        setLoading(false);
      }
    }
  }, [dataLink, basemapUrl,geojsonData]);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const storage = getStorage();
      const grid_lib = new PetainGrid();
      const legendColors = LegendColors[geoLink?.category as LegendType] || [];
      const mapInstance = map.current;
  
      if (mapInstance) {
        // Remove existing layers and sources for geoLink
        if (mapInstance.getStyle() && mapInstance.getStyle().layers) {
          mapInstance.getStyle().layers.forEach((layer) => {
            if (layer.id.startsWith('layer-geoLink-')) {
              mapInstance.removeLayer(layer.id);
            }
          });
        }
  
        if (mapInstance.getStyle() && mapInstance.getStyle().sources) {
          Object.keys(mapInstance.getStyle().sources).forEach((sourceId) => {
            if (sourceId.startsWith('source-geoLink-')) {
              mapInstance.removeSource(sourceId);
            }
          });
        }
  
        // Remove existing layers and sources for geojsonData
        if (mapInstance.getStyle() && mapInstance.getStyle().layers) {
          mapInstance.getStyle().layers.forEach((layer) => {
            if (layer.id.startsWith('layer-geojsonData-')) {
              mapInstance.removeLayer(layer.id);
            }
          });
        }
  
        if (mapInstance.getStyle() && mapInstance.getStyle().sources) {
          Object.keys(mapInstance.getStyle().sources).forEach((sourceId) => {
            if (sourceId.startsWith('source-geojsonData-')) {
              mapInstance.removeSource(sourceId);
            }
          });
        }
  
        const allFeatures: any[] = [];
  
        // Handle data from geoLink
        if (geoLink) {
          const listRef = ref(storage, geoLink?.table_location);
  
          await listAll(listRef).then(async (res) => {
            for (const itemRef of res.items) {
              if (map.current) {
                try {
                  const downloadUrl = await getDownloadURL(itemRef);
                  const partData = await loadParquet(downloadUrl);
  
                  const features = partData.map((item: any) => {
                    let value = item.value;
                    for (const key in item) {
                      if (item.hasOwnProperty(key) && key !== 'gid' && key !== 'value' && item[key] === 0) {
                        return true;
                      }
                    }
                    return {
                      type: 'Feature',
                      properties: {
                        gid: item.gid,
                        value: value,
                        color: getLegendColor(value, legendColors),
                      },
                      geometry: {
                        type: 'Polygon',
                        coordinates: [grid_lib._gid_to_geometry(item.gid)],
                      },
                    };
                  });
  
                  allFeatures.push(...features);
  
                  const sourceId = `source-geoLink-${itemRef.fullPath}`;
                  const layerId = `layer-geoLink-${itemRef.fullPath}`;
  
                  mapInstance.addSource(sourceId, {
                    type: 'geojson',
                    data: {
                      type: 'FeatureCollection',
                      features: features,
                    }
                  });
  
                  mapInstance.addLayer({
                    id: layerId,
                    type: 'fill',
                    source: sourceId,
                    minzoom: 2,
                    paint: {
                      'fill-color': ['get', 'color'],
                      'fill-opacity': 0.7,
                      'fill-outline-color': 'rgba(0, 0, 0, 0)', // Transparent outline
                    }
                  });
  
                  mapInstance.on('mousemove', layerId, (e) => {
                    if (map.current) {
                      map.current.getCanvas().style.cursor = 'pointer';
                    }
                    if (e.features && e.features.length > 0) {
                      const feature = e.features[0];
                      if (feature && feature.properties && feature.geometry && feature.geometry.type === 'Polygon') {
                        const coordinates = (feature.geometry.coordinates[0] as [number, number][]).slice();
                        const centroid = calculateCentroid(coordinates);
                        const properties = feature.properties;
                        showPopup(centroid, properties);
                      }
                    }
                  });
  
                  mapInstance.on('mouseleave', layerId, () => {
                    if (map.current) {
                      map.current.getCanvas().style.cursor = 'default';
                    }
                    hidePopup();
                  });
                } catch (e) {
                  console.error(e);
                }
              }
            }
  
            if (allFeatures.length > 0 && map.current) {
              const bbox = turf.bbox({
                type: 'FeatureCollection',
                features: allFeatures,
              });
  
              if (bbox.length === 4) {
                map.current.fitBounds(bbox as [number, number, number, number], { padding: 200 });
                map.current.once('moveend', () => {
                  if (map.current) {
                    map.current.setZoom(map.current.getZoom() + 3);
                  }
                });
              }
            }
            setLoading(false);
            setDataLink({
              type: 'FeatureCollection',
              features: allFeatures,
            });
          }).catch((error) => {
            console.error('Error fetching data from geoLink:', error);
            setLoading(false);
          });
        }
  
        // Handle data from geojsonData
        if (geojsonData) {
          const sourceId = `source-geojsonData`;
          const layerId = `layer-geojsonData`;
  
          mapInstance.addSource(sourceId, {
            type: 'geojson',
            data: geojsonData
          });
  
          mapInstance.addLayer({
            id: layerId,
            type: 'fill',
            source: sourceId,
            minzoom: 2,
            paint: {
              'fill-color': ['get', 'color'],
              'fill-opacity': 0.7,
              'fill-outline-color': 'rgba(0, 0, 0, 0)', // Transparent outline
            }
          });
  
          mapInstance.on('mousemove', layerId, (e) => {
            if (map.current) {
              map.current.getCanvas().style.cursor = 'pointer';
            }
            if (e.features && e.features.length > 0) {
              const feature = e.features[0];
              if (feature && feature.properties && feature.geometry && feature.geometry.type === 'Polygon') {
                const coordinates = (feature.geometry.coordinates[0] as [number, number][]).slice();
                const centroid = calculateCentroid(coordinates);
                const properties = feature.properties;
                showPopup(centroid, properties);
              }
            }
          });
  
          mapInstance.on('mouseleave', layerId, () => {
            if (map.current) {
              map.current.getCanvas().style.cursor = 'default';
            }
            hidePopup();
          });
  
          if (map.current) {
            const bbox = turf.bbox(geojsonData);
            if (bbox.length === 4) {
              map.current.fitBounds(bbox as [number, number, number, number], { padding: 200 });
              map.current.once('moveend', () => {
                if (map.current) {
                  map.current.setZoom(map.current.getZoom() + 3);
                }
              });
            }
          }
  
          setLoading(false);
        }
      }
    };
  
    fetchData();
  }, [geoLink, geojsonData]);
  

  // ============ test for link =====================//

  useEffect(() => {
    map.current?.on('draw.create', (event) => {
      const newGeometry = event.features[0].geometry;
      setGeom(newGeometry);
      setGeomData(newGeometry);
      drawerOpen(true);;

    });
    map.current?.on('draw.update', (e) => {
      const updatedFeatures = e.features;
      updatedFeatures.forEach((feature: { geometry: any; }) => {
        const updatedGeometry = feature.geometry;
        setGeom(updatedGeometry);
        setGeomData(updatedGeometry);

      });
    });
    map.current?.on('draw.delete', () => {
      setGeom(null);
      setGeomData(null);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basemapUrl]);


  const handleButtonClick = () => {
    // Ubah mode menggambar menjadi 'draw_polygon'
    drawControl.current.changeMode('draw_polygon');
    const data = drawControl.current.getAll();
    if (drawControl.current.getMode() === 'draw_polygon') {
      const polygonIds: string[] = [];
      // ID of the added template empty feature
      const lastFeatureId = data.features[data.features.length - 1].id;
      data.features.forEach((feature: { geometry: { type: string; }; id: string; }) => {
        if (feature.geometry.type === 'Polygon' && feature.id !== lastFeatureId) {
          polygonIds.push(feature.id);
        }
      });
      drawControl.current.delete(polygonIds);
    }
  };

  useEffect(() => {
    let params = paramsData;
    if (params === "INSIGHT") {
      params = "insight";
      drawPixelCategory(params, geom);
    } else if (params === "DATA") {
      params = "data";
      drawPixelCategory(params, geom);
    } else if (params === "SURVEY") {
      params = "survey";
      drawPixelCategory(params, geom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsData, geom, basemapUrl]);

  const isLocation = () => {
    const bounds = calculateBoundingBox();
    if (bounds) {
      map.current?.fitBounds(bounds, {
        padding: 70
      });
    }
  };

  const calculateBoundingBox = (): LngLatBoundsLike | null => {
    if (polygons.length === 0) return null;

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    polygons.forEach(polygon => {
      polygon.coordinates.forEach(coord => {
        minX = Math.min(minX, coord[0]);
        minY = Math.min(minY, coord[1]);
        maxX = Math.max(maxX, coord[0]);
        maxY = Math.max(maxY, coord[1]);
      });
    });

    return [[minX, minY], [maxX, maxY]] as LngLatBoundsLike;
  };

  const handleGeocodingSelect = async (result: any) => {
    if (result?.geometry?.type === 'Polygon') {
      const coordinatesByGeoCode = result?.geometry;
      setGeom(coordinatesByGeoCode);
      setGeomData(coordinatesByGeoCode);
      drawerOpen(true);;
    }
  }
  return (
    <div className="map-wrap">
      <div style={{
        position: "absolute",
        top: 30,
        left: '50%',
        zIndex: 100,
        transform: "translate(-50%, -50%)"
      }}>

        <GeocodingControl
          onPick={handleGeocodingSelect}
          apiKey={API_KEY}
          mapController={mapController}
        />
      </div>
      <DrawMapsTools drawerOpen={isOpen} onclickPolygon={handleButtonClick} />
      <GeoMapsTools drawerOpen={isOpen} onClickLocation={isLocation} />
      <LegendToolsDashboard legendData={legendData} />
      {loading && <LoaderMap />}
      {isLoadingData && <LoaderProgress percent={progressData} />}
      <div ref={mapContainer} className="map" />
    </div>
  );
};