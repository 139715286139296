import React, { useState } from 'react';
import { Layout, Row, Col, Card, Typography,Button ,message, Modal} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { MapComponentPoint, EditableTextArea } from '../../component/page/component/componentApi';
import postmanIcon from "../../asesst/Icons/product/postman.svg";
import { TableCategory } from '../../component/page/shared/table';
import Text from '../../style/styledComponent/Text';
// import useReduxOneApi from '../../layout/mapLayout/hooks/useOneApi';
// import EditableTextArea from './EditableTextArea';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const EndpointDocumentationPage: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const {setPointApi} = useReduxOneApi()

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
          message.success('URL copied to clipboard');
        });
      };
      const endpointUrl = 'https://api.geosquare.ai/api/data/:data_id?longitude=[longitude]&latitude=[latitude]&key=[API_KEY]';

      const handleDocsApi = () => {
        window.open('https://api-docs.geosquare.ai/#24b64279-10b2-4b29-9752-6ce8d46f7332', '_blank');
      };
    return (
        <Layout style={{ padding: '24px', minHeight: '100vh', background: '#fff' }}>
            <Content>
                <Title level={3}>By Point Documentation</Title>
                <Row gutter={16} style={{ marginBottom: '24px' }}>
                    <Col span={10}>
                        <MapComponentPoint />
                    </Col>
                    <Col span={14}>
                        <EditableTextArea />
                    </Col>
                </Row>
                <Card style={{ marginTop: '24px' }}>
                    {/* <Title level={3}>Endpoint Documentation</Title> */}
                    <Title level={2}>Full Documentation </Title>
                    <Row gutter={16} style={{ marginBottom: '24px' }}>
                        <Card style={{ textAlign: 'center', borderColor: '#ff6c37', cursor:'pointer' }} onClick={handleDocsApi}>
                            <img
                                src={postmanIcon}
                                alt="Postman"
                                style={{ width: '50px', height: '50px', marginBottom: '16px' }}
                            />
                            <p style={{ color: '#ff6c37', fontWeight: 'bold' }}>
                                Go to full documentation
                            </p>
                        </Card>
                    </Row>
                    <Title level={3}>Endpoint Documentation</Title>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom:'10px' }}>
                        
                            <Text css={{fontWeight:700, color:'Green', marginRight:'10px'}}>GET</Text>
                    
                        {endpointUrl}
                            <CopyOutlined
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                                onClick={() => copyToClipboard(endpointUrl)}
                            />
                        </div>
                    
                    <Paragraph>
                        <strong>Parameters:</strong>
                        <ul>
                            <li><strong>latitude:</strong> This query parameter represents the longitude coordinate of a location. It's a decimal value in degrees. It's a decimal value in degrees.</li>
                            <li><strong>longitude:</strong> This query parameter represents the latitude coordinate of a location. It's a decimal value in degrees.</li>
                            <li><strong>key:</strong> This query parameter is an API key or access token required for authorization..</li>
                          
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        <strong>Path Variable:</strong>
                        <ul>
                            <li><strong>data_id:</strong> This is a path parameter that specifies the unique identifier of the data object you want to access. You can get data_id from catalog list.</li>
                          
                        </ul>

                        <strong>You can check Indentifier data_id here:</strong>
                        <ul>
                        <Button onClick={showModal} type="primary" style={{ marginBottom: '16px', marginTop:'10px' }}>
                       Open
                        
                    </Button>
                    </ul>
                    </Paragraph>
                    <Paragraph>
                        <strong>Example Response:</strong>
                        <pre>
{
`{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "id": "J3N2M768C2Q287",
      "properties": {
        "gid": "J3N2M768C2Q287",
        "value": 1
      },
      "geometry": {
        "type": "Feature",
        "properties": {},
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [
                106.82747839337955,
                -6.186652550106606
              ],
              [
                106.82747839337955,
                -6.186607634342401
              ],
              [
                106.82752330914376,
                -6.186607634342401
              ],
              [
                106.82752330914376,
                -6.186652550106606
              ],
              [
                106.82747839337955,
                -6.186652550106606
              ]
            ]
          ]
        }
      }
    }
  ]
}`
}
                        </pre>
                    </Paragraph>
                </Card>
            </Content>
            <Modal
            title='Identifier Data ID'
            visible={isModalOpen}
            width={1000}
            onCancel={handleCancel}
            footer={false}
        >
            <TableCategory/>
           
        </Modal>
        </Layout>
    );
};


