import React from 'react';
import { MapProvider } from 'react-map-gl/maplibre';
import { MapComponent } from '../../newmap';
import { MapLoader } from '../../../shared/loader/mapload';
import { Layout } from 'antd';

const { Content } = Layout;

export const MapApp: React.FC<{ children?: React.ReactNode }> = (props) => {
    return (
        <Layout style={{ height: '100vh', width: '100vw' }}>
            <Content style={{ height: '100%', width: '100%' }}>
                <MapProvider>
                    <MapComponent>
                        <MapLoader>
                            {props.children}
                        </MapLoader>
                    </MapComponent>
                </MapProvider>
            </Content>
        </Layout>
    );
};
