// drawerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface locationState {
    address: string;
    centroid: number[][];
    area:number
}
interface CatalogState {
    catalog: any | null;
    isloading: boolean; // Add loading property
    error: boolean;
    paramsCatalogGeometry: any;
    location: locationState | null,
}

const initialState: CatalogState = {
    catalog: null,
    paramsCatalogGeometry: null,
    isloading: true, // Initialize loading state
    error: false,
    location: null
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {

    setCatalogStart: (state) => {
        state.isloading = true; // Set loading to true when fetching starts
        state.error = false;
      },
    setCatalog: (state, action: PayloadAction<any>) => {
        state.isloading = false; 
        state.catalog = action.payload;
    },
    setCatalogGeometry: (state, action: PayloadAction<any>) => {
        state.isloading = false; 
        state.paramsCatalogGeometry = action.payload;
    },
    setCataloglocation: (state, action: PayloadAction<any>) => {
        state.isloading = false; 
        state.location = action.payload;
    },
  },
});

export const { 
    setCatalog,
    setCatalogGeometry,
    setCataloglocation,
    setCatalogStart
} = catalogSlice.actions;

export default catalogSlice.reducer;
