import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined,  IdcardOutlined, MailOutlined  } from '@ant-design/icons';
import { auth, db } from '../../firebase/firebaseConfig';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import logo from '../../asesst/Icons/product/Geosquare-logo.png'
import { useNavigate } from 'react-router-dom';
import PhoneNumberInput from '../../component/page/shared/input/phoneNumber';
import Text from '../../style/styledComponent/Text';

export const RegistrationForm: React.FC = () => {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleClick = () => {
        navigate('/');
    }
    
    const [loading, setLoading] = useState(false);
    const checkEmailExists = async (email: string) => {
        try {
            const signInMethods = await fetchSignInMethodsForEmail(auth, email);
            return signInMethods.length > 0;
        } catch (error) {
            // console.error('Error checking email:', error);
            message.error('Error checking email');
            return false;
        }
    };
    const onFinish = async (values: { name: string; nickname: string; email: string; phoneNumber: number; password: string}) => {
        setLoading(true);
        const { name, nickname, email, phoneNumber, password } = values;

        try {
            const emailExists = await checkEmailExists(email);

            if (emailExists) {
                message.error('Email already registered');
                setLoading(false);
                return;
            }

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(db, 'usersGeosquare', user.uid), {
                name,
                displayName: nickname,
                email,
                phoneNumber,
                photoURL: null,
                provider:'none',
                balance: 0,
                createdAt: new Date(),
                total_spent: 0,
                total_balance:0
            });

            message.success('Registration successful!');
            navigate('/login'); // Redirect to login page
        } catch (error) {
            // console.error('Registration error:', error);
            if (error instanceof Error) {
                switch (error.message) {
                    case 'auth/invalid-email':
                        message.error('Invalid email format.');
                        break;
                    case 'auth/weak-password':
                        message.error('Password should be at least 6 characters.');
                        break;
                    default:
                        message.error('Email already registered!');
                        break;
                }
            } else {
                message.error('An unexpected error occurred.');
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <div style={{ width: 300, margin: '0 auto', padding: '20px 0', textAlign: 'center' }}>
          <img onClick={handleClick} src={logo} alt="Logo" style={{ width: '200px', cursor:'pointer' }} />
        <Text style={{fontFamily: '"Poppins", sans-serif', fontSize:'24px', color:'black', marginBottom:'10px'}} >Create Account</Text>
        <Form
        name="register"
        onFinish={onFinish}
        style={{ width: '100%' }}
        layout="vertical"
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input prefix={<IdcardOutlined />} placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="nickname"
          rules={[{ required: true, message: 'Please input your nickname!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Nickname" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'The input is not valid E-mail!' }]}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          rules={[{ required: true, message: 'Please input your phone number!' }]}
        >
          <PhoneNumberInput value={phoneNumber} onChange={setPhoneNumber} />
         
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%',fontFamily: '"Poppins", sans-serif', }}>
            Register
          </Button>
        </Form.Item>
      </Form>
      </div>
    );
  };