// firestoreFunctions.ts

import {
  collection,
  addDoc,
  getDocs,
  query,
  getDoc,
  doc,
  DocumentReference,
  where
} from 'firebase/firestore';
import { db } from './firebaseConfig'; // Menggunakan instance firestore dari firebaseConfig

// Membuat referensi ke koleksi baru
const geo_transactions = collection(db, 'geo_transactions');
const geo_quote = collection(db, 'geo_quote');
const geo_talkToUs = collection(db, 'geo_talkToUs');
const user_geo ='usersGeosquare';

export const saveFormDataToFirestore = async (formDataBuy: any) => {
  try {
    // Simpan data ke Firestore dan dapatkan referensi dokumen
    const docRef: DocumentReference = await addDoc(geo_transactions, formDataBuy);

    // Pastikan dokumen tersedia di Firestore sebelum mengambil data
    await new Promise((resolve) => setTimeout(resolve, 500));

    // Ambil data dari dokumen yang baru saja ditambahkan
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Jika dokumen ada, keluarkan data dokumen
      const savedData = docSnap.data();
      // console.log('Data berhasil disimpan ke Firestore:', savedData);
      return savedData;
    } else {
      // Dokumen tidak ditemukan (ini seharusnya tidak terjadi setelah addDoc)
      // console.error('Dokumen tidak ditemukan setelah disimpan');
      return null;
    }
  } catch (error) {
    // console.error('Gagal menyimpan data ke Firestore:', error);
    throw error; // Mengembalikan error jika terjadi kesalahan
  }
};


export const saveFormDataTalkToUs = async (formTalkToUs: any) => {
  try {
    // Simpan data ke Firestore dan dapatkan referensi dokumen
    const docRef: DocumentReference = await addDoc(geo_talkToUs, formTalkToUs);

    // Pastikan dokumen tersedia di Firestore sebelum mengambil data
    await new Promise((resolve) => setTimeout(resolve, 500));

    // Ambil data dari dokumen yang baru saja ditambahkan
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Jika dokumen ada, keluarkan data dokumen
      const savedData = docSnap.data();
      // console.log('Data berhasil disimpan ke Firestore:', savedData);
      return savedData;
    } else {
      // Dokumen tidak ditemukan (ini seharusnya tidak terjadi setelah addDoc)
      // console.error('Dokumen tidak ditemukan setelah disimpan');
      return null;
    }
  } catch (error) {
    // console.error('Gagal menyimpan data ke Firestore:', error);
    throw error; // Mengembalikan error jika terjadi kesalahan
  }
};

export const saveQuoteDataToFirestore = async (formDataQuote: any) => {
  try {
    // Simpan data ke Firestore
    const modifiedData = {
      ...formDataQuote,
      geom: {
        ...formDataQuote.geom,
        coordinates: JSON.stringify(formDataQuote.geom.coordinates)
      }
    };
    await addDoc(geo_quote, modifiedData);
    // Jika berhasil, lakukan tindakan lanjutan
    console.log('Data berhasil disimpan ke Firestore');
  } catch (error) {
    console.error('Gagal menyimpan data ke Firestore:', error);
  }
};

export const getAllGeoTransactions = async (email: any) => {
  try {
    // Create a query to filter documents by user ID
    const q = query(geo_transactions, where('email', '==', email));
    const geoTransactionSnapshot = await getDocs(q);
    const geoTransactionList = geoTransactionSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return geoTransactionList;
  } catch (error) {
    console.error('Gagal mengambil data dari Firestore:', error);
    return [];
  }
};

export const getStatusGeoTransactions = async (email: any) => {
  try {
    // Query to get documents where status_data is either 'success' or 'failed'
    const q = query(
      geo_transactions,
      where('email', '==', email),
      where('status_data', 'in', ['success', 'failed'])
    );

    const geoTransactionSnapshot = await getDocs(q);
    const geoTransactionList = geoTransactionSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return geoTransactionList;
  } catch (error) {
    console.error('Gagal mengambil data dari Firestore:', error);
    return [];
  }
};

export const getAllGeoTransactionsSuccessdata = async (email: any) => {
  try {
    // Create a query to filter documents by email and status_data 'success'
    // const q = query(geo_transactions, where('email', '==', email), where('status_data', '==', 'success'));
    const q = query(geo_transactions, where('email', '==', email));
    const geoTransactionSnapshot = await getDocs(q);
    const geoTransactionList = geoTransactionSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    // return geoTransactionList;
    return geoTransactionList.filter(
      (transaction: any) =>
        transaction.status_data === 'success' || transaction.table_ready === true
    );
  } catch (error) {
    console.error('Gagal mengambil data dari Firestore:', error);
    return [];
  }
};


export const getUserById = async ( docId: string) => {
  try {
    const docRef = doc(db,user_geo , docId); // Perbaiki di sini
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log('Document data:', docSnap.data());
      return docSnap.data();
    } else {
      // console.log('No such document!');
      return null;
    }
  } catch (error) {
    // console.error('Error getting document:', error);
    throw error; // Mengembalikan error jika terjadi kesalahan
  }
};