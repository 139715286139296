import {api} from './api';
interface PromptRequestBody {
    prompt: string;
}

const postPrompt = async (prompt: string): Promise<any> => {
    const url: string = 'api/data/recomended-data';
    const requestBody: PromptRequestBody = { prompt };
    
    try {
        const { data } = await api.post<any>(url, requestBody);
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error occurred while posting prompt:', error);
        throw error;
    }
};

const ombenApi = {
    postPrompt,
};

export default ombenApi;
