import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { 
    drawerActionClose,
    drawerActionOpen,
    setActiveBaseMaps,
    modalButActionClose,
    modalBuyActionOpen,
    modalQuoteActionClose,
    modalQuotectionOpen,
    cardMapActionClose,
    cardMapActionOpen,
    setGeomDatas,
    setGeojsonDatas,
    setGeoLinks,
    modalFreeActionClose,
    modalFreeActionOpen,
    modalTopActionClose,
    modalTopActionOpen
    } from '../action/drawer.action';

const useReduxDrawer = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
         isOpen, 
         basemapUrl,
         isOpenModalBuy,
         isOpenModalQuote,
         isOpenCardMap,
         setGeom,
         geojsonData,
         geoLink,
         isOpenModalGetFree,
         isOpenModalTopUp
        }  = useSelector((state: RootState) => state.drawer);

    const drawerOpen = async (status: any) => {
        dispatch(drawerActionOpen(status));
    };

    const drawerClose = async (status: any) => {
        dispatch(drawerActionClose(status));
    };
    const ModalBuyOpen = async (status: any) => {
        dispatch(modalBuyActionOpen(status));
    };

    const ModalBuyClose = async (status: any) => {
        dispatch(modalButActionClose(status));
    };

    const ModalQuoteOpen = async (status: any) => {
        dispatch(modalQuotectionOpen(status));
    };

    const ModalQuoteClose = async (status: any) => {
        dispatch(modalQuoteActionClose(status));
    };
    const setBaseMaps = async (status: any) => {
        dispatch(setActiveBaseMaps(status));
    };
    const cardMapOpen = async (status: any) => {
        dispatch(cardMapActionOpen(status));
    };

    const cardMapClose = async (status: any) => {
        dispatch(cardMapActionClose(status));
    };

    const setGeomData = async (status: any) => {
        dispatch(setGeomDatas(status));
    };

    const setGeoJsonData = async (status: any) => {
        dispatch(setGeojsonDatas(status));
    };
    const setGeoLink = async (status: any) => {
        dispatch(setGeoLinks(status));
    };

    const ModalFreeOpen = async (status: any) => {
        dispatch(modalFreeActionOpen(status));
    };

    const ModalFreeClose = async (status: any) => {
        dispatch(modalFreeActionClose(status));
    };
    const ModalTopUpClose = async (status: any) => {
        dispatch(modalTopActionClose(status));
    };

    const ModalTopUpOpen= async (status: any) => {
        dispatch(modalTopActionOpen(status));
    };
    return {
        drawerOpen, 
        drawerClose,
        isOpen,
        isOpenModalBuy,
        setBaseMaps,
        basemapUrl,
        ModalBuyOpen,
        ModalBuyClose,
        isOpenModalQuote,
        ModalQuoteOpen,
        ModalQuoteClose,
        cardMapOpen,
        cardMapClose,
        isOpenCardMap,
        setGeomData,
        setGeom,
        geojsonData,
        setGeoJsonData,
        setGeoLink,
        geoLink,
        ModalFreeOpen,
        ModalFreeClose,
        isOpenModalGetFree,
        ModalTopUpClose,
        ModalTopUpOpen,
        isOpenModalTopUp
    };
};

export default useReduxDrawer;
