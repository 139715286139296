import React, { useState } from 'react';
import { Space,Button, Tooltip} from 'antd';
import { GiArtificialIntelligence } from "react-icons/gi";
import { InputText } from '../inputText';


interface DrawToolsSpaceProps {
    drawerOpen: boolean;
}

export const AiTools: React.FC<DrawToolsSpaceProps> = ({ drawerOpen }) => {
    const [showBaseMaps, setShowBaseMaps] = useState(false);

    const handleBaseMapsButtonClick = () => {
        setShowBaseMaps(!showBaseMaps);
    };
    return (
        <React.Fragment>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    left: drawerOpen ? '390px' : '15px',
                    top: 158,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip 
                    title={<p style={{color:'black'}}>Prompt Here</p>}
					placement="right"
					color='white'
                    >
                    <Button
                       id='end-point'
                        style={{
                            backgroundColor: 'white', // Ubah warna tombol menjadi putih
                            color: 'black', // Ubah warna teks menjadi hitam
                            height: "30px",
                            width: "32px",
                            display: 'flex',
                            justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                            alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                        }}
                        icon={
                            <span className='anticon'>
                                <GiArtificialIntelligence />
                            </span>
                        }
                        onClick={handleBaseMapsButtonClick}
                    >
                    </Button>
                </Tooltip>
            </Space>
             {showBaseMaps && <InputText drawerOpen={drawerOpen} />}
        </React.Fragment>
    )
}