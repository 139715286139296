import React, { useRef, useEffect, useState } from 'react';
import 'maplibre-gl/dist/maplibre-gl.css';
import LoaderMap from '../../shared/loader/loaderMap';
import maplibregl from 'maplibre-gl';
// import { getEsriWorldImageryStyle } from './helper/layer/layer.tile';
import { SideMenu } from '../sider/side.menu';
import { LocationTools } from '../../shared/map/button/location';
import { ZoomTools } from '../../shared/map/button/zoom';
import { LayerTools } from '../../shared/map/button/layer';

export const MapComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maplibregl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [isLoading, ] = useState(false);

  useEffect(() => {
    if (map.current || !mapContainer.current) return;

    map.current = new maplibregl.Map({
      container: mapContainer.current,
    //   style: getEsriWorldImageryStyle(),  // Use the imported style function
      style:'https://api.maptiler.com/maps/streets/style.json?key=IkttUJmMfqWCx0g43vGM',
      center: [114.17487380816897, 0.3746499288735805],
      zoom: 4,
      maxZoom:17
    });

    map.current.on('load', () => {
      setMapLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;
  }, [mapLoaded]);

  return (
    <div>
      <div ref={mapContainer} style={{ width: '100%', height: '100vh' }} />
      {isLoading && <LoaderMap />}
      <SideMenu  map={map.current}/>
      {children}
      <LocationTools/>
      <ZoomTools/>
      <LayerTools/>
    </div>
  );
};
