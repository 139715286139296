// drawerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';

interface UserState {
    user: User | null;
    isLoggedIn: boolean,
    isloading: boolean; // Add loading property
    isLoginModalVisible: boolean;
    isLoginModalCardVisible: boolean;
    dataUser: any
  }

  const initialState: UserState = {
    user: null,
    isLoggedIn: false,
    isloading: false, // Initialize loading state
    isLoginModalVisible: false,
    isLoginModalCardVisible: false,
    dataUser:null,
  };

  const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      setUser: (state, action: PayloadAction<User | null>) => {
        state.isloading = false; 
        state.isLoggedIn = !action.payload;
        state.user = action.payload;
      },
      clearUser: (state) => {
        state.isloading = false; 
        state.user = null;
      },
      openModalLogin(state) {
        state.isLoginModalVisible = true;
      },
      closeModalLogin(state) {
        state.isLoginModalVisible = false;
      },
      openModalCardLogin(state) {
        state.isLoginModalCardVisible = true;
      },
      closeModalCardLogin(state) {
        state.isLoginModalCardVisible = false;
      },
      getUserDetail: (state, action: PayloadAction<any>) => {
        state.dataUser = action.payload;
      },
    },
  });
  

  export const { 
    setUser, 
    clearUser, 
    openModalLogin,
    closeModalLogin,
    openModalCardLogin,
    closeModalCardLogin,
    getUserDetail
  } = userSlice.actions;
  export default userSlice.reducer;