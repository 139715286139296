import { css, injectGlobal } from '@emotion/css';

export const SharedComponentDrawerTypeOneStyle = (prefix?: string) =>
	css({
		[`.${prefix}-drawer-content-wrapper:has(> &.${prefix}_SharedComponentDrawerTypeOne__closed)`]: {
			width: '42px !important',
		},
		[`.${prefix}-drawer-body`]: {
			padding: 12,
		},
		[`&.${prefix}_SharedComponentDrawerTypeOne`]: {
			height: '100%',
			zIndex: 1,
			'&__closed': {},
			[`.${prefix}_SharedComponentDrawerTypeOne__icon`]: {
				display: 'none',
				[`&-open, 
						&-close`]: {
					cursor: 'pointer',
					display: 'block',
					background: 'white',
					position: 'absolute',
					width: '28px',
					height: '28px',
					borderRadius: '28px',
					boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.14)',
					padding: '6px',
					top: '30px',
					zIndex: 9999,
					'&:hover': {
						background: '#0088EA',
						color: 'white',
					},
					'&-right': {
						left: '-14px',
					},
					'&-left': {
						right: '-14px',
					},
				},
			},
		},
	});
export const SharedComponentDrawerTypeOneMaskStyle = (prefix?: string) =>
	injectGlobal({
		[`.${prefix}-drawer`]: {
			zIndex: 2,
		},
	});
