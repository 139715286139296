import * as turf from '@turf/turf';
import maplibregl from 'maplibre-gl';
export interface GeoJSONPolygon {
    type: 'Polygon';
    coordinates: number[][][];
}

export interface GeoJSONFeature {
    type: 'Feature';
    geometry: GeoJSONPolygon;
    properties: Record<string, any>;
}

export const renderGeocodeResultToMap = (
    map: maplibregl.Map,
    geojson: GeoJSONFeature,
    sourceId: string = 'geojson-source',
    layerId: string = 'geojson-layer'
): void => {
    // Remove previous layer and source if they exist
    if (map.getSource(sourceId)) {
        if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
        }
        map.removeSource(sourceId);
    }

    // Add new source
    map.addSource(sourceId, {
        type: 'geojson',
        data: geojson,
    });

    // Add new layer
    map.addLayer({
        id: layerId,
        type: 'line',
        source: sourceId,
        layout: {},
        paint: {
            'line-color': '#4A7FC0',
            'line-width': 3,
        },
    });
    const bbox = turf.bbox(geojson);
    // Convert the bounding box to a LngLatBounds object
    const bounds = new maplibregl.LngLatBounds(
        [bbox[0], bbox[1]], 
        [bbox[2], bbox[3]] 
    );

    map.fitBounds(bounds, {
        padding: 20, 
        maxZoom: 15,
        duration: 1000 
    })
};
