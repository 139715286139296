import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { CSSProperties } from 'react';
import Container from "../../../../style/styledComponent/Container";
import { fadein } from '../../../../style/keyframes';
import Imge from '../../../../asesst/new/lidar.svg';
import Imge2 from '../../../../asesst/new/spasialinsight.svg';
import Imge3 from '../../../../asesst/new/locationscore.svg';
import Imge6 from '../../../../asesst/new/custos.svg';
import Imge4 from '../../../../asesst/new/sateliteimg.svg';
import Imge5 from '../../../../asesst/new/image (7).svg';
import Imge7 from '../../../../asesst/new/mapidservice.svg';
import servicesIcon from '../../../../asesst/new/icon/services.svg';
import Icon1 from '../../../../asesst/new/icon/dbic.svg';
import Icon2 from '../../../../asesst/new/icon/satelite.svg';
import Icon3 from '../../../../asesst/new/icon/layeric.svg';
import Icon4 from '../../../../asesst/new/icon/earth.svg'
import Icon5 from '../../../../asesst/new/icon/search.svg';
import Icon6 from '../../../../asesst/new/icon/poi.svg';
import Icon7 from '../../../../asesst/new/icon/retangle.svg';

import subIcon1 from '../../../../asesst/new/icon/landsurvey.svg'
import subIcon2 from '../../../../asesst/new/icon/water.svg'
import subIcon3 from '../../../../asesst/new/icon/carbon_drone.svg'
import subIcon4 from '../../../../asesst/new/icon/engsurvey.svg'
import subIcon5 from '../../../../asesst/new/icon/fieldSurvey.svg'
import subIconlist from '../../../../asesst/new/icon/Ellipse 1.svg'

const { Title, Text } = Typography;

const SectionServices2 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('On-Demand Data Collections');

  const handleMenuItemClick = (item: string) => {
    setSelectedMenuItem(item);
  };

  const containerStyles: React.CSSProperties = {
    position: "relative",
    width: "100%",
    height: "auto",
    padding: windowWidth <= 768 ? '10px 5%' : '20px 7%',
    backgroundSize: "cover",
    backgroundColor: "#F7F9FC",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const styles: { [key: string]: CSSProperties } = {
    integrationHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
      flexDirection: windowWidth <= 768 ? 'column' : 'row',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
    },
    container: {
      display: 'flex',
      minHeight: '100vh',
      backgroundColor: "#F7F9FC",
    },
    sidebar: {
      width: windowWidth <= 768 ? '100%' : '25%',
      backgroundColor: "#F7F9FC",
      padding: windowWidth <= 768 ? '50px 0' : '10px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: windowWidth <= 768 ? 'flex-start' : 'center',
      justifyContent: 'flex-start',
    },
    imageContainer: {
      width: windowWidth <= 768 ? '100%' : '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: windowWidth <= 768 ? '24px' : '10px 0',
    },
    content: {
      width: windowWidth <= 768 ? '100%' : '25%',
      padding: windowWidth <= 768 ? '24px' : '0',
      backgroundColor: "#F7F9FC",
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      padding: windowWidth <= 768 ? '10px 20px' : '10px',
      cursor: 'pointer',
      height: windowWidth <= 768 ? '72px' : 'auto',
      width: windowWidth <= 768 ? '250px' : '90%',
      fontSize: windowWidth <= 768 ? '16px' : '14px',
      transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      borderRadius: '4px',
      marginBottom: '10px',
      boxSizing: 'border-box',
    },
    menuItemIcon: {
      marginRight: windowWidth <= 768 ? '10px' : '5px',
      flexShrink: 0,
    },
    selectedMenuItem: {
      transform: 'translateY(-5px)',
      backgroundColor: '#fff',
      transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      fontSize: windowWidth <= 768 ? '20px' : '16px',
      height: windowWidth <= 768 ? '72px' : 'auto',
      padding: windowWidth <= 768 ? '40px' : '10px',
      width: windowWidth <= 768 ? '280px' : '90%',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    button: {
      display: 'block',
      width: '100%',
      margin: '10px 0',
      transition: 'background 0.3s',
    },
    image: {
      width: windowWidth <= 768 ? '100%' : '90%',
      maxWidth: '600px',
      height: 'auto',
      display: 'block',
      margin: '0 auto',
    },
    title: {
      marginTop: '20px',
      color: '#5A5A5A',
      textAlign: windowWidth <= 768 ? 'center' : 'start',
    },
    list: {
      marginTop: '20px',
      paddingLeft: windowWidth <= 768 ? '25%' : '0px',
      listStyleType: 'disc',
      color: 'black',
    },
    listItem: {
      placeSelf: windowWidth <= 768 ? 'center' : 'flex-start',
      display: 'flex',
      alignContent: 'center',
      padding: '10px 0',
      listStyle: 'none',
      color: 'none',
    },
    listText: {
      fontSize: '16px',
      fontWeight: '700',
      color: '#5A5A5A',
      fontFamily: '"Jakarta Sans", sans-serif',
    },
    listDescription: {
      fontSize: '16px',
      fontFamily: '"Jakarta Sans", sans-serif',
      lineHeight: '26px',
      fontWeight: '400',
      color: '#5A5A5A',
      marginBottom: '20px',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
    },
    listSubDescription: {
      fontSize: '16px',
      fontFamily: '"Jakarta Sans", sans-serif',
      lineHeight: '26px',
      fontWeight: '400',
      color: '#5A5A5A',
      marginLeft: '20px',
      textAlign: windowWidth <= 768 ? 'center' : 'left',
    },
    icon: {
      marginRight: windowWidth <= 768 ? '' : '10px',
      marginBottom: windowWidth <= 768 ? '10px' : '0',
      color: '#316DBA',
    },
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'On-Demand Data Collections':
        return {
          image: Imge,
          title: 'On-Demand Data Collections',
          description: 'Our on-demand data collection services offer precise and reliable data across various domains :',
          icon: Icon1,
          method: [
            {
              iconMethod: subIcon1,
              title: "Land Surveying",
              description: ''
            },
            {
              iconMethod: subIcon2,
              title: "Water Surveying",
              description: ''
            },
            {
              iconMethod: subIcon3,
              title: "Aerial Surveying",
              description: ''
            },
            {
              iconMethod: subIcon4,
              title: "Engineering Surveying",
              description: ''
            },
            {
              iconMethod: subIcon5,
              title: "Field Surveying",
              description: ''
            }
          ]
        };
      case 'Satellite Images':
        return {
          image: Imge4,
          title: 'Satellite Images',
          description: 'Access high-resolution visual data that provides a clear and intuitive understanding of the spatial context. Our satellite imagery offers detailed views of the Earths surface, aiding in monitoring, analysis, and decision-making processes across various sectors.',
          icon: Icon2,
          method: []
        };
      case 'Analysis- Ready Dataset':
        return {
          image: Imge5,
          title: 'Analysis- Ready Dataset',
          description: 'Benefit from raw, well-organized geospatial data that is ready for analysis. Our datasets are meticulously prepared to ensure they are accurate, comprehensive, and easy to integrate into your geospatial analyses and workflows.',
          icon: Icon3,
          method: []
        };
      case 'Spatial Insight':
        return {
          image: Imge2,
          title: 'Spatial Insight',
          description: 'Leverage processed and analyzed data that provides actionable insights. Our spatial insights transform raw geospatial data into meaningful information, helping you uncover patterns, trends, and opportunities for better decision-making.',
          icon: Icon4,
          method: []
        };
      case 'Location Scores':
        return {
          image: Imge3,
          title: 'Location Scores',
          description: 'Utilize quantitative scores that facilitate decision-making based on specific criteria. Our location scores offer a numerical representation of various factors, such as suitability, risk, and potential, enabling you to compare and evaluate different locations effectively.',
          icon: Icon5,
          method: []
        };
      case 'Curated GIS Platform':
        return {
          image: Imge7,
          title: 'Curated GIS Platform',
          description: 'Access the best in geospatial technology through our curated GIS platform. We offer top-tier solutions from industry leaders like MAPID, all integrated under the GeoSquare.ai umbrella for your convenience, ensuring you have the tools you need to achieve geospatial excellence.',
          icon: Icon5,
          method: []
        };
      case 'Custom Solution':
        return {
          image: Imge6,
          title: 'Custom Solution',
          description: 'Tailor our offerings to meet your specific needs with our custom solutions :',
          icon: Icon5,
          method: [
            {
              iconMethod: subIconlist,
              title: "Consultation:",
              description: 'Work with our experts to develop strategies, solve complex geospatial challenges, and optimize your geospatial operations.'
            },
            {
              iconMethod: subIconlist,
              title: "Customized Model & Scoring:",
              description: 'Develop bespoke analytical frameworks and scoring models tailored to your unique requirements and objectives.'
            },
            {
              iconMethod: subIconlist,
              title: "Personalized GIS Platform:",
              description: 'Create a GIS platform customized to your specific workflows, ensuring seamless integration and maximum efficiency.'
            }
          ]
        };
      default:
        return {
          image: Imge,
          title: 'Data Collection Methods',
          description: '',
          icon: Icon1,
          method: []
        };
    }
  };

  const { image, title, description, method } = renderContent();
  const headerStyles: React.CSSProperties = {
    padding: '50px 0',
    textAlign: 'left',
    width: '100%',
  };
  const integrationText: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
    flexDirection: windowWidth <= 768 ? 'column' : 'row',
    textAlign: windowWidth <= 768 ? 'center' : 'left',
  };
  const titleStyle: React.CSSProperties = {
    fontWeight: '800',
    fontSize: windowWidth <= 768 ? '24px' : '38px',
    color: '#333',
    marginTop: '10px',
    fontFamily: '"Jakarta Sans", sans-serif',
    textAlign: windowWidth <= 768 ? 'center' : 'left',
  }
  return (
    <>
      <Container data-testid="app" style={containerStyles}
        css={{
          width: "100%",
          height: "100%",
          opacity: "0",
          animation: `1s ease-in 1s 1 both ${fadein}`,
        }}
      >
        <div style={headerStyles}>
          <Row justify="start" align="middle">
            <Col xs={24} md={12}>
              <div style={integrationText}>
                <img alt="example" src={servicesIcon} style={{
                  marginRight: windowWidth <= 768 ? '' : '10px',
                  marginBottom: windowWidth <= 768 ? '10px' : '0',
                  color: '#316DBA',
                }} />
                <Text style={{ fontFamily: '"Jakarta Sans", sans-serif', color: '#316DBA', fontSize: '24px', fontWeight: '800' }}>Services</Text>
              </div>
              <Title style={titleStyle}>Geospatial made easy!</Title>
              <Text style={{ fontFamily: '"Jakarta Sans", sans-serif', fontSize: '16px', color: '#595959', display: 'block', lineHeight: '1.5', textAlign: windowWidth <= 768 ? 'center' : 'left' }}>
                From data collection to actionable insights, we cover the entire geospatial value chain, ensuring you have everything needed from data acquisition to decision-making.
              </Text>
            </Col>
          </Row>
        </div>
      </Container>

      <Container
        css={{
          backgroundColor: "#F7F9FC",
          opacity: "0",
          animation: `1s ease-in 1s 1 both ${fadein}`,
          width: "100%",
          padding: windowWidth <= 768 ? '$2 $5' : '$2 $10',
          display: "flex",
          alignItems: "flex-start",
          justifyContent: windowWidth <= 768 ? "center" : "space-between",
          flexDirection: windowWidth <= 768 ? "column" : "row",
        }}
      >
        <div style={{ ...styles.sidebar }}>
          {[
            'On-Demand Data Collections',
            'Satellite Images',
            'Analysis- Ready Dataset',
            'Spatial Insight',
            'Location Scores',
            'Curated GIS Platform',
            'Custom Solution'
          ].map((item, index) => (
            <div
              key={index}
              style={{
                ...styles.menuItem,
                ...(selectedMenuItem === item ? styles.selectedMenuItem : {})
              }}
              onClick={() => handleMenuItemClick(item)}
            >
              {item === 'On-Demand Data Collections' && <img alt="example" src={Icon1} style={styles.menuItemIcon} />}
              {item === 'Satellite Images' && <img alt="example" src={Icon2} style={styles.menuItemIcon} />}
              {item === 'Analysis- Ready Dataset' && <img alt="example" src={Icon3} style={styles.menuItemIcon} />}
              {item === 'Spatial Insight' && <img alt="example" src={Icon5} style={styles.menuItemIcon} />}
              {item === 'Location Scores' && <img alt="example" src={Icon4} style={styles.menuItemIcon} />}
              {item === 'Curated GIS Platform' && <img alt="example" src={Icon6} style={styles.menuItemIcon} />}
              {item === 'Custom Solution' && <img alt="example" src={Icon7} style={styles.menuItemIcon} />}
              <span style={{ marginLeft: '10px', fontFamily: '"Jakarta Sans", sans-serif', color: '#333333', fontSize: '18px', fontWeight: '200px' }}>{item}</span>
            </div>
          ))}
        </div>
        <div style={{ ...styles.imageContainer, width: windowWidth <= 768 ? '100%' : '48%' }}>
          <img src={image} alt={title} style={{ ...styles.image, width: windowWidth <= 768 ? '100%' : '80%' }} />
        </div>
        <div style={{ ...styles.content }}>
          <Title level={4} style={{ ...styles.title }}>{title}</Title>
          <Text style={{ ...styles.listDescription }}>
            {description}
          </Text>
          {method.map((meth, index) => (
            typeof meth === 'object' && meth.title ? (
              <li key={index} style={styles.listItem}>
                <Row justify="start" align="middle">
                  <img
                    src={meth.iconMethod}
                    alt="icon method"
                    style={{
                      marginRight: windowWidth <= 768 ? '' : '10px',
                      // marginBottom: windowWidth <= 768 ? '10px' : '0',
                      color: '#316DBA',
                      ...styles.menuItemIcon,
                    }}
                  />
                  <Text style={styles.listText}>{meth.title}</Text>
                  <Text style={styles.listSubDescription}>{meth.description}</Text>
                </Row>

              </li>
            ) : null
          ))}
        </div>
      </Container>
    </>
  );
};

export default SectionServices2;
