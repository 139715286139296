import React from 'react';
import { 
	Button,
    Space,
} from 'antd';
import logogeosquare from "../../asesst/Icons/product/Geosquare-logo1.png";

export const LogoTools: React.FC<any> =  ({onClick}) => {
	return (
		<React.Fragment>
			<Space
			onClick={onClick}
				direction='vertical'
				style={{
					position: 'absolute',
                    right: '15px',
					top: 24,
					zIndex: 100,
					transition: '0.25s ease-in-out',
				}}
			>
				 <Button
					style={{
					height: '70px',
					padding:'0px',
					backgroundColor: 'white', // Ubah warna tombol menjadi putih
					color: 'black', // Ubah warna teks menjadi hitam
					}}
            	type="primary"

          >
          
             <img
                src={logogeosquare} // Ganti yourImageSrc dengan sumber gambar Anda
                alt="GeoSquare.ai"
                style={{ cursor: "pointer", width: "70px", height: "auto" }}
                />
			</Button>
			</Space>
            </React.Fragment>
    )
}