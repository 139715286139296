import {
    polygon,
    featureCollection,
    feature,
    area,
    intersect,
    bboxPolygon,
    Feature,
    union,
    Geometry,
    Properties,
    Polygon,
    BBox,
    coordAll,
    simplify,
    MultiPolygon
  } from '@turf/turf';
  
  class PetainGrid {
    CODE_ALPHABET: string[][] = [
      ['2', '3', '4', '5', '6'],
      ['7', '8', '9', 'C', 'E'],
      ['F', 'G', 'H', 'J', 'L'],
      ['M', 'N', 'P', 'Q', 'R'],
      ['T', 'V', 'W', 'X', 'Y'],
    ];
    d: number[] = [5, 2, 5, 2, 5, 2, 5, 2, 5, 2, 5, 2, 5, 2, 5];
    size_level: {
      [key: number]: number;
    } = {
        10000000: 1,
        5000000: 2,
        1000000: 3,
        500000: 4,
        100000: 5,
        50000: 6,
        10000: 7,
        5000: 8,
        1000: 9,
        500: 10,
        100: 11,
        50: 12,
        10: 13,
        5: 14,
        1: 15,
      };
    CODE_ALPHABET_VALUE: {
      [key: string]: number[];
    } = {};
    CODE_ALPHABET_: {
      [key: string]: string[];
    } = {};
    constructor() {
      this.CODE_ALPHABET_ = {
        '5': ([] as string[]).concat(...this.CODE_ALPHABET),
        '2': ([] as string[]).concat(
          ...this.CODE_ALPHABET.slice(0, 2).map((c) => c.slice(0, 2)),
        ),
        c2: ['2', '3'],
        c12: [
          'V',
          'X',
          'N',
          'M',
          'F',
          'R',
          'P',
          'W',
          'H',
          'G',
          'Q',
          'L',
          'Y',
          'T',
          'J',
        ],
      };
  
      this.CODE_ALPHABET_VALUE = {};
      this.CODE_ALPHABET.forEach((row, idx1) => {
        row.forEach((val, idx2) => {
          this.CODE_ALPHABET_VALUE[val] = [idx1, idx2];
        });
      });
    }
  
    gidToBound(gid: string): BBox {
      let lat_ranged = [-216, 233.157642055036];
      let lon_ranged = [-217, 232.157642055036];
  
      for (let idx = 0; idx < gid.length; idx++) {
        const char = gid[idx];
        const part_x = (lon_ranged[1] - lon_ranged[0]) / this.d[idx];
        const part_y = (lat_ranged[1] - lat_ranged[0]) / this.d[idx];
        const shift_x = part_x * this.CODE_ALPHABET_VALUE[char][1];
        const shift_y = part_y * this.CODE_ALPHABET_VALUE[char][0];
        lon_ranged = [lon_ranged[0] + shift_x, lon_ranged[0] + shift_x + part_x];
        lat_ranged = [lat_ranged[0] + shift_y, lat_ranged[0] + shift_y + part_y];
      }
  
      return [lon_ranged[0], lat_ranged[0], lon_ranged[1], lat_ranged[1]];
    }
  
    lonlatTOgid(longitude: number, latitude: number, level: number): string {
      let lat_ranged = [-216, 233.157642055036];
      let lon_ranged = [-217, 232.157642055036];
      let gid: string = '';
      for (let idx = 0; idx < level; idx++) {
        const position_x = Math.floor(
          ((longitude - lon_ranged[0]) / (lon_ranged[1] - lon_ranged[0])) *
          this.d[idx],
        );
        const position_y = Math.floor(
          ((latitude - lat_ranged[0]) / (lat_ranged[1] - lat_ranged[0])) *
          this.d[idx],
        );
        const part_x = (lon_ranged[1] - lon_ranged[0]) / this.d[idx];
        const part_y = (lat_ranged[1] - lat_ranged[0]) / this.d[idx];
        const shift_x = part_x * position_x;
        const shift_y = part_y * position_y;
        lon_ranged = [lon_ranged[0] + shift_x, lon_ranged[0] + shift_x + part_x];
        lat_ranged = [lat_ranged[0] + shift_y, lat_ranged[0] + shift_y + part_y];
        gid += this.CODE_ALPHABET[position_y][position_x];
      }
      return gid;
    }
  
    _gid_to_geometry(gid: string) {
      const a = this.gidToBound(gid);
      return [
        [a[0], a[1]],
        [a[0], a[3]],
        [a[2], a[3]],
        [a[2], a[1]],
        [a[0], a[1]],
      ];
    }
  
    _toFeature(gid: string) {
      return feature(
        polygon([this._gid_to_geometry(gid)]).geometry,
        {
          gid: gid,
        },
        {
          id: gid,
        },
      );
    }
  
    _area_ratio(
      a: Feature<any, Properties> | Geometry,
      b: Feature<any, Properties> | Geometry,
    ): number {
      if ('geometry' in a && 'geometry' in b) {
        return intersect(a.geometry, b.geometry)
          ? area(intersect(a, b)?.geometry as Polygon) / area(a.geometry)
          : 0;
      }
      return 0;
    }
  
    getOuterCoordinates(featureCollection: { features: any[] }) {
      let outerCoordinates: any[] = [];
  
      featureCollection.features.forEach((feature) => {
        if (feature.geometry.type === 'Polygon') {
          const coordinates = coordAll(feature);
          outerCoordinates = outerCoordinates.concat(coordinates);
        }
      });
  
      return outerCoordinates;
    }
  
    _get_contained_keys(
      geometry: Feature<Polygon | MultiPolygon, Properties>,
      initial_key: string,
      resolution: any[],
      parent: boolean,
    ) {
      // 
      if (initial_key !== '2') {
        const g = intersect(geometry, bboxPolygon(this.gidToBound(initial_key)));
        geometry = g ? g : geometry;
  
      } else {
        initial_key = initial_key[0];
      }
      const contained_keys: Feature<Polygon, Properties>[] = [];
      const func = (key: string, approved: boolean) => {
        if (key.length > resolution[1]) {
          return;
        }
        if (approved) {
          if (key.length < 2) {
            return;
          }
          if (resolution[0] <= key.length && key.length <= resolution[1]) {
            contained_keys.push(this._toFeature(key));
          } else {
            for (const child_key of this._to_children(key)) {
              func(child_key, true);
            }
          }
        } else {
          const area_ratio = this._area_ratio(
            bboxPolygon(this.gidToBound(key)),
            geometry,
          );
          if (area_ratio === 0) {
            const last_idx = this.CODE_ALPHABET_[this.d[0]].indexOf(
              key[key.length - 1],
            );
            if (last_idx < 25 && key.length === 1) {
              if (last_idx >= 24) {
                return;
              }
              func(
                key.slice(0, -1) +
                this.CODE_ALPHABET_[this.d[0]][last_idx + 1][0],
                false,
              );
            }
          } else if (area_ratio === 1) {
            func(key, true);
          } else if (key.length === resolution[1] && parent) {
            contained_keys.push(this._toFeature(key));
          } else if (
            key.length === resolution[1] &&
            area_ratio > 0.5 &&
            !parent
          ) {
            contained_keys.push(this._toFeature(key));
          } else if (key.length === resolution[1]) {
            return;
          } else {
            for (const child_key of this._to_children(key)) {
              func(child_key, false);
            }
          }
        }
      };
  
      func(initial_key, false);
      return contained_keys;
    }
  
    polyfill(
      geometry: Feature<Polygon, Properties>,
      size: number | number[],
      start = '2',
      parent = false,
    ) {
      if ('coordinates' in geometry) {
        geometry = feature(geometry) as any;
      }
      if (Array.isArray(size)) {
        if (size[0] < size[1]) {
          throw new Error('Size must be in [min, max] format');
        }
        if (!(size[0] in this.size_level) || !(size[1] in this.size_level)) {
          throw new Error(`Size must be in ${Object.keys(this.size_level)}`);
        }
        size = [this.size_level[size[0]], this.size_level[size[1]]];
      } else {
        if (!(size in this.size_level)) {
          throw new Error(`Size must be in ${Object.keys(this.size_level)}`);
        }
        size = [this.size_level[size], this.size_level[size]];
      }
      return this._get_contained_keys(geometry, start, size, parent);
    }
  
    polyfill_bbox(
      xmin: number,
      ymin: number,
      xmax: number,
      ymax: number,
      resolution: number,
    ) {
      const ids: string[] = [];
      const gid_max = this._gid_to_geometry(
        this.lonlatTOgid(xmax, ymax, resolution),
      );
      const gid_min = this._gid_to_geometry(
        this.lonlatTOgid(xmin, ymin, resolution),
      );
      const value_p = gid_max[2][0] - gid_max[0][0];
      const x = gid_min[0][0] + value_p / 2;
      const y = gid_min[0][1] + value_p / 2;
      const xend = gid_max[2][0] + value_p / 2;
      const yend = gid_max[2][1] + value_p / 2;
      for (let i = x; i < xend; i += value_p) {
        for (let j = y; j < yend; j += value_p) {
          ids.push(this.lonlatTOgid(i, j, resolution));
        }
      }
      return {
        gid: ids,
        geometry: ids.map((id) => this._toFeature(id)),
      };
    }
  
    geometryToFeaturecollection(
      geometry: Feature<Polygon, Properties>,
      size: number | number[],
      start = '2',
      parent = false,
    ) {
      return featureCollection(this.polyfill(geometry, size, start, parent));
    }
  
    geometrytoOuterPolygon(
      geometry: Feature<Polygon, Properties>,
      size: number,
      start = '2',
      parent = false,
    ): {
      outerPolygon: Feature<Polygon, Properties> | null;
      gids: string[];
      total_5: number;
      total_50: number;
    } {
      const sizeList = [50000, 10000, 5000, 1000, 500, 100, 50, 10];
      const selectedSize = [5000, 1000, 500, 100, 5, 5, 5, 5];
      let featuresCollection: any;
      const countVertices = geometry.geometry.coordinates[0].length;
      let simpliedGeometry;
      if (countVertices > 1000) {
        simpliedGeometry = simplify(geometry, { tolerance: 0.001 });
      } else if (countVertices > 100) {
        simpliedGeometry = simplify(geometry, { tolerance: 0.0005 });
      } else {
        simpliedGeometry = geometry;
      }
      for (let i = 0; i < sizeList.length; i++) {
        featuresCollection = this.geometryToFeaturecollection(
          simpliedGeometry,
          sizeList[i],
          start,
          false,
        );
        if (featuresCollection.features.length > 0) {
          size = selectedSize[i];
          break;
        }
      }
      featuresCollection = this.geometryToFeaturecollection(
        simpliedGeometry,
        [10000, size],
        start,
        true,
      );
      const features = featuresCollection.features;
      let outerPolygon: any = features[0].geometry; // Extract the geometry property
      for (let i = 1; i < features.length; i++) {
        outerPolygon = union(outerPolygon, features[i].geometry); // Extract the geometry property
      }
      let total_5 = 0;
      let total_50 = 0;
      for (const feature of features) {
        const gid = feature.properties.gid;
        if (gid.length === 14) {
          total_5 += 1;
          total_50 += 1;
        } else if (gid.length === 13) {
          total_5 += 4;
          total_50 += 1;
        } else if (gid.length === 12) {
          total_5 += 100;
          total_50 += 1;
        } else if (gid.length === 11) {
          total_5 += 200;
          total_50 += 4;
        } else if (gid.length === 10) {
          total_5 += 5000;
          total_50 += 100;
        } else if (gid.length === 9) {
          total_5 += 10000;
          total_50 += 200;
        } else if (gid.length === 8) {
          total_5 += 250000;
          total_50 += 5000;
        } else if (gid.length === 7) {
          total_5 += 500000;
          total_50 += 10000;
        } else if (gid.length === 6) {
          total_50 += 250000;
        } else if (gid.length === 5) {
          total_50 += 500000;
        }
      }
      return {
        outerPolygon: outerPolygon,
        gids: features.map((f: any) => f.properties.gid),
        total_5: total_5,
        total_50: total_50,
      };
    }
  
    _countGids(
      geometry: Feature<Polygon, Properties>,
      size: number,
      start = '2',
      parent = false,
    ) {
      if (size !== 5 && size !== 50) {
        throw new Error('Size must be 5 or 50');
      }
      const sizeList = [50000, 10000, 5000, 1000, 500, 100];
      const selectedSize = [5000, 1000, 500, 100, 50, 5].map(() => size);
      let featuresCollection: any;
      for (let i = 0; i < sizeList.length; i++) {
        featuresCollection = this.geometryToFeaturecollection(
          geometry,
          sizeList[i],
          start,
          parent,
        );
        if (featuresCollection.features.length > 0) {
          size = selectedSize[i];
          break;
        }
      }
      featuresCollection = this.geometryToFeaturecollection(
        geometry,
        [10000, size],
        start,
        parent,
      );
      const features = featuresCollection.features;
      let total = 0;
      if (size === 5) {
        for (const feature of features) {
          const gid = feature.properties.gid;
          if (gid.length === 14) {
            total += 1;
          } else if (gid.length === 13) {
            total += 4;
          } else if (gid.length === 12) {
            total += 100;
          } else if (gid.length === 11) {
            total += 200;
          } else if (gid.length === 10) {
            total += 5000;
          } else if (gid.length === 9) {
            total += 10000;
          } else if (gid.length === 8) {
            total += 250000;
          } else if (gid.length === 7) {
            total += 500000;
          }
        }
      } else if (size === 50) {
        for (const feature of features) {
          const gid = feature.properties.gid;
          if (gid.length === 12) {
            total += 1;
          } else if (gid.length === 11) {
            total += 4;
          } else if (gid.length === 10) {
            total += 100;
          } else if (gid.length === 9) {
            total += 200;
          } else if (gid.length === 8) {
            total += 5000;
          } else if (gid.length === 7) {
            total += 10000;
          } else if (gid.length === 6) {
            total += 250000;
          } else if (gid.length === 5) {
            total += 500000;
          }
        }
      }
      return total;
    }
  
    _to_children(key: string) {
      return this.CODE_ALPHABET_[this.d[key.length]].map((i) => key + i);
    }
  }
  
  export default PetainGrid;