import React from 'react';
import { Card,Typography } from 'antd';
import { XMapPoint } from '../../../map/content/mapPoint';
const { Title } = Typography;
export const MapComponentPoint : React.FC = () => {
  return (
    <Card style={{ height: '450px' }}>
         <Title level={3}>Map</Title>
      <div style={{ height: '100%' }}>
        <XMapPoint />
      </div>
    </Card>
  );
};


