import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import logogeosquare from "../asesst/Icons/product/Geosquare-logo.png";
// import SectionOrder from '../layout/pageLayout/component/componentHome/section.dashboard.general';
import { EndpointDocumentationPage, ApiDocsOverviewPage, EndpointDocumentationPagePolygon, EndpointDocumentationPageAddres } from './api'
import { IoDocumentAttachOutline } from "react-icons/io5";
import { CiBoxList } from "react-icons/ci";
import { MdShoppingCartCheckout } from "react-icons/md";
import { EndpointDocumentationPageOrderCatalog } from './api/order.catalog';
import { EndpointDocumentationPageCreateOrder } from './api/order.createOrder';
import { EndpointDocumentationPageGetlistOrder } from './api/order.getlistOrder';
import { EndpointDocumentationPageGetlistOrderById } from './api/order.getlistById';
import { EndpointDocumentationPageDownloadData } from './api/order.downloadData';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;
const ApiDashboardPage = () => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState('profile'); // Set default selected key to 'accessibility'
  const handleClickBack = () => {
    navigate('/');
  };

  const handleMenuClick = (e: any) => {
    setSelectedMenuItem(e.key);
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'point':
        return (
          <EndpointDocumentationPage />
        )
      case 'catalogData':
        return <div style={{ padding: 24 }}>
        <EndpointDocumentationPageOrderCatalog/>
        </div>;
      case 'createOrder':
        return <div style={{ padding: 24 }}>
        <EndpointDocumentationPageCreateOrder/>
        </div>;
      case 'getlistOrder':
        return <div style={{ padding: 24 }}>
      <EndpointDocumentationPageGetlistOrder/>
        </div>;
      case 'getlistOrderById':
        return <div style={{ padding: 24 }}>
      <EndpointDocumentationPageGetlistOrderById/>
        </div>;
      case 'downloadData':
        return <div style={{ padding: 24 }}>
      <EndpointDocumentationPageDownloadData/>
        </div>;
   
      case 'overview':
        return (
          <div style={{ padding: 24 }}>
            <ApiDocsOverviewPage />
          </div>
        )
      case 'poly':
        return (
          <EndpointDocumentationPagePolygon />
        )
      case 'address':
        return (
          <EndpointDocumentationPageAddres />
        )
      default:
        return (
          <div style={{ padding: 24 }}>
            <ApiDocsOverviewPage />
          </div>
        )
    }
  };

  return (
    <Layout style={{ width: '100%', height: '100vh' }}>
      <Sider
        trigger={null} 
        collapsible width={250} 
          style={{
            background: "#363A45",
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0
          }}
         >
        <div onClick={handleClickBack} style={{ color: 'rgba(255, 255, 255, 0.65)', paddingLeft: '24px', paddingTop: '20px', cursor: 'pointer' }}>
          <img
            src={logogeosquare}
            alt="GeoSquare.ai"
            style={{ cursor: "pointer", width: "124px", height: "auto" }}
          />
        </div>
        <Menu 
          defaultSelectedKeys={['general']} 
          mode="inline" 
          selectedKeys={[selectedMenuItem]} // Custom selectedKeys
          onClick={handleMenuClick}
          style={{ background: "#363A45", color: 'white' }}
        >
          <Menu.Item
           key="overview"
           icon={<IoDocumentAttachOutline size={20} />} 
           className={selectedMenuItem === 'overview' ? 'selected-menu-item' : 'custom-menu-item'}
           >
            Overview
          </Menu.Item>
          <SubMenu 
            key="catalogApi" 
            title="Catalog Order" 
            icon={<MdShoppingCartCheckout size={20} />} >
            <Menu.Item key="catalogData"
            className={selectedMenuItem === 'catalogData' ? 'selected-menu-item' : 'custom-menu-item'}
            >
              Catalog Data
            </Menu.Item>
            <Menu.Item
             key="createOrder"
             className={selectedMenuItem === 'createOrder' ? 'selected-menu-item' : 'custom-menu-item'}
             >
              Create Order
            </Menu.Item>
            <Menu.Item 
            key="getlistOrder"
            className={selectedMenuItem === 'getlistOrder' ? 'selected-menu-item' : 'custom-menu-item'}
            >
              Get List Order
            </Menu.Item>
            <Menu.Item 
            className={selectedMenuItem === 'getlistOrderById' ? 'selected-menu-item' : 'custom-menu-item'}
            >
              Get List Order by Id
            </Menu.Item>
            <Menu.Item 
            key="downloadData"
            className={selectedMenuItem === 'downloadData' ? 'selected-menu-item' : 'custom-menu-item'}
            >
              Download Data
            </Menu.Item>
          </SubMenu>
          <SubMenu key="retrieveApi" title="Retrieve Data" icon={<CiBoxList size={20} />}>
            <Menu.Item 
             className={selectedMenuItem === 'point' ? 'selected-menu-item' : 'custom-menu-item'}
             key="point"
            >
              Coordinates (Point)
            </Menu.Item>
            <Menu.Item 
             className={selectedMenuItem === 'poly' ? 'selected-menu-item' : 'custom-menu-item'}
             key="poly"
             >
              Polygon
            </Menu.Item>
            <Menu.Item 
            key="address"
            className={selectedMenuItem === 'address' ? 'selected-menu-item' : 'custom-menu-item'}
            >
              Address
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: 250, height: '100vh', width: '100%', overflow: 'auto' }}>
        <Content  >
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
};

export default ApiDashboardPage;
