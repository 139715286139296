import React, { useState } from 'react';
import {  Button } from 'antd';
import { FaBriefcase } from 'react-icons/fa'; // Example icon
interface MyDataProps {
    // onClick: () => void;
}
// return null;
export const MyData: React.FC<MyDataProps> = () => {
    const [activeTab, setActiveTab] = useState('Insight');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Insight':
                return (
                    <div>
                    <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                      <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>Business Expansion</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                  </div>
                );
            case 'Data':
                return (
                    <div>
                           <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                    </div>
                );
            case 'Survey':
                return (
                    <div>
                           <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                    </div>
                );
            case 'Other':
                return (
                    <div>
                          <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                           <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px' }}>
                    <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
                      <div>
                        <span style={{ fontWeight: 'bold', color: '#fff' }}>General</span>
                        <p style={{ margin: '5px 0 0', color: '#fff' }}>3 Business</p>
                      </div>
                    </div>
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <React.Fragment>
            {/* Back Arrow */}
            <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
            <h2 style={{ color: 'white' }}>My Data</h2>
            </div>

            {/* List Items */}
            <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
                {['Insight', 'Data', 'Survey', 'Other'].map((tab) => (
                    <Button
                        key={tab}
                        type="text"
                        onClick={() => setActiveTab(tab)}
                        style={{
                            borderRadius:'0%',
                            color: activeTab === tab ? '#4A7CF3' : 'white',
                            fontWeight: activeTab === tab ? 'bold' : 'normal',
                            padding: '0 10px',
                            borderBottom: activeTab === tab ? '2px solid #4A7CF3' : 'none',
                        }}
                    >
                        {tab}
                    </Button>
                ))}
            </div>
            <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                {renderTabContent()}
            </div>
            </React.Fragment>
    );
};
