/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import React, { useState } from 'react';
import {
  Button,
  Form,
  Card,
  Collapse,
  Badge,
  Empty,
} from 'antd';

import _ from 'lodash';
import useReduxPixel from '../../hoc/hooks/usePixel'
import {
  EyeOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { PiPolygon } from "react-icons/pi";
import { IoIosLock,IoIosUnlock } from "react-icons/io";

import bgInsight from "../../asesst/Icons/pixel-insight.png";
import mapid from "../../asesst/Icons/product/mapid.png"; 
import bgData from "../../asesst/Icons/pixel-data.png";
import bgSurveryLidar from "../../asesst/Icons/lidar.jpg";
import iconRetails from "../../asesst/Icons/product/Insigh-Minimarket Retail.png";
import iconGeneral from "../../asesst/Icons/product/General.png";
import iconFinaceBanking from "../../asesst/Icons/product/Finance and Banking.png";
import iconProperty from "../../asesst/Icons/product/Insigh-Property Selection.png";
import iconPeople from "../../asesst/Icons/product/Data & Image-People demography.png";
import iconAddress from "../../asesst/Icons/product/Address.png";
import iconAccesibility from "../../asesst/Icons/product/Data & Image-Accessibility rev.png";
import iconDemograph from "../../asesst/Icons/product/Data & Image-People demography.png";
import iconPlanning from "../../asesst/Icons/product/Data & Image-zone planning rev.png";
import iconSafety from "../../asesst/Icons/product/Safety.png";
import iconEconomics from "../../asesst/Icons/product/Economics.png";
import iconASM from "../../asesst/Icons/product/Service-Aerial survey.png";
import iconlts from "../../asesst/Icons/product/Service-Land survey.png";

const { Meta } = Card;
const { Panel } = Collapse;
import geojsonDataInsight from '../../utils/sampleGeojson/dataInsigh-retail-fnb.geojson';;
import { ModalBuy, ModalFreeData, ModalQuote } from '../modal';
import useReduxDrawer from '../../hoc/hooks/useDrawer';
import useReduxTransaction from '../../hoc/hooks/useTransaction';
import Loader from '../loader/loader';
import useReduxUser from '../../hoc/hooks/useUser';
import ModalLogin from '../modal/formModalLogin';
import ModalLoginCardMap from '../modal/formModalLoginCardMap';
import { CardListData } from '../card/cardListData';

interface SharedComponentTabsProps {
  alignValue: string;
  setAlignValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SharedComponentTabs: React.FC<SharedComponentTabsProps> = ({ alignValue, setAlignValue }) => {
  const {
    // fetchPixel,
    isloading,
    pixelDrawCategory,
    getStaticDataInsight,
    getParamsData
  } = useReduxPixel()

  const { 
    isOpenModalBuy,
    isOpenModalQuote,
    ModalQuoteOpen,
    ModalBuyOpen,
    ModalFreeOpen,
    isOpenModalGetFree
  } = useReduxDrawer();
  const { setDataForm } = useReduxTransaction();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user,isLoginModalVisible, modalLoginOpen, isLoginModalCardVisible } = useReduxUser();

  getParamsData(alignValue)
  const ContactDataButton = () => {
    window.location.href = "https://petain.vercel.app/pages/contact";
  }

  const showSampleDataInsight = (data: any) => {
    // Melakukan parsing file JSON yang diimpor menjadi objek JavaScript
    fetch(data)
      .then(response => response.json())
      .then(data => {

        getStaticDataInsight(data)
      })
      .catch(error => console.error('Error:', error));
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleLoginModalOpen = () => {
    modalLoginOpen(true);
  };

  const onClickOpenModalBuy = (item: any) => {
    if (user) {
      setDataForm(item)
      ModalBuyOpen(true);
    } else {
      handleLoginModalOpen();
      setDataForm(item)
    }

  };

  const onClickOpenModalGetFreeData = (item: any) => {
    if (user) {
      setDataForm(item)
      ModalFreeOpen(true);
    } else {
      handleLoginModalOpen();
      setDataForm(item)
    }

  };

  const onClickOpenModalQuote = (item: any) => {
    setDataForm(item)
    ModalQuoteOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let categoryData: { [category: string]: any } = {};
  if (pixelDrawCategory) {
    const categories = Object.keys(pixelDrawCategory?.data);


    categories.forEach(category => {
      // Pisahkan data berdasarkan jenis kategori tertentu
      if (isBusinessCategory(category)) {
        const categoryInfo = pixelDrawCategory?.data[category];


        // Periksa apakah categoryInfo adalah string dan nilainya "data not found in this area"
        if (typeof categoryInfo === 'string' && categoryInfo === "data not found in this area") {
          // Tidak perlu dilakukan apa-apa jika categoryInfo adalah string "data not found in this area"
          return null;
        }

        if (!categoryData[category]) {
          categoryData[category] = {};
        }

        Object.keys(categoryInfo).forEach(subcategory => {
          if (!categoryData[category][subcategory]) {
            categoryData[category][subcategory] = [];
          }

          categoryData[category][subcategory].push({
            category,
            subcategory,
            data: categoryInfo[subcategory]
          });
        });
      }
    });
  }

  function isCurency(price: any) {
    if (price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      '0'
    }
  }

  function isBusinessCategory(category: string): boolean {
    // console.log(category);
    
    return category === 'PREVIEW' ||
      // ======== INSIGHT ======== //
      category === 'finance_banking' ||
      category === 'food_beverage' ||
      category === 'general' ||
      category === 'property' ||
      category === 'retail' ||
      // ======== DATA ======== //
      category === 'accessibility' || //
      category === 'address' ||//
      category === 'demography' ||//
      category === 'economics' ||
      category === 'people' ||//
      category === 'planning' || //
      category === 'safety' ||
      category === 'amenity' ||
      // ======== SURVEY ======== //
      category === 'aerial_survey_mapping' ||//
      category === 'land_topography_survey'
  }

  const renderCardCustom = () => {
    return (
      <>
        {
          !categoryData?.retail ? (
            <>
              <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
              <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
            </>
          ) : (
            Object.entries(categoryData?.retail || {}).map(([category, subcategories]) => (
              <div key={category}>
                <Form.Item className='price-card' key={category}>

                  {
                    Array.isArray(subcategories) && subcategories.map(subcategory => (
                      Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                        <Badge.Ribbon text='Pre-Order' color='#f5b916'>
                          <Card
                            style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                            bordered={true}
                            hoverable

                          >
                            <div key={item.id}>
                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontSize: 16, fontWeight: 'bold' }}>Custom</span>
                                <span style={{ fontSize: 12 }}>We will generate a tailored insight based on your requirment</span>
                              </div>
                              <div style={{ display: 'flex', marginTop: '10px' }}>
                                <div style={{ marginRight: 16 }}>
                                  <img alt="example" src={bgInsight} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                </div>
                                <div>
                                  <Meta
                                    title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 12, fontWeight: 'bold' }}>Estimated Price : </span>
                                        <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}` || 'Free'}</span>

                                      </div>
                                      <div style={{ textAlign: 'left' }}>
                                      </div>
                                    </div>}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                  <ShoppingCartOutlined /> Request Insight
                                </Button>

                              </div>
                            </div>
                          </Card>
                        </Badge.Ribbon>
                      ))
                    ))
                  }
                </Form.Item>
              </div>
            ))
          )}
      </>
      // <div key='1'>
      //   <Form.Item className='price-card' key='1'>
      //   <Badge.Ribbon text='Pre-order' color='#f5b916'>
      //     <Card
      //       style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
      //       bordered={true}
      //       hoverable

      //     >
      //       <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
      //         <span style={{ fontSize: 16, fontWeight: 'bold' }}>Custom</span>
      //         <span style={{ fontSize: 12 }}>We will generate a tailored insight based on your requirment</span>
      //       </div>
      //       <div style={{ display: 'flex', marginTop: '10px' }}>
      //         <div style={{ marginRight: 16 }}>
      //           <img
      //             alt="example"
      //             src={bgInsight}
      //             style={{ width: 70, height: 60, objectFit: 'cover' }} />
      //         </div>
      //         <div>
      //           <Meta
      //             title={<div style={{ display: 'flex', flexDirection: 'column' }}>
      //               <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
      //                 <span style={{ fontSize: 12, fontWeight: 'bold' }}>Estimated Price : </span>
      //                 <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>Rp.766.300</span>
      //               </div>
      //               <div style={{ textAlign: 'left' }}>
      //                 <span style={{ marginLeft: '5px' }}>
      //                   <EyeOutlined onClick={() => showSampleDataInsight(geojsonDataInsight)} />
      //                 </span>
      //                 <span style={{ marginLeft: '5px' }}>
      //                   <InfoCircleOutlined onClick={() => handleDetailClickSampleInsgiht()} />
      //                 </span>
      //               </div>
      //             </div>} />
      //         </div>

      //       </div>
      //       <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      //         <Button  style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
      //           <ShoppingCartOutlined /> Request Insight
      //         </Button>
      //       </div>
      //       <div>
      //       </div>
      //     </Card>
      //     </Badge.Ribbon>
      //   </Form.Item>
      // </div>
    )
  }

  const PanelHeadersInsight = () => {
    if (isloading) {
      return (
        <>
       <Loader />;
        </>
      );
    }
     else if (pixelDrawCategory?.statusCode === 404) {
      // Tampilkan pesan jika data kosong

      return (
        <>
          <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
          <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '250px', height: '40px' }}>Get Quotation for New Data</Button>
        </>
      );
    } else if (pixelDrawCategory?.statusCode === 200) {
      return (
        <>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>

              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img alt="example" src={iconRetails} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  {/* <HiOutlineBuildingStorefront style={{ marginRight: '5px' }} size={22} /> */}
                  <h3>Business Expansion</h3>
                </div>
              }>

                {
                  !categoryData?.food_beverage ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.food_beverage || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>


                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgInsight} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                        {/* <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price) || 0}`}</span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <EyeOutlined onClick={() => showSampleDataInsight(geojsonDataInsight)} />
                                                </span>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <IoIosLock />
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div> */}
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        {/* {item.status === 'Ready' ? (
                                          <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Buy Insight
                                          </Button>
                                        ) :
                                          <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Pre-Order
                                          </Button>} */}

                                            {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Insight
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}

                                      </div>
                                    </div>
                                  </Card>

                                </Badge.Ribbon>
                              ))
                            ))
                          }
                        </Form.Item>
                      </div>
                    ))
                  )}


                {
                  !categoryData?.retail ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.retail || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgInsight} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        {/* <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <EyeOutlined onClick={() => showSampleDataInsight(geojsonDataInsight)} />
                                                </span>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <IoIosLock />
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div> */}
                                          <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        {/* {item.status === 'Ready' ? (
                                          <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Buy Insight
                                          </Button>
                                        ) :
                                          <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Pre-Order
                                          </Button>} */}

                                          {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined />  Buy Insight
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}

                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }
                        </Form.Item>
                      </div>
                    ))
                  )}
                {renderCardCustom()}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                 <img alt="example" src={iconGeneral} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3>General</h3>
                </div>
              }>
                {
                  !categoryData?.general ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '250px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.general || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgInsight} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                        {/* <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <EyeOutlined onClick={() => showSampleDataInsight(geojsonDataInsight)} />
                                                </span>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <IoIosLock />
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div> */}
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        {/* {item.status === 'Ready' ? (
                                          <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Buy Insight
                                          </Button>
                                        ) :
                                          <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Pre-Order
                                          </Button>} */}
                                           {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}

                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }
                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>

            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <img alt="example" src={iconFinaceBanking} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3>Finance Banking</h3>
                </div>
              }>

                {
                  !categoryData?.finance_banking ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.finance_banking || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgInsight} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                        {/* <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <EyeOutlined onClick={() => showSampleDataInsight(geojsonDataInsight)} />
                                                </span>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <IoIosLock />
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div> */}
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        {/* {item.status === 'Ready' ? (
                                          <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Buy Insight
                                          </Button>
                                        ) :
                                          <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Pre-Order
                                          </Button>} */}
                                           {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}

                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }


                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>

              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                 <img alt="example" src={iconProperty} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3>Property</h3>
                </div>
              }>
                {
                  !categoryData?.property ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.property || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgInsight} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                        {/* <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <EyeOutlined onClick={() => showSampleDataInsight(geojsonDataInsight)} />
                                                </span>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <IoIosLock />
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div> */}
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        {/* {item.status === 'Ready' ? (
                                          <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Buy Insight
                                          </Button>
                                        ) :
                                          <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Pre-Order
                                          </Button>} */}
                                           {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}

                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }
                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
        </>
      )
    } else if (!pixelDrawCategory){
      return (
        <Empty
          description={
            <span>
              <Button
                style={{
                  backgroundColor: 'white', // Ubah warna tombol menjadi putih
                  color: 'black', // Ubah warna teks menjadi hitam
                  height: "30px",
                  width: "32px",
                }}
                icon={
                  <span className='anticon'>
                    <PiPolygon />
                  </span>
                } >

              </Button>
              {' '}  Draw Polygon First to Get Data
            </span>
          }
          style={{ marginTop: '20px' }}
        />
      );
    }
  }

  const PanelHeadersSurvey = () => {
    if (isloading) {
      return (
        <>
        <Loader />;
        </>
      );
     
  } 
  else if (pixelDrawCategory?.statusCode === 404) {
      // Tampilkan pesan jika data kosong

      return (
        <>
          <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
          <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '250px', height: '40px' }}>Get Quotation for New Data</Button>
        </>
      );
    } else if (pixelDrawCategory?.statusCode === 200) {
      return (
        <>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                 <img alt="example" src={iconASM} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3> Aerial Survey & Mapping</h3>
                </div>
              }>
                {
                  !categoryData?.aerial_survey_mapping ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.aerial_survey_mapping || {}).map(([category, subcategories]) => (
                      Array.isArray(subcategories) && subcategories.map(subcategory => (
                        Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                          <div key={item.id}>
                            <Form.Item className='price-card' key={item.id}>
                              <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                <Card
                                  style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                  bordered={true}
                                  hoverable

                                >
                                  <div key={item.id}>
                                    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                      <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                      <span style={{ fontSize: 12 }}>{item.description}</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '10px' }}>
                                      <div style={{ marginRight: 16 }}>
                                        <img alt="example" src={bgSurveryLidar} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                      </div>
                                      <div>
                                        <Meta
                                          title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                              <span style={{ fontSize: 12, fontWeight: 'bold' }}>Estimated Price : </span>
                                              <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                            </div>
                                            <div style={{ textAlign: 'left' }}>
                                              <span style={{ marginLeft: '5px' }}>
                                                <IoIosLock size={20} />
                                              </span>
                                            </div>
                                          </div>}
                                        />
                                      </div>
                                    </div>
                                    <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                        <ShoppingCartOutlined /> Request Service
                                      </Button>
                                    </div>
                                  </div>
                                </Card>
                              </Badge.Ribbon>
                            </Form.Item>
                          </div>
                        ))

                      ))

                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>

              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <img alt="example" src={iconlts} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3> Land / Topography Survey</h3>
                </div>
              }>
                {
                  !categoryData?.land_topography_survey ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.land_topography_survey || {}).map(([category, subcategories]) => (
                      Array.isArray(subcategories) && subcategories.map(subcategory => (
                        Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                          <div key={item.id}>
                            <Form.Item className='price-card' key={item.id}>
                              <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                <Card
                                  style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                  bordered={true}
                                  hoverable

                                >
                                  <div key={item.id}>
                                    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                      <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                      <span style={{ fontSize: 12 }}>{item.description}</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '10px' }}>
                                      <div style={{ marginRight: 16 }}>
                                        <img alt="example" src={bgSurveryLidar} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                      </div>
                                      <div>
                                        <Meta
                                          title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                              <span style={{ fontSize: 12, fontWeight: 'bold' }}>Estimated Price : </span>
                                              <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                            </div>
                                            <div style={{ textAlign: 'left' }}>
                                              <span style={{ marginLeft: '5px' }}>
                                                <IoIosLock size={20} />
                                              </span>
                                            </div>
                                          </div>}
                                        />
                                      </div>
                                    </div>
                                    <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                        <ShoppingCartOutlined /> Request Service
                                      </Button>

                                    </div>
                                  </div>
                                </Card>
                              </Badge.Ribbon>
                            </Form.Item>
                          </div>
                        ))

                      ))

                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
        </>
      )
    } else {
      return (
        <Empty
          description={
            <span>
              <Button
                style={{
                  backgroundColor: 'white', // Ubah warna tombol menjadi putih
                  color: 'black', // Ubah warna teks menjadi hitam
                  height: "30px",
                  width: "32px",
                }}
                icon={
                  <span className='anticon'>
                    <PiPolygon />
                  </span>
                } >

              </Button>
              {' '}  Draw Polygon First to Get Data
            </span>
          }
          style={{ marginTop: '20px' }}
        />
      );
    }
  }

  const PanelHeadersOthers = () => {
    return (
    <>
      <div key='3'>
          <Form.Item className='price-card' key='1'>
            <Card
              style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              bordered={true}
              hoverable

            >
              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: 16, fontWeight: 'bold' }}>  <img
                    alt="example"
                    src={mapid}
                    style={{ width: 70, height: 60, objectFit: 'cover' }} /></span>
                <span style={{ fontSize: 12 }}>MAPID is a location intelligence platform that connects various location data from trusted sources. The term itself comes from a map where representation of areas are shown in physical features and id as country code of Indonesia.</span>
              </div>
       
              <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                  <ShoppingCartOutlined /> Request Service
                </Button>
              </div>
              <div>
              </div>
            </Card>
          </Form.Item>
        </div>
        </>
    )
  }

  const PanelHeadersData = () => {
    if (isloading) {
      return (
        <>
        <Loader />;
        </>
      );
    } 
    else if (pixelDrawCategory?.statusCode === 404) {
      // Tampilkan pesan jika data kosong
      return (
        <>
          <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
          <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '250px', height: '40px' }}>Get Quotation for New Data</Button>
        </>
      );
    } else if (pixelDrawCategory?.statusCode === 200) {
      return (
        <>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img alt="example" src={iconPeople} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3>People</h3>
                </div>
              }>

                {
                  !categoryData?.people ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.people || {}).map(([category, subcategories]) => (
                      Array.isArray(subcategories) && subcategories.map(subcategory => (
                        Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (

                          <div key={item.id}>
                            <Form.Item className='price-card' key={item.id}>
                              <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                <Card
                                  style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                  bordered={true}
                                  hoverable

                                >
                                  <div key={item.id}>
                                    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                      <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                      <span style={{ fontSize: 12 }}>{item.description}</span>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '10px' }}>
                                      <div style={{ marginRight: 16 }}>
                                        <img alt="example" src={bgData} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                      </div>
                                      {/* <div>
                                        <Meta
                                          title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                              <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                              <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                            </div>
                                            <div style={{ textAlign: 'left' }}>
                                              <span style={{ marginLeft: '5px' }}>
                                                <IoIosLock size={20} />
                                              </span>
                                            </div>
                                          </div>}
                                        />
                                      </div> */}
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}
                                    </div>
                                  </div>
                                </Card>
                              </Badge.Ribbon>
                            </Form.Item>
                          </div>
                        ))

                      ))

                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                   <img alt="example" src={iconAddress} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3>Address</h3>
                </div>
              }>
                {
                  !categoryData?.address ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.address || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgData} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        {/* <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <IoIosLock size={20} />
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div> */}
                                          <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}
                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }


                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>
            </Collapse>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                 <img alt="example" src={iconAccesibility} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3>Accessibility</h3>
                </div>
              }>

                {
                  !categoryData?.accessibility ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.accessibility || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgData} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                        {/* <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <IoIosLock size={20} />
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div> */}
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}
                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }


                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                 <img alt="example" src={iconDemograph} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3>Demography</h3>
                </div>
              }>

                {
                  !categoryData?.demography ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.demography || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgData} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        {/* {item.status === 'Ready' ? (
                                          <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Buy Data
                                          </Button>
                                        ) :
                                          <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                            <ShoppingCartOutlined /> Pre-Order
                                          </Button>
                                        } */}
                                        {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}

                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }


                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                   <img alt="example" src={iconPlanning} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3>Planning</h3>
                </div>
              }>
                {
                  !categoryData?.planning ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.planning || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>


                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgData} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}
                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }


                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                   <img alt="example" src={iconSafety} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3> Safety</h3>
                </div>
              }>
                {
                  !categoryData?.safety ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.safety || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgData} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                        {/* <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <IoIosLock size={20} />
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div> */}
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}
                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }


                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                 <img alt="example" src={iconEconomics} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3> Economics</h3>
                </div>
              }>
                {/* <Panel style={{ textAlign: 'left' }} key='7' header={<h3> <DatabaseOutlined /> Others (Land Value)</h3>}> */}
                {
                  !categoryData?.economics ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.economics || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgData} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}
                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }


                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Collapse>
              <Panel style={{ textAlign: 'left' }} key='1' header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                   <img alt="example" src={iconAddress} style={{ width: 30, height: 30, objectFit: 'cover',  marginRight: '5px'}} />
                  <h3>Amenity</h3>
                </div>
              }>
                {
                  !categoryData?.amenity ? (
                    <>
                      <Empty description=" Data Not Found On this Area" style={{ marginTop: '20px', marginBottom: '50px' }} />
                      <Button style={{ backgroundColor: '#1785e7', color: 'white', width: '300px', height: '40px' }}>Get Quotation for New Data</Button>
                    </>
                  ) : (
                    Object.entries(categoryData?.amenity || {}).map(([category, subcategories]) => (
                      <div key={category}>
                        <Form.Item className='price-card' key={category}>

                          {
                            Array.isArray(subcategories) && subcategories.map(subcategory => (
                              Array.isArray(subcategory.data) && subcategory.data.map((item: { id: React.Key | null | undefined; alias: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; price: { total_price: any; }; status: string; }) => (
                                <Badge.Ribbon text={item.status} color={item.status === 'Ready' ? '#56bf52' : '#f5b916'}>
                                  <Card
                                    style={{ width: 300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    bordered={true}
                                    hoverable

                                  >
                                    <div key={item.id}>
                                      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.alias}</span>
                                        <span style={{ fontSize: 12 }}>{item.description}</span>
                                      </div>
                                      <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <div style={{ marginRight: 16 }}>
                                          <img alt="example" src={bgData} style={{ width: 70, height: 60, objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>
                                              {item.price.total_price ? `Rp.${isCurency(item.price.total_price)}` : 'Free'}
                                            </span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                {item.price.total_price ? <IoIosLock size={20} /> : <IoIosUnlock size={20} /> }
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div>
                                        {/* <div>
                                          <Meta
                                            title={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Total Price : </span>
                                                <span style={{ color: '#3489eb', fontSize: 20, fontWeight: 'bold' }}>{`Rp.${isCurency(item.price.total_price)}`}</span>
                                              </div>
                                              <div style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: '5px' }}>
                                                  <IoIosLock size={20} />
                                                </span>
                                              </div>
                                            </div>}
                                          />
                                        </div> */}
                                      </div>
                                      <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      {item.status === 'Ready' ? (
                                              item.price && item.price.total_price !== null && item.price.total_price !== 0 && item.price.total_price !== undefined ? (
                                                <Button onClick={() => onClickOpenModalBuy(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Buy Data
                                                </Button>
                                              ) : (
                                                <Button onClick={() => onClickOpenModalGetFreeData(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                  <ShoppingCartOutlined /> Get Data
                                                </Button>
                                              )
                                            ) : (
                                              <Button onClick={() => onClickOpenModalQuote(item)} style={{ fontSize: 20, color: 'white', backgroundColor: '#3489eb', width: '250px', height: '40px' }}>
                                                <ShoppingCartOutlined /> Pre-Order
                                              </Button>
                                           )}

                                      </div>
                                    </div>
                                  </Card>
                                </Badge.Ribbon>
                              ))
                            ))
                          }


                        </Form.Item>
                      </div>
                    ))
                  )}
              </Panel>
            </Collapse>
          </div>
        </>
      )
    } else {
      return (
        <Empty
          description={
            <span>
              <Button
                style={{
                  backgroundColor: 'white', // Ubah warna tombol menjadi putih
                  color: 'black', // Ubah warna teks menjadi hitam
                  height: "30px",
                  width: "32px",
                }}
                icon={
                  <span className='anticon'>
                    <PiPolygon />
                  </span>
                } >

              </Button>
              {' '}  Draw Polygon First to Get Data
            </span>
          }
          style={{ marginTop: '20px' }}
        />
      );
    }
  }


  return (
    <div style={{ textAlign: 'center' }}>

      {alignValue === 'DATA' ? (
        <>
          {PanelHeadersData()}
        </>
      ) : alignValue === 'SURVEY' ? (
        <>
          {PanelHeadersSurvey()}
        </>
      ) : alignValue === 'INSIGHT' ? (
        <>
          {PanelHeadersInsight()}
        </>
      ) : alignValue === 'OTHER' ? (
        <>
        {PanelHeadersOthers()}
        </>
      )
      : alignValue === 'OWN' ? (
        <>
       <CardListData/>
        {/* {PanelHeadersOthers()} */}
        </>
      )
        : null}
      <ModalBuy modalOpen={isOpenModalBuy} />
      <ModalFreeData modalOpen={isOpenModalGetFree} />
      <ModalQuote modalOpen={isOpenModalQuote} />
      <ModalLogin 
	     visible={isLoginModalVisible}
				/>
        <ModalLoginCardMap
	  		   visible={isLoginModalCardVisible}
			/>
    </div>
  );
};
