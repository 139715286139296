// Fungsi untuk mendapatkan warna berdasarkan kode status HTTP
export const getStatusColor = (statusCode:any) => {
    switch (statusCode) {
      case 200:
        return '#81C784'; // Hijau untuk sukses
      case 201:
        return '#81C784'; // Hijau untuk dibuat
      case 400:
        return '#FFD54F'; // Kuning/oranye untuk permintaan buruk
      case 401:
        return '#FFD54F'; // Kuning/oranye untuk tidak otorisasi
      case 403:
        return '#FFD54F'; // Kuning/oranye untuk dilarang
      case 404:
        return '#FFD54F'; // Kuning/oranye untuk tidak ditemukan
      case 500:
        return '#E57373'; // Merah untuk kesalahan server
      case 502:
        return '#E57373'; // Merah untuk Bad Gateway
      case 503:
        return '#E57373'; // Merah untuk Service Unavailable
      default:
        return '#d9d9d9'; // Abu-abu untuk status lainnya
    }
  };
  

  