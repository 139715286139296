import React, { useState } from 'react';
import { Button } from 'antd';
import { FaCoffee } from 'react-icons/fa';
import Text from '../../../style/styledComponent/Text';

interface CatalogCardProps {
    title: string;
    description: string;
    price: string;
}

interface ChartProps {
    data: CatalogCardProps[]; // Array of cards to be displayed
}

export const DrawerMyChart: React.FC<ChartProps> = ({ data }) => {
    // State to track selected items
    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    // Calculate the total price from the selected items
    const totalItems = selectedItems.length;
    const totalPrice = selectedItems
        .reduce((sum, index) => sum + parseInt(data[index].price.replace(/[^0-9]/g, '')), 0)
        .toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });

    // Handle checkbox change
    const handleCheckboxChange = (index: number) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(index)
                ? prevSelectedItems.filter((item) => item !== index)
                : [...prevSelectedItems, index]
        );
    };

    return (
        <React.Fragment>
            {/* Back Arrow */}
            <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
                <h2 style={{ color: 'white' }}>Chart</h2>
            </div>

            <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                {data.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            height: '80px',
                            alignItems: 'center',
                            backgroundColor: '#142943',
                            padding: '10px',
                            borderRadius: '10px',
                            marginBottom: '10px',
                        }}
                    >
                        {/* Checkbox */}
                        <input
                            type="checkbox"
                            checked={selectedItems.includes(index)}
                            onChange={() => handleCheckboxChange(index)}
                            style={{ marginRight: '10px' }}
                        />

                        {/* Icon */}
                        <FaCoffee size={30} style={{ marginRight: '10px' }} />

                        {/* Content */}
                        <div style={{ flex: 1 }}>
                            <div style={{ fontWeight: 'bold' }}>{item.title}</div>
                            <div style={{ fontWeight: 'bold', color: 'white' }}>{item.price}</div>
                        </div>

                        {/* Badge */}
                        <div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                padding: '5px 10px',
                                width: '40px',
                                height: '20px',
                                color: '#0A74DA',
                                fontWeight: 'bold',
                                marginLeft: '10px',
                            }}
                        >
                            <Text style={{ color: '#316DBA', margin: 0, fontSize: '10px' }}>5x5</Text>
                        </div>
                    </div>
                ))}
            </div>

            {/* New Section */}
            <div
                style={{
                    marginTop: '20px',
                    padding: '20px',
                    // backgroundColor: '#243A5E',
                    borderRadius: '10px',
                }}
            >
                <div style={{ marginBottom: '5px', color: 'white' }}>Total</div>
                <div style={{ marginBottom: '5px', color: 'white' }}>
                    {totalItems} Items for
                </div>
                <div style={{ marginBottom: '10px', color: 'white' }}>
                     {totalPrice}
                </div>
                <Button
                    type="primary"
                    style={{
                        width: '100%',
                        backgroundColor: '#0A74DA',
                        borderColor: '#517EA6',
                    }}
                >
                    Checkout
                </Button>
            </div>
        </React.Fragment>
    );
};
