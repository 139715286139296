import React from 'react';
import { MapProvider } from 'react-map-gl/maplibre';
import { XMapLayoutComponentLayerLoader } from '../component/componentLayerLoader';
import { MapPoint } from '../component/componentMap/mapPoint';
// import { DrawTools } from '../shared/drawtools';

export const XMapPoint: React.FC<{ children?: React.ReactNode }> = (props) => {
    return (
        <MapProvider>
            <MapPoint>
                <XMapLayoutComponentLayerLoader>
                    {props.children}
                </XMapLayoutComponentLayerLoader>
            </MapPoint>
        </MapProvider>
    )
}