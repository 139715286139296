// Loader.js
import React from 'react';
import { FaRegSquare } from 'react-icons/fa'; // Using FontAwesome globe icon

const Loader = () => {
  return (
    <div className="loader-container">
      <FaRegSquare className="globe-icon" />
      <p>loading...</p>
    </div>
  );
};

export default Loader;
