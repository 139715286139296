// drawerSlice.ts

import { createSlice } from '@reduxjs/toolkit';

interface TourState {
  stepIndex: number;
  tourVisible: boolean;

}

const initialState: TourState = {
  stepIndex: 0,
  tourVisible: false,
};

const TourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    startTour(state) {
      state.tourVisible = true;
      state.stepIndex = 0;
    },
    nextStep(state) {
      state.stepIndex += 1;
    },
    prevStep(state) {
      if (state.stepIndex > 0) {
        state.stepIndex -= 1;
      }
    },
    endTour(state) {
      state.tourVisible = false;
      state.stepIndex = 0;
    },
  },
});

export const {
  startTour,
  nextStep,
  prevStep,
  endTour
} = TourSlice.actions;

export default TourSlice.reducer;
