// drawerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PromptState {
    prompt: any | null;
    isloading: boolean; // Add loading property
    fetching: boolean;
    error: boolean;
}

const initialState: PromptState = {
    prompt: null,
    fetching: false,
    error: false,
    isloading: false, // Initialize loading state
};

const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.isloading = true; // Set loading to true when fetching starts
      state.error = false;
    },
    fetchDataFailure: (state) => {
      state.isloading = false; 
      state.error = true;
    },
    fetchDataSucces: (state, action: PayloadAction<any>) => {
        state.isloading = false; 
        state.prompt = action.payload;
      },
  },
});

export const { 
     fetchDataSucces,
    fetchDataStart,
    fetchDataFailure
} = promptSlice.actions;
export default promptSlice.reducer;
