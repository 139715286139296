import React from 'react';
import { Space } from 'antd';

interface LegendsProps {
  legendData: any;
 
}
export const LegendToolsDashboard:  React.FC<LegendsProps> = ({legendData }) => {

  const containerStyle: React.CSSProperties = {
    position: 'absolute',
    right: '15px',
    bottom: '74px',
    zIndex: 100,
    transition: '0.25s ease-in-out',
    height: 'auto',
    backgroundColor: 'white',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the start
    padding: '10px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  };

  const flexContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px', // Add gap between elements
  };

  const titleStyle: React.CSSProperties = {
    fontSize: '16px',
    fontWeight: 'bold',
  };

  return (
    <React.Fragment>
     
      {legendData && legendData.length > 0 && 
      <Space direction='vertical' style={containerStyle}>
        <div style={titleStyle}>Legend</div>
        {legendData?.map((item:any, index:any) => (
          <div key={index} style={flexContainerStyle}>
            <div style={{
                backgroundColor: item.color,
                opacity:'0.4',
                color: 'black',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
            </div>
            <span>{item.value}</span>
          </div>
        ))}
        </Space>
      }
    </React.Fragment>
  );
};
