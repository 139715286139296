import React from "react";
import { Layout } from 'antd';
import Container from "../../../../style/styledComponent/Container";
import Flex from "../../../../style/styledComponent/Container";
import Text from "../../../../style/styledComponent/Text";
import iconGeo from '../../../../asesst/Icons/product/Geosquare-logo.png'
import { FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";


const { Footer } = Layout;

const Footers = () => {
  const currentYear = new Date().getFullYear();
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/6282246245824', '_blank');
  };
  const handleWhatsLinkInd = () => {
    window.open('https://www.linkedin.com/company/geosquareai/', '_blank');
  };
  const handleWhatsInsta = () => {
    window.open('https://www.instagram.com/geosquare.ai/', '_blank');
  };
  return (
    <>
      <Container
        css={{
          width: "100%",
          // height: "200vh",
          margin: "1rem",
          padding: "1rem",
          display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: "1rem",
          // flex: "1",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          cursor: "pointer ",
        }}
      >
        <Flex justify={"start"} >
          <Container
            css={{
              flexDirection: "column",
              alignItems: "left",
            }}
          >

            <img
              src={iconGeo} // Ganti dengan URL gambar GIF Anda
              alt="iconGeo"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                height: "100px",
                width: "100px",
              }}
            />

            <Text
              css={{
                subhead1: "400",
                height: "100px",
                fontSize: "1rem",
                color: "black",
                textAlign: "left",
                lineHeight: "1.5rem",
                width: "300px"
              }}>
              PT Geo Inovasi Nusantara. <br></br>Signature by Regus, South Quarter Tower C Lantai 10 Jalan RA Kartini Kav 8, Cilandak Jakarta, 12430

            </Text>
            <Flex css={{
              marginTop: "10px",
              gap:"1rem"
            }}>
              <FaWhatsapp size={30} onClick={handleWhatsAppClick} />
              <FaInstagram size={30}onClick={handleWhatsInsta} />
              <FaLinkedinIn size={30} onClick={handleWhatsLinkInd} />
            </Flex>
          </Container>
        </Flex>
        <Flex
          className="flex-footer"
          justify={"center"}
        >

          <Container
            css={{
              flexDirection: "column",
              alignItems: "left",
              height: "100px",
            }}
          >
            <Text
              css={{
                display: "block",
                subhead4: "400",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "black",
                textAlign: "left",
              }}>
              Solutions
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                marginTop: "10px",
                fontSize: "1rem",
                color: "gray",
                textAlign: "left",
              }}>
              GeoSquare Insights
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                fontSize: "1rem",
                color: "gray",
                textAlign: "left",
              }}>
              Analysis-Ready Dataset
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                fontSize: "1rem",
                color: "gray",
                textAlign: "left",
              }}>
              On-Demand Services
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                // fontSize: "1rem",
                color: "gray",
                textAlign: "left",
              }}>
              Curated Platforms
            </Text>

          </Container>


        </Flex>
        <Flex
         className="flex-footer"
          justify={"center"}

        >

          <Container
            css={{
              height: "100px",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Text
              css={{
                display: "block",
                subhead4: "400",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "black",
                textAlign: "left",
              }}>
              Product
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                marginTop: "10px",
                fontSize: "1rem",
                color: "gray",
                textAlign: "left",
              }}>
              Interactive Map
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                fontSize: "1rem",
                color: "gray",
                textAlign: "left",
              }}>
              One Call API
            </Text>
          </Container>


        </Flex>
        <Flex
          justify={"center"}
          className="flex-footer"
        >

          <Container 
            css={{
              height: "100px",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Text
              css={{
                display: "block",
                subhead4: "400",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "black",
                textAlign: "left",
              }}>
              Company
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                marginTop: "10px",
                fontSize: "1rem",
                color: "gray",
                textAlign: "left",
              }}>
              About Us
            </Text>
          </Container>
        </Flex>
      </Container>
      <Footer style={{ textAlign: 'center', backgroundColor: "white" }}>
        <Text
          css={{
            color: "black",
            subhead1: "400",
            "@bp2": {
              subhead1: "400",
            },
            "@bp4": {
              subhead1: "400",
            },
            "@bp5": {
              subhead1: "400",
              width:'260px'
            },
          }}
        >
          All rights reserved. Copyright © {currentYear} PT Geo Inovasi Nusantara.
        </Text>
      </Footer></>
  )
}

export default Footers;
