import React from "react";
import { FaCommentAlt } from "react-icons/fa";

const ChatLogo = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#007bff",
        color: "#fff",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <FaCommentAlt style={{ fontSize: "24px" }} />
    </div>
  );
};

export default ChatLogo;
