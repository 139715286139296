import React, { useEffect, useState } from 'react';
import {
	Drawer,
	DrawerProps,
	Col,
	Form,
	Row,
	Segmented,
	Tooltip,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../hoc/store/config.strore';
import {
	SharedComponentDrawerTypeOneMaskStyle,
} from './SharedComponentDrawerTypeOne.style';
import { SharedComponentTabs } from '../tabs';
import { BaseMapsTools, DrawTools, LogoTools } from '../drawtools';
import { LegendTools } from '../legend';
import useReduxDrawer from '../../hoc/hooks/useDrawer';
import { AiTools } from '../drawtools/AiTools';
import ModalTour from '../modal/modalTour';
import useReduxTour from '../../hoc/hooks/useTour';
import TourGuide from '../tour/tour';
// import { CardMap } from '../card';
import useReduxUser from '../../hoc/hooks/useUser';
import ModalLoginCardMap from '../modal/formModalLoginCardMap';
import { GoDatabase } from "react-icons/go";
import { FcGlobe } from 'react-icons/fc';

// import { DataTools } from '../drawtools/dataTools';
// import ModalLogin from '../modal/formModalLogin';
type Align = 'INSIGHT' | 'DATA' | 'SURVEY' | 'OTHER' | 'OWN';

export interface ISharedComponentDrawerTypeOne extends DrawerProps {
	showToggleButton?: boolean;
	iconOpenToggle?: React.ReactNode;
	iconCloseToggle?: React.ReactNode;
	onIconClick?: () => void;
	children?: React.ReactNode;
}

export const SharedComponentDrawerTypeOne: React.FC<ISharedComponentDrawerTypeOne> = (props) => {
	const { className, showToggleButton, iconOpenToggle, iconCloseToggle, onIconClick, children, ...restProps } = props;
	let areaSquareKm = useSelector((state: RootState) => state.app.gjsProperties);
	const { tourStart } = useReduxTour();
	const [alignValue, setAlignValue] = useState<string>("INSIGHT"); // State untuk menyimpan nilai segmen yang dipilih
	const navigate = useNavigate();
	const { drawerOpen, drawerClose, isOpen } = useReduxDrawer();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [, setIsData] = useState(false);
	const [, setArea] = useState(String);
	// const { cardMapClose, cardMapOpen, isOpenCardMap } = useReduxDrawer();
	const { user, modalLoginCardOpen, isLoginModalCardVisible } = useReduxUser();

	useEffect(() => {
		SharedComponentDrawerTypeOneMaskStyle();
	}, []);
	const numberWithDots = (number: number) => {
		const integerPart = Math.floor(number);
		const formattedInteger = integerPart.toLocaleString('en-US');
		return formattedInteger.replace(/,/g, '.');
	};

	useEffect(() => {
		if (areaSquareKm) {
			const formattedNumber = numberWithDots(areaSquareKm);
			// console.log(formattedNumber);
			setIsData(true)
			setArea(formattedNumber)
		}
	}, [areaSquareKm]);

	const titleStyle = {
		display: 'flex',
		alignItems: 'center',
	};

	const handleDrawerOpen = () => {
		drawerOpen(true);
	};

	const handleDrawerClose = () => {
		drawerClose(false);
	};
	const handleClick = () => {
		// Perform some logic, and then navigate
		navigate('/');
		window.location.reload();
	};

	const handleStartTour = () => {
		setIsModalVisible(false);
		tourStart();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	useEffect(() => {
		// Check if the tour has been completed before
		const tourCompleted = localStorage.getItem('tourCompleted');
		if (!tourCompleted) {
			setIsModalVisible(true);
		}
	}, []);

	const handleLoginModalOpen = () => {
		modalLoginCardOpen(true);
	  };

	// const handleDrawerCardOpen = () => {
	// 	if (user) {
	// 		cardMapOpen(true)
	// 	  } else {
	// 		handleLoginModalOpen();
	// 	  }
	  
	//   };
  
	//   const handleDrawerCardClose = () => {
	// 	  cardMapClose(false);
	//   };

	  const handleClickOwnData = () => {
		if (user) {
			setAlignValue('OWN')
		  } else {
			handleLoginModalOpen();
		  }
	};
	return (
		<>
			<Drawer
				{...restProps}
				placement={'left'}
				// className="custom-drawer"
				closable={false}
				headerStyle={{
					cursor: 'pointer',
					borderBottom: '4px solid #0088EA',
				}}
				mask={false}
				visible={isOpen}
				bodyStyle={{ paddingTop: '0px' }} // Tambahkan paddingTop di sini
				title={
					<div style={titleStyle}>
						<FcGlobe className="spin-icon" size={50} style={{
							marginRight: '10px'
						}} />
						{/* <img alt="example" src={iconRetails} style={{ width: 100, height: 100, objectFit: 'cover',  marginRight: '5px'}} /> */}
						<div>
							<h4>
								<span style={{ color: '#0088EA', fontWeight: 'bold'}}>One</span>
								<span style={{ fontWeight: 'bold' }}>-Stop</span>
								<span> solution for all your geospatial needs!</span>
							</h4>
						</div>

					</div>
				}
			>
			<div 
				style={{ 
					position: 'sticky',
					 top: 0, 
					 zIndex: 1,
					 backgroundColor: 'white', 
					 textAlign: 'center', 
					 paddingTop: '20px' }}>
			<div 
				style={{ 
					display: 'flex', 
					justifyContent: 'center', 
					alignItems: 'center', 
					paddingBottom:'20px' 
				}}>
				<Segmented
				defaultValue="INSIGHT"
				onChange={(value) => setAlignValue(value as Align)}
				options={['INSIGHT', 'DATA', 'SURVEY', 'OTHER']}
				/>
				<span 
				onClick={handleClickOwnData}
					className='anticon' 
					style={{
						 marginLeft: '15px', 
						 cursor: 'pointer'
						 }}>
				<Tooltip
					title={<p style={{color:'black'}}>Your Data</p>}
					placement="right"
					color='white'
					>
						<GoDatabase size={25} color={alignValue === 'OWN' ? '#0088EA' : 'black'} />
				</Tooltip>
				</span>
			</div>
			</div>

				<Row style={{ overflowY: 'auto' }}>
					<Col xs={24}>
						<Form layout='vertical'>
							<SharedComponentTabs alignValue={alignValue} setAlignValue={setAlignValue} />
						</Form>
					</Col>
				</Row>
			</Drawer>
			<DrawTools drawerOpen={isOpen} onOpenDrawer={handleDrawerOpen} onCloseDrawer={handleDrawerClose} />
			<LegendTools drawerOpen={isOpen} />
			<LogoTools onClick={handleClick} />
			<BaseMapsTools drawerOpen={isOpen} />
			<AiTools drawerOpen={isOpen} />
			{/* <DrawToolsDashboard drawerOpenCardMap={isOpenCardMap} drawerOpen={isOpen} onOpenDrawer={handleDrawerCardOpen} onCloseDrawer={handleDrawerCardClose} /> */}
			{/* <CardMap drawerOpen={isOpen} drawerOpenCardMap={isOpenCardMap}/> */}
			<ModalTour
				visible={isModalVisible}
				onConfirm={handleStartTour}
				onCancel={handleCancel}
			/>
			<TourGuide />
			<ModalLoginCardMap 
	  		   visible={isLoginModalCardVisible}
			/>
		</>
	);
};
