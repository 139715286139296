import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { EnvironmentOutlined, AimOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import { FaBriefcase } from 'react-icons/fa'; // Example icon
import { CardCatalog } from '../../../component/menu/cardlist.catalog';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import mapid from "../../../asesst/Icons/product/mapid.png";
import LoaderSideBar from '../../loader/loader.sidebar';

interface ResultCatalogProps {
  onBackClick: () => void;
}

export const ResultCatalog: React.FC<ResultCatalogProps> = ({ onBackClick }) => {
  const [activeTab, setActiveTab] = useState('Insight');
  const [showCardCatalog, setShowCardCatalog] = useState(false);
  const { paramsCatalogGeometry, setCatalog, catalog, isloading, location } = useReduxCatalog();
  const [param, setParam] = useState('insight');
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);

  const handleTabClick = (params: string) => {
    setActiveTab(params);
    const paramCatalog = params.toLowerCase();
    setParam(paramCatalog);
  };

  const handleCardClick = (category: any) => {
    setSelectedCategory(category);
    setShowCardCatalog(true);
  };

  useEffect(() => {
    setCatalog(param, paramsCatalogGeometry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, paramsCatalogGeometry, location]);

  const renderTabContent = () => {

    if (activeTab === 'Other') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', background: '#2C3F58', padding: '15px', borderRadius: '12px', marginBottom: '10px', gap: '10px' }}>
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>
            <img
              alt="example"
              src={mapid}
              style={{ width: 70, height: 70, objectFit: 'cover', borderRadius: '8px' }} />
          </span>
          <span style={{ fontSize: 12, lineHeight: '1.5' }}><b>MAPID</b> is a location intelligence platform that connects various location data from trusted sources. The term itself comes from a map where representation of areas are shown in physical features and id as country code of Indonesia.</span>
        </div>
      );
    }
    if (isloading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <LoaderSideBar />
        </div>
      );
    }
    const dataTabs = catalog?.data || {};
    return Object.keys(dataTabs).map((category) => {
      const subcategory = dataTabs[category];
      const objectCount = Object.values(subcategory).flat().length;
      const formattedCategory = category.replace(/_/g, ' ').toUpperCase();
      return (
        <div
           key={category} onClick={() => handleCardClick(subcategory)} 
          
           style={{
            display: 'flex', 
            alignItems: 'center', 
            background: '#2C3F58', 
            padding: '15px', 
            borderRadius: '12px', 
            marginBottom: '10px', 
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for smooth effect
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-5px)'; // Move up by 5px on hover
            e.currentTarget.style.boxShadow = '0px 8px 15px rgba(0, 0, 0, 0.2)'; // Add a shadow for a lifting effect
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)'; // Reset position when not hovering
            e.currentTarget.style.boxShadow = 'none'; // Remove shadow when not hovering
          }}
          >
          <FaBriefcase size={30} style={{ marginRight: '10px', color: '#fff' }} />
          <div>
            <span style={{ fontWeight: 'bold', color: '#fff' }}>{formattedCategory}</span>
            <p style={{ margin: '5px 0 0', color: '#fff' }}>{objectCount} Items</p>
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      {/* Back Arrow */}
      <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
        <span onClick={onBackClick} style={{ fontSize: '18px', fontWeight: 'bold' }}>← Result Location</span>
      </div>

      {/* Location Address */}
      <div
        style={{
          background: '#2C3F58',
          padding: '15px',
          borderRadius: '12px',
          marginBottom: '20px',
        }}
      >
        <EnvironmentOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        <span style={{ fontWeight: 'bold' }}>Location Address</span>
        <p style={{ margin: '5px 0 0' }}>
          {location?.address}
        </p>
      </div>

      {/* Position */}
      <div
        style={{
          background: '#2C3F58',
          padding: '15px',
          borderRadius: '12px',
          marginBottom: '20px',
        }}
      >
        <AimOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        <span style={{ fontWeight: 'bold' }}>Position</span>
        <p style={{ margin: '5px 0 0' }}>
          {location?.centroid[1]},<br /> {location?.centroid[0]}
        </p>
      </div>

      {/* Size of the area */}
      <div
        style={{
          background: '#2C3F58',
          padding: '15px',
          borderRadius: '12px',
          marginBottom: '20px',
        }}
      >
        <ArrowsAltOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        <span style={{ fontWeight: 'bold' }}>Size of the area</span>
        <p style={{ margin: '5px 0 0' }}>  {location?.area} km2</p>
      </div>

      {/* List Items */}
      <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
        {['Insight', 'Data', 'Survey', 'Other'].map((tab) => (
          <Button
            type="text"
            key={tab}
            id={tab}
            onClick={() => handleTabClick(tab)}
            style={{
              borderRadius: '0%',
              color: activeTab === tab ? '#4A7CF3' : 'white',
              fontWeight: activeTab === tab ? 'bold' : 'normal',
              padding: '0 10px',
              borderBottom: activeTab === tab ? '2px solid #4A7CF3' : 'none',
            }}
          >
            {tab}
          </Button>
        ))}
      </div>
      <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
        {renderTabContent()}
      </div>
      {showCardCatalog && <CardCatalog selectedCategory={selectedCategory} location={location} />}
    </React.Fragment>
  );
};
