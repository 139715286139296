import React from 'react';
import { Input } from 'antd';
import { PhoneOutlined  } from '@ant-design/icons';
interface PhoneNumberInputProps {
  value: string;
  onChange: (value: string) => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (rawValue.length <= 13) { // Maximum length for Indonesian phone numbers
      onChange(rawValue);
    }
  };

  return (
    <Input
    prefix={<PhoneOutlined />}
      value={value}
      onChange={handleChange}
      placeholder="Phone Number"
      maxLength={14} // For formatting purposes, e.g., "+62 812 3456 7890"
    />
  );
};

export default PhoneNumberInput;
