import React from 'react';
import { MapProvider } from 'react-map-gl/maplibre';
import { XMapLayoutComponentLayerLoader } from '../component/componentLayerLoader';
import { MapPolygon } from '../component/componentMap/mapPolygon';

export const XMapPolygon: React.FC<{ children?: React.ReactNode }> = (props) => {
    return (
        <MapProvider>
            <MapPolygon>
                <XMapLayoutComponentLayerLoader>
                    {props.children}
                </XMapLayoutComponentLayerLoader>
            </MapPolygon>
        </MapProvider>
    )
}