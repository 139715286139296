import React, { useEffect, useState } from 'react';
import Container from "../../../../../style/styledComponent/Container";
import { fadein } from "../../../../../style/keyframes";
import customSection from "../../../../../asesst/new/mobile/ourpartnersMobile.svg";
import { Typography } from 'antd';
import partnerIcon from '../../../../../asesst/new/icon/easyaccess.svg';
import { CSSProperties } from 'react';

const { Title, Text } = Typography;

const SectionOurPartnersMobile = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            textAlign:  'left',
        },
        icon: {
            marginRight: windowWidth <= 768 ? '' : '10px',
            marginBottom: windowWidth <= 768 ? '10px' : '0',
            color: '#316DBA',
        },
        integrationText: {
            color: '#316DBA',
            fontSize: '24px',
            fontWeight: '800',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: 'left',
        },
        title: {
            fontWeight: '800',  
            fontSize: windowWidth <= 768 ? '24px' : '38px',
            color: '#333333',
            marginTop: '10px',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign:  'left',
        },
        description: {
            fontSize: '16px',
            color: '#333333',
            display: 'block',
            width: windowWidth <= 768 ? '100%' : '500px',
            lineHeight: '1.5',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: 'left',
        },
        imageStyle: {
            filter: 'grayscale(1)',
            width: '100%',
            height: 'auto',
        },
    };

    return (
        <Container
            css={{
                opacity: "0",
                animation: `1s ease-in 1s 1 both ${fadein}`,
                width: "100%",
                // padding: " $2 $10",
                backgroundColor: '#F7F9FC',
                display: "grid",
                alignItems: "left",
                justifyContent: "space-between",
                flexWrap: "wrap",
                cursor: "pointer",
                gridTemplateColumns: "1.5fr 1.5fr",
                '@media (max-width: 768px)': {
                    gridTemplateColumns: "1fr",
                    padding: "20px",
                }
            }}
        >
            <Container>
                <Container
                    dir={"column"}
                    css={{
                        width: "100%",
                        height: "100%",
                        opacity: "0",
                        padding: '50px 0',
                        animation: `1s ease-in 1s 1 both ${fadein}`,
                        display: 'flex',
                        alignItems:  'flex-start',
                    }}
                >
                    <div style={styles.integrationHeader}>
                        <img alt="example" src={partnerIcon} style={styles.icon} />
                       
                    </div>
                    <Text style={styles.integrationText}>Our Partners</Text>
                    <Title style={styles.title}>Backed and trusted by many</Title>
                    <Text style={styles.description}>
                        We're proud to partner with leading universities, visionary venture capitalists, and innovative companies to accelerate progress. This collaboration fuels cutting-edge research, unlocks new possibilities, and brings groundbreaking solutions to market.
                    </Text>
                </Container>
            </Container>
            <Container css={{ padding: '50px 0' }}>
                <img
                    src={customSection}
                    alt="Animated GIF"
                    // className="responsive-image"
                    style={styles.imageStyle}
                />
            </Container>
        </Container>
    );
};

export default SectionOurPartnersMobile;
