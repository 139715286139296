import React, {  useState } from 'react';
// import { useMap } from 'react-map-gl/maplibre';

export const XMapLayoutComponentLayerLoader: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	// const { xmap } = useMap();
	const [internalState] = useState({ load: false });

	// const listenerLoad = () => {
	// 	setInternalState((c) => ({ ...c, load: true }));
	// };

	// useEffect(() => {
	// 	if (xmap) {
	// 		xmap.on('load', listenerLoad);
	// 	}
	// 	return () => {
	// 		if (xmap) {
	// 			xmap.off('load', listenerLoad);
	// 		}
	// 	};
	// }, []);

	return internalState.load ? <>{children}</> : null;
};
