import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Space } from 'antd';
import Container from "../../../../style/styledComponent/Container";
import Flex from "../../../../style/styledComponent/Container";
import Text from "../../../../style/styledComponent/Text";
import iconGeo from '../../../../asesst/Icons/product/Geosquare-logo.png';
import { FaWhatsapp, FaLinkedinIn, FaInstagram } from "react-icons/fa";

const { Footer } = Layout;

const SectionFooters = () => {
  const currentYear = new Date().getFullYear();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/6282246245824', '_blank');
  };

  const handleWhatsLinkInd = () => {
    window.open('https://www.linkedin.com/company/geosquareai/', '_blank');
  };

  const handleWhatsInsta = () => {
    window.open('https://www.instagram.com/geosquare.ai/', '_blank');
  };

  return (
    <>
      <Container
        css={{
          width: "100%",
          padding: windowWidth <= 768 ? '16px 40px' : '$2 $10',
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "1rem",
          backgroundColor: "#fff",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          cursor: "pointer",
        }}
      >
        <Flex justify={"start"}>
          <Flex
            css={{
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <img
              src={iconGeo}
              alt="iconGeo"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                height: "120px",
                width: "120px",
              }}
            />
            <Text
              css={{
                subhead1: "400",
                height: "100px",
                fontSize: "14px",
                color: "#333333",
                textAlign: "left",
                lineHeight: "20px",
                width: "300px",
                fontFamily: '"Jakarta Sans", sans-serif',
              }}
            >
              PT Geo Inovasi Nusantara. <br />
              Signature by Regus, South Quarter Tower C Lantai 10 Jalan RA Kartini Kav 8, Cilandak Jakarta, 12430
            </Text>
          </Flex>
        </Flex>
        <Flex className="flex-footer" justify={"center"}>
          <Container
            css={{
              flexDirection: "column",
              alignItems: "left",
              height: "100px",
              fontFamily: '"Jakarta Sans", sans-serif',
            }}
          >
            <Text
              css={{
                display: "block",
                subhead4: "400",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "#333333",
                textAlign: "left",
              }}
            >
              Solutions
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                marginTop: "10px",
                fontSize: "14px",
                color: "#333333",
                textAlign: "left",
              }}
            >
              GeoSquare Insights
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                fontSize: "14px",
                color: "#333333",
                textAlign: "left",
              }}
            >
              Analysis-Ready Dataset
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                fontSize: "14px",
                color: "#333333",
                textAlign: "left",
              }}
            >
              On-Demand Services
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                fontSize: "14px",
                color: "#333333",
                textAlign: "left",
              }}
            >
              Curated Platforms
            </Text>
          </Container>
        </Flex>
        <Flex className="flex-footer" justify={"center"}>
          <Container
            css={{
              height: "100px",
              flexDirection: "column",
              alignItems: "left",
              fontFamily: '"Jakarta Sans", sans-serif',
            }}
          >
            <Text
              css={{
                display: "block",
                subhead4: "400",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "#333333",
                textAlign: "left",
              }}
            >
              Product
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                marginTop: "10px",
                fontSize: "14px",
                color: "#333333",
                textAlign: "left",
              }}
            >
              Interactive Map
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                fontSize: "14px",
                color: "#333333",
                textAlign: "left",
              }}
            >
              One Call API
            </Text>
          </Container>
        </Flex>
        <Flex justify={"center"} className="flex-footer">
          <Container
            css={{
              height: "100px",
              flexDirection: "column",
              alignItems: "left",
              color: "#333333",
              fontFamily: '"Jakarta Sans", sans-serif',
            }}
          >
            <Text
              css={{
                display: "block",
                subhead4: "400",
                fontSize: "1rem",
                fontWeight: "bold",
                color: "#333333",
                textAlign: "left",
              }}
            >
              Company
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                marginTop: "10px",
                fontSize: "14px",
                color: "#333333",
                textAlign: "left",
              }}
            >
              About Us
            </Text>
            <Text
              css={{
                display: "block",
                subhead1: "400",
                fontSize: "14px",
                color: "#333333",
                textAlign: "left",
              }}
            >
              Contact Us
            </Text>
          </Container>
        </Flex>
      </Container>
      <Container
      css={{
        width: "100%",
        padding: windowWidth <= 768 ? '0' : '$1 $5',
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gap: "1rem",
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        cursor: "pointer",
      }}
      >
      <Footer style={{ backgroundColor: "white" }}>
        <Row justify="space-between" align="middle">
          <Col style={{ marginTop: '10px' }}>
            <Text
               css={{
                   display: "block",
                   textAlign: "left",
                   color: "#333333",
                   fontFamily: '"Jakarta Sans", sans-serif',
            }}>All rights reserved. <b>PT Geo Inovasi Nusantara</b> © {currentYear}</Text>  
          </Col>
          <Col style={{ marginTop: '10px', marginRight:'50px' }}>
            <Space size="middle">
              <button
                onClick={handleWhatsAppClick}
                style={{
                  background: 'none',
                  border: 'none',
                  backgroundColor: "#33333",
                  cursor: 'pointer',
                  padding: 0,
                  fontSize: '24px',
                }}
              >
                <FaWhatsapp size={24} />
              </button>
              <button
                onClick={handleWhatsInsta}
                style={{
                  background: 'none',
                  border: 'none',
                  backgroundColor: "#33333",
                  cursor: 'pointer',
                  padding: 0,
                  fontSize: '24px',
                }}
              >
                <FaInstagram size={24} />
              </button>
              <button
                onClick={handleWhatsLinkInd}
                style={{
                  background: 'none',
                  border: 'none',
                  backgroundColor: "#33333",
                  cursor: 'pointer',
                  padding: 0,
                  fontSize: '24px',
                }}
              >
                <FaLinkedinIn size={24} />
              </button>
            </Space>
          </Col>
        </Row>
      </Footer>
      </Container>
    </>
  );
}

export default SectionFooters;
