import React, { useState } from 'react';
import { Layout, Row, Card, Typography ,message, Modal, Col} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import postmanIcon from "../../asesst/Icons/product/postman.svg";
import { TableCategory } from '../../component/page/shared/table';
import Text from '../../style/styledComponent/Text';
import { Editor } from '@monaco-editor/react';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const EndpointDocumentationPageGetlistOrder: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
          message.success('URL copied to clipboard');
        });
      };
      const endpointUrl = 'https://api.geosquare.ai/api/data/order/getOrder?key=[API_KEY]';
    
      const handleDocsApi = () => {
        window.open('https://api-docs.geosquare.ai/#24b64279-10b2-4b29-9752-6ce8d46f7332', '_blank');
      };
    const  requestCode = `
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "features": [
        {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [
                  106.73663428958235,
                  -6.218307293805334
                ],
                [
                  106.73618725268459,
                  -6.22029653914168
                ],
                [
                  106.73829471234536,
                  -6.221037213673636
                ],
                [
                  106.73803926268954,
                  -6.220084917655153
                ],
                [
                  106.73855016200123,
                  -6.219132619911601
                ],
                [
                  106.73663428958235,
                  -6.218307293805334
                ]
              ]
            ]
          }
        }
      ],
      "page": 1,
      "perPage": 20
    });
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://api.geosquare.ai/api/data/order/getOrder?key=50e528f7b0fc2b787454fdf932bc0", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    `
    const responseCode = `
    {
        "statusCode": 200,
        "message": "",
        "url": "/api/data/order/getOrder",
        "pagination": {
          "perPage": 10,
          "totalPage": null,
          "totalRows": null,
          "previousPage": null,
          "nextPage": null,
          "page": 1
        },
        "data": [
          {
            "category": "Demography",
            "createdAt": "2024-07-30T10:19:38.543Z",
            "alias": "",
            "amount": 35280,
            "data_id": "demography_bachelor_grad",
            "item": "Demography Bachelor Graduate",
            "name": "admin IT",
            "order_id": "GS-API-EVra0qiFRn0e7Ack2AyJGsRJFw",
            "sizeGrid": 50,
            "status": "PAID",
            "status_data": "success",
            "plygon_id": "d621f3ce-3b64-4f93-b03d-d180c1698820",
            "id": "GS-API-EVra0qiFRn0e7Ack2AyJGsRJFw"
          },
          {
            "category": "Demography",
            "createdAt": "2024-07-30T09:59:50.290Z",
            "alias": "",
            "amount": 35280,
            "data_id": "demography_bachelor_grad",
            "item": "Demography Bachelor Graduate",
            "name": "admin IT",
            "order_id": "GS-API-0r5ssz3gdXAELWlmK4y1pqtWrfY",
            "sizeGrid": 50,
            "status": "PAID",
            "status_data": "success",
            "plygon_id": "d621f3ce-3b64-4f93-b03d-d180c1698820",
            "id": "GS-API-0r5ssz3gdXAELWlmK4y1pqtWrfY"
          },
          {
            "category": "Demography",
            "createdAt": "2024-07-30T05:27:59.736Z",
            "alias": "",
            "amount": 35280,
            "data_id": "demography_bachelor_grad",
            "item": "Demography Bachelor Graduate",
            "name": "admin IT",
            "order_id": "GS-API-McTYiZbVIgPkjrnbA3D6BfkU1k",
            "sizeGrid": 50,
            "status": "PAID",
            "status_data": "success",
            "plygon_id": "d621f3ce-3b64-4f93-b03d-d180c1698820",
            "id": "GS-API-McTYiZbVIgPkjrnbA3D6BfkU1k"
          },
          {
            "category": "Demography",
            "createdAt": "2024-07-30T05:25:34.920Z",
            "alias": "",
            "amount": 35280,
            "data_id": "demography_bachelor_grad",
            "item": "Demography Bachelor Graduate",
            "name": "admin IT",
            "order_id": "GS-API-B9kwo47LvkNMzWF1yLDHv5sXf4",
            "sizeGrid": 50,
            "status": "PAID",
            "status_data": "process",
            "plygon_id": "d621f3ce-3b64-4f93-b03d-d180c1698820",
            "id": "GS-API-B9kwo47LvkNMzWF1yLDHv5sXf4"
          },
          {
            "category": "Demography",
            "createdAt": "2024-07-30T05:23:35.577Z",
            "alias": "",
            "amount": 35280,
            "data_id": "demography_bachelor_grad",
            "item": "Demography Bachelor Graduate",
            "name": "admin IT",
            "order_id": "GS-API-fyxSzRGFAiCWLVmZi3yoerqGVcI",
            "sizeGrid": 50,
            "status": "PAID",
            "status_data": "process",
            "plygon_id": "d621f3ce-3b64-4f93-b03d-d180c1698820",
            "id": "GS-API-fyxSzRGFAiCWLVmZi3yoerqGVcI"
          },
          {
            "category": "Demography",
            "createdAt": "2024-07-30T01:48:10.738Z",
            "alias": "",
            "amount": 35280,
            "data_id": "demography_bachelor_grad",
            "item": "Demography Bachelor Graduate",
            "name": "admin IT",
            "order_id": "GS-API-EJFpdXgALVag7BySLMqNjakwKaE",
            "sizeGrid": 50,
            "status": "PAID",
            "status_data": "pending",
            "plygon_id": "d621f3ce-3b64-4f93-b03d-d180c1698820",
            "id": "GS-API-EJFpdXgALVag7BySLMqNjakwKaE"
          },
          {
            "category": "demography",
            "createdAt": "2024-07-22T05:40:43.806Z",
            "alias": "Demographic Population",
            "amount": 2660000,
            "data_id": "demography_population",
            "item": "Demographic Population",
            "name": "admin IT",
            "order_id": "GS-5d8614a67760",
            "sizeGrid": 5,
            "status": "EXPIRED",
            "status_data": "failed",
            "plygon_id": "b46b9d5f-d066-4e53-a00f-5061ef1e6f89",
            "id": "GS-5d8614a67760"
          },
          {
            "category": "demography",
            "createdAt": "2024-07-22T05:40:14.755Z",
            "alias": "Demographic Population",
            "amount": 2786000,
            "data_id": "demography_population",
            "item": "Demographic Population",
            "name": "admin IT",
            "order_id": "GS-f3f20f30418c",
            "sizeGrid": 5,
            "status": "EXPIRED",
            "status_data": "failed",
            "plygon_id": "0b86e4da-ae74-434e-9da7-df288fe3b902",
            "id": "GS-f3f20f30418c"
          },
          {
            "category": "accessibility",
            "createdAt": "2024-07-19T01:32:51.366Z",
            "alias": "Business Accessibility",
            "amount": 30250000,
            "data_id": "accessibility_business_accessibility_",
            "item": "Business Accessibility",
            "name": "admin IT",
            "order_id": "GS-b49c4cbac95b",
            "sizeGrid": 5,
            "status": "EXPIRED",
            "status_data": "failed",
            "plygon_id": "064a69c8-9fab-4483-bc8f-6830ed1204ae",
            "id": "GS-b49c4cbac95b"
          },
          {
            "category": "demography",
            "createdAt": "2024-07-19T01:32:39.874Z",
            "alias": "Demographic Population",
            "amount": 42350000,
            "data_id": "demography_population",
            "item": "Demographic Population",
            "name": "admin IT",
            "order_id": "GS-179b48e51b4f",
            "sizeGrid": 5,
            "status": "EXPIRED",
            "status_data": "failed",
            "plygon_id": "064a69c8-9fab-4483-bc8f-6830ed1204ae",
            "id": "GS-179b48e51b4f"
          }
        ]
      }
    `
    return (
        <Layout style={{ padding: '24px', minHeight: '100vh', background: '#fff' }}>
            <Content>
                <Title level={3}>Get List Data Documentation</Title>
                <Row gutter={16} style={{ marginBottom: '24px' }}>
                <Col span={14}>
                <Card style={{ marginTop: '24px' }}>
                    <Title level={3}>Endpoint Documentation</Title>
                    {/* <Paragraph> */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom:'10px' }}>
                        
                            <Text css={{fontWeight:700, color:'Green', marginRight:'10px'}}>GET</Text>
                    
                        {endpointUrl}
                            <CopyOutlined
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                                onClick={() => copyToClipboard(endpointUrl)}
                            />
                        </div>
                    {/* </Paragraph> */}
                    
                    <Paragraph>
                        <strong>Parameters:</strong>
                        <ul>
                            <li><strong>key:</strong> This query parameter is an API key or access token required for authorization.</li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        <strong>Headers:</strong>
                        <ul>
                            <li><strong>Content-Type:</strong> application/json</li>
                        </ul>
                    <Title level={2}>Full Documentation </Title>
                    <Row gutter={16} style={{ marginBottom: '24px' }}>
                        <Card style={{ textAlign: 'center', borderColor: '#ff6c37', cursor:'pointer' }} onClick={handleDocsApi}>
                            <img
                                src={postmanIcon}
                                alt="Postman"
                                style={{ width: '50px', height: '50px', marginBottom: '16px' }}
                            />
                            <p style={{ color: '#ff6c37', fontWeight: 'bold' }}>
                                Go to full documentation
                            </p>
                        </Card>
                    </Row>
                    </Paragraph>
                   
                </Card>
                </Col>
                <Col span={10}>
                <Title level={4}>Example Request</Title>
                <Editor
                            height="480px"
                            language="javascript"
                            theme="vs-dark"
                            value={requestCode}
                            // onChange={handleEditorChange}
                            options={{
                            minimap: { enabled: false },
                            scrollBeyondLastLine: false,
                            automaticLayout: true,
                            }}
                        />
                    </Col>
                </Row>
                <Title level={4}>Example Response</Title>
                        <Editor
                            height="600px"
                            language="json"
                            theme="vs-dark"
                            value={responseCode}
                            // onChange={handleEditorChange}
                            options={{
                            minimap: { enabled: false },
                            scrollBeyondLastLine: false,
                            automaticLayout: true,
                            }}
                        />
            </Content>
            <Modal
            title='Identifier Data ID'
            visible={isModalOpen}
            width={1000}
            onCancel={handleCancel}
            footer={false}
        >
            <TableCategory/>
           
        </Modal>
        </Layout>
    );
};


