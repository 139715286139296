import React, { useEffect, useRef, useState } from "react";
import maplibregl from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import useReduxOneApi from "../../../../hoc/hooks/useOneApi";

export const MapPolygon: React.FC<React.PropsWithChildren> = ({ children }) => {
    const {setPollyApi} = useReduxOneApi()
  const [coordinates, setCoordinates] = useState([
    
        [
            106.82643616574086,
            -6.182085408588648
        ],
        [
            106.84021348220287,
            -6.152732478920527
        ],
        [
            106.86949359137294,
            -6.161912613045303
        ],
        [
            106.87735897885135,
            -6.183572067641269
        ],
        [
            106.83643616574086,
            -6.192085408588648
        ],
        [
            106.82643616574086,
            -6.192085408588648
        ],
        [
            106.82643616574086,
            -6.182085408588648
        ]
    
  ]);
// console.log(coordinates);

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<maplibregl.Map | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);

  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new maplibregl.Map({
        container: mapContainerRef.current,
        style: 'https://api.maptiler.com/maps/streets/style.json?key=IkttUJmMfqWCx0g43vGM',
        center: [106.82643616574086, -6.182085408588648],
        zoom: 11,
      });

      drawRef.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: false,
          trash: false
        }
      });

      // Use type assertion to bypass the TypeScript check
      if (mapRef.current) {
        (mapRef.current as any).addControl(drawRef.current);
      }

      mapRef.current.on('draw.create', updatePolygon);
      mapRef.current.on('draw.update', updatePolygon);
      mapRef.current.on('draw.delete', updatePolygon);

      // Add initial polygon
      if (drawRef.current) {
        drawRef.current.add({
          id: 'polygon',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [coordinates]
          }
        });
      }
    }

    function updatePolygon() {
      if (drawRef.current) {
        const data = drawRef.current.getAll();
        if (data.features.length > 0) {
          const geometry = data.features[0].geometry;
          if (geometry.type === 'Polygon') {
            const updatedCoordinates = geometry.coordinates[0];
            setCoordinates(updatedCoordinates);
          }
        } else {
          setCoordinates([]);
        }
      }
    }
  }, [coordinates]);

useEffect(()=> {
    setPollyApi(coordinates)
       // eslint-disable-next-line react-hooks/exhaustive-deps
},[coordinates])
  

  return (
    <div className="map-wrap">
      <div ref={mapContainerRef} style={{ width: '100%', height: '350px' }}>
        {children}
      </div>
    </div>
  );
};
