import React from 'react';
import { Space, Button, Tooltip } from 'antd';
import { LeftOutlined } from '@ant-design/icons'; // Import ikon yang diperlukan

export const ToogleMenu: React.FC<any> = () => {
    return (
        <React.Fragment>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    left: '280px',
                    top: '15px',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                  <Tooltip
					placement="right"
					color='white'
                >
                <Button
                    style={{
                        backgroundColor: 'white', // Ubah warna tombol menjadi putih
                        color: 'black', // Ubah warna teks menjadi hitam
                        height: "35px",
                        width: "35px",
                        display: 'flex',
                        borderRadius:'50%',
                        justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                        alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                    }}
                >
                    <LeftOutlined /> 
                </Button>
                </Tooltip>
            </Space>
        </React.Fragment>
    )
}