import React, { useEffect, useState, useRef } from "react";
import maplibregl from 'maplibre-gl';
import useReduxOneApi from "../../../../hoc/hooks/useOneApi";

export const MapPoint: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [latLng, setLatLng] = useState({ lat: -6.182085408588648, lng: 106.82643616574086 });
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<maplibregl.Map | null>(null);
  const markerRef = useRef<maplibregl.Marker | null>(null);
  const {setPointApi} = useReduxOneApi()

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (mapContainerRef.current && !mapRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
      mapRef.current = new maplibregl.Map({
        container: mapContainerRef.current,
        style: 'https://api.maptiler.com/maps/streets/style.json?key=IkttUJmMfqWCx0g43vGM',
        center: [latLng.lng, latLng.lat],
        zoom: 9,
      });

      markerRef.current = new maplibregl.Marker({
        draggable: true,
      })
        .setLngLat([latLng.lng, latLng.lat])
        .addTo(mapRef.current);

      markerRef.current.on('dragend', () => {
        const lngLat = markerRef.current!.getLngLat();
        setLatLng({ lat: lngLat.lat, lng: lngLat.lng });
        
      });
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// eslint-disable-next-line react-hooks/exhaustive-deps
useEffect(()=> {
    setPointApi(latLng)
       // eslint-disable-next-line react-hooks/exhaustive-deps
},[latLng])
  return (
    <div className="map-wrap">
      <div ref={mapContainerRef} style={{ width: '100%', height: '350px' }}>
        {children}
      </div>
    </div>
  );
};
