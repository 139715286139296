import React, { useEffect, useState } from 'react';
import { Layout, Card, Table, Row, Col, Button, Select, Spin } from 'antd';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import moment from 'moment';
import useReduxOneApi from '../../../../hoc/hooks/useOneApi';
import {
  auth,
} from '../../../../firebase/firebaseConfig';
import Text from '../../../../style/styledComponent/Text';
import { IoArrowDownOutline, IoArrowUpOutline, IoWalletOutline } from "react-icons/io5";
import { ModalTopUp } from '../../../../shared/modal';
import useReduxDrawer from '../../../../hoc/hooks/useDrawer';
import useReduxUser from '../../../../hoc/hooks/useUser';
import { PiShareNetwork } from "react-icons/pi";
import { LuTableProperties } from "react-icons/lu";
import { getStatusColor } from '../../../../helper/color';

const { Content } = Layout;
const { Option } = Select;

interface ProductData {
  time: string;
  product: string;
  total: number;
}

interface GroupedDataItem {
  name: string;
  [key: string]: number | string;
}

// Define types for the grouped data
interface ListKey {
  active: boolean;
  [key: string]: any; // Allow for additional properties
}
const columns = [
  {
    title: 'API Type',
    dataIndex: 'reqType',
    key: 'reqType',
    render: (text: string | null) => text || '-',
  },
  {
    title: 'Data ID',
    dataIndex: 'data_id',
    key: 'data_id',
    render: (text: string | null) => text || '-',
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    render: (text: string | null) => text || '-',
  },

  {
    title: 'Type Data',
    dataIndex: 'type_data',
    key: 'type_data',
    render: (text: string | null) => text || '-',
  },
  {
    title: 'Size',
    dataIndex: 'size',
    key: 'size',
    render: (value: number | null) => value !== null ? value : '-',
  },
  {
    title: 'Status Code',
    dataIndex: 'status_code',
    key: 'status_code',
    render: (_: any, record: any) => (
      <Button style={{ 
        width: '100px', 
        height: '25px', 
        backgroundColor: (getStatusColor(record.status_code)), 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center' 
      }}>
        <Text style={{ fontWeight: 500, textAlign: 'center' }}> 
          {record.status_code}
        </Text>
      </Button>
    ),
  },

  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => moment(date).format('YYYY-MM-DD HH:mm:ss'), // Format date
  },
];


export const DashboardChart: React.FC = () => {
  const { getlistApiLogs, apilogs } = useReduxOneApi();
  const { ModalTopUpOpen, isOpenModalTopUp } = useReduxDrawer();
  const [apiKeys, setApiKeys] = useState<ListKey[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const { user, getDetailUser, dataUser } = useReduxUser();

  const prevKeys: ListKey[] = Object.entries(dataUser?.apiKeysData ?? {}).flatMap(([key, value]) => {
    const apiKeyValue = value as ListKey; // Type assertion

    if (typeof apiKeyValue === "object" && apiKeyValue !== null ) {
      return [{
        key, // Set the key explicitly
        ...apiKeyValue, // Spread the rest of the properties
      }];
    }

    if (!(value as ListKey)?.active) {
      return [{
        key, // Set the key explicitly
        active: false, // Ensure 'active' exists
      }];
    }

    return []; // Return an empty array if active is true
  });
  const fetchApiLogs = async (key: string) => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      const token = await user.getIdToken();
      if (!selectedKey) {
        throw new Error('No API key selected');
      }

      // Panggil getlistApiLogs dengan body yang telah diperbarui
      await getlistApiLogs(token, {
        timeRange: null,
        logsKey: selectedKey, // gunakan selectedKey di sini
        groupby: 'day',
      });
    } catch (error) {
      console.error('Error:', error);
      // message.error('Failed to fetch API keys');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value: string) => {
    setSelectedKey(value);
  };
  useEffect(() => {
    if (selectedKey) {
      fetchApiLogs(selectedKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  useEffect(() => {
    // fetchApiLogs()
    const firstActiveKey = prevKeys.find(apiKey => apiKey.active);
    console.log(firstActiveKey);
    
    setApiKeys(prevKeys);
    if (prevKeys.length > 0) {
        setSelectedKey(prevKeys[0].key); // Default to the first key
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickModalTopUp = () => {
    ModalTopUpOpen(true);
  };

  const statusCodeData = apilogs?.data?.status_code || [];
  // const reqTypeData = apilogs?.data?.reqType || [];
  const lastLogData = apilogs?.data?.last_logs || [];
  const totalLog = lastLogData?.length;
  
  const productData: ProductData[] = apilogs?.data?.product || [];
  // const totalRequestData = apilogs?.data?.total_request?.[0] || { total: 0 };

  // Group and transform raw data
  const groupedData = productData.reduce((acc: { [key: string]: { [key: string]: number } }, { time, product, total }: ProductData) => {
    const formattedTime = moment(time).format('DD MMM YYYY'); // Format time for the x-axis
    if (!acc[formattedTime]) {
      acc[formattedTime] = {}; // Initialize the object for this date
    }
    acc[formattedTime][product] = (acc[formattedTime][product] || 0) + Math.round(total); // Round the total value
    return acc;
  }, {});
  
  // Convert grouped data to array
  const data: GroupedDataItem[] = Object.keys(groupedData).map(key => ({
    name: key,
    ...groupedData[key] // Spread properties of the grouped data
  }));
  
  // console.log(data);
  
  // Extract unique products for datasets
  const uniqueProducts = Array.from(new Set(productData.map(item => item.product)));
  
  // Prepare chart data
  const lineChartData = {
    labels: data.map(item => item.name),
    datasets: uniqueProducts.map(product => ({
      label: product,
      data: data.map(item => item[product] !== undefined ? Math.round(item[product] as number) : 0), // Ensure all data points are present
      fill: false,
    })),
  };


  const fetchGetUser = async () => {
    setLoading(true);
    try {
      const userId = user?.uid
      await getDetailUser(userId);
    } catch (error) {
      console.error('Error:', error);
      // message.error('Failed to fetch API keys');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  return (
    <Layout style={{ padding: '44px', minHeight: '100vh',background: "#272B34", }}>
      <Content>
        <Spin spinning={loading}>
          {/* Line Chart */}
          <Card 
          title={
            <span style={{ display: 'flex', alignItems: 'center', color:'white' }}>
            <PiShareNetwork size={20} style={{ marginRight: '8px' }} />
            Traffic Data API
          </span>
          }
          style={{ marginBottom: '24px',background: "#363A45", color:'white' }}>
            <Row gutter={16} style={{ marginBottom: '24px', marginTop: '24px' }}>

              <Col span={12}>
                <div style={{ backgroundColor:'#272B34', border: '1px solid #ccc', padding: '20px', height: '100%', borderRadius: '8px' }}>
                  <Text style={{ color: 'white', marginBottom: '10px', fontSize: '20px', fontWeight: 600 }}>Current Balance</Text>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <IoWalletOutline size={40} style={{ color: 'white', fontSize: '20px', marginRight: '10px' }} />
                    <Text style={{ color: 'white', fontSize: '20px' }}>{`Rp. ${dataUser?.balance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                  </div>
                  {/* <Button  onClick={() => setVisible(true)}>Top Up Balance</Button> */}
                  <Button type="primary" onClick={onClickModalTopUp}>
                    Top Up Balance
                  </Button>
                </div>
              </Col>
              <Col span={12}>
              <div style={{backgroundColor:'#272B34', border: '1px solid #ccc', padding: '20px', height: '100%', borderRadius: '8px' }}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Card 
                      style={{backgroundColor:'#363A45'}}
                      title={<><IoArrowUpOutline style={{ marginRight: '8px',color:'green' }} /> 
                      <span style={{color:'white'}} >Total Balance</span> </>} bordered={false}>
                      <Text style={{ color: 'white', fontSize: '20px' }}>Rp. {dataUser?.total_balance}</Text>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card 
                     style={{backgroundColor:'#363A45'}}
                     title={<><IoArrowDownOutline  style={{ marginRight: '8px',color:'red' }} />  <span style={{color:'white'}} >Total Spent</span></>} bordered={false}>
                      <Text style={{ color: 'white', fontSize: '20px' }}>Rp.{dataUser?.total_spent}</Text>
                    </Card>
                  </Col>
                </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <div style={{ padding: '20px', marginLeft: '20px', height: '100%', borderRadius: '8px' }}>
                <Text style={{ color: 'white', marginBottom: '10px', fontSize: '20px', fontWeight: 600 }}>Select Api Key</Text>
                <Select
                  value={selectedKey}
                  onChange={handleChange}
                  style={{ width: 400 }} // Adjust the width as needed
                  placeholder="Select an API key"
                >
                  {apiKeys.map(key => (
                    <Option key={key.key} value={key.key}>
                      {key.key}
                    </Option>
                  ))}
                </Select>
              </div>
            </Row>
            <Line
            style={{backgroundColor:'#272B34'}}
              data={lineChartData}
              options={{
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Date',
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Total',
                    },
                    ticks: {
                      stepSize: 1, // Ensure step size is 1 to show whole numbers only
                      callback: function (value) {
                        if (Number.isInteger(value)) {
                          return value;
                        }
                      },
                    },
                  },
                },
                plugins: {
                  title: {
                    display: true,
                  },
                },
              }}
            />
            <Row gutter={16} style={{ marginBottom: '24px', marginTop: '24px' }}>
              <Col span={8}>
                <Card style={{background:'#272B34', color:'white'}} title={<Text>success</Text>}>
                  {statusCodeData.reduce((acc: any, item: { status_code: number; total: any; }) => {
                    if (item.status_code === 200 || item.status_code === 201) {
                      return acc + item.total;
                    }
                    return acc;
                  }, 0)}
                </Card>
              </Col>
              <Col span={8}>
                {/* <Card title="Failed">0</Card> */}
                <Card style={{background:'#272B34', color:'white'}} title={<Text>failed</Text>}>
                  {statusCodeData.reduce((acc: any, item: { status_code: number; total: any; }) => {
                    if (item.status_code >= 400) {
                      return acc + item.total;
                    }
                    return acc;
                  }, 0)}
                </Card>
              </Col>
              <Col span={8}>
              <Card style={{background:'#272B34', color:'white'}} title={<Text>total request</Text>}>{totalLog}</Card>
              </Col>
            </Row>
          </Card>
          <Card 
          style={{background:'#363A45', color:'white'}}
          title={
            <span style={{color:'white', display: 'flex', alignItems: 'center' }}>
            <LuTableProperties size={20} style={{ marginRight: '8px' }} />
            Table Data API
          </span>
          }
          >
            <Table    rowClassName={() => 'custom-row'} dataSource={lastLogData} columns={columns} rowKey="name" />
          </Card>
        </Spin>
      </Content>
      <ModalTopUp visibled={isOpenModalTopUp} />
    </Layout>
  );
};
