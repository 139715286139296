import { FeatureCollection } from 'geojson';
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../store/config.strore';
import { Action } from 'redux'
import {
  setIsloadingData,
  setProgressData
} from '../store/map.store';
// actions.ts
export const setGjsData = (gjs: FeatureCollection) => ({
  type: 'SET_GJS_DATA' as const,
  payload: gjs,
});

export const setGjsPropsData = (gjsProperties: number) => ({
  type: 'SET_GJS_PROPS_DATA' as const,
  payload: gjsProperties,
});

export const setLoadingData = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(setIsloadingData(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const setProgresData = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(setProgressData(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export type ActionTypes =
  | ReturnType<typeof setGjsData>
  | ReturnType<typeof setGjsPropsData>;