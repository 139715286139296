import {api} from './api';

const getCatalogApi = async (params:string, RequestBody: any): Promise<any> => {
      const coordinates = RequestBody.coordinates
        const url: string = `/api/catalog/available-data`;
        const dataFeatures = { 
        features: [{
           type: "Feature",
           properties: {},
           geometry: {
             type: "Polygon",
             coordinates
            } }],
            grouping: true,
            type: params 
          };
    
        const { data } = await api.post<any>(url, dataFeatures);
        return data;
    };
    

const catalogServicesApi = {
    getCatalogApi
};

export default catalogServicesApi;