import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { 
   getCatalog,
   getRespPoint,
   getRespPollygon,
   createApiKeys,
   getApiKeys,
   getLogs
} from '../action/oneapi.action';
interface ApiKey {
    key: string;
    generatedAt: string;
  }
  

const useReduxOneApi = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
         catalogData,
         isloading,
         responseApiPoint,
         responseApiPolygon,
         apiKeys,
         listApiKeys,
         apilogs
     }  = useSelector((state: RootState) => state.oneapi);

    const getCatalogData = async () => {
        dispatch(getCatalog());
    };
    const setPointApi = async (data: any) => {
        dispatch(getRespPoint(data));
    };
    const setPollyApi = async (data: any) => {
        dispatch(getRespPollygon(data));
    };

    const generateApiKeys = async (data: any) => {
        await dispatch(createApiKeys(data));
    };
    const getlistApiKey = async (token: string): Promise<ApiKey[]> => {
        return await dispatch(getApiKeys(token));
    };
    const getlistApiLogs = async (token: string, body: any): Promise<ApiKey[]> => {
        return await dispatch(getLogs(token,body));
    };

    return {
        isloading,
        catalogData,
        responseApiPoint,
        responseApiPolygon,
        apiKeys,
        listApiKeys,
        apilogs,
        getCatalogData,
        setPointApi,
        setPollyApi,
        generateApiKeys,
        getlistApiKey,
        getlistApiLogs
       
    }; 
};

export default useReduxOneApi;
