import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { paymentCreateInvoice,paymentTopUp } from '../action/payment.action';

const useReduxPayment = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const { data, isloading, error,dataTopUp } = useSelector((state: RootState) => state.payment);

    const paymentDispatch = async (data: any) => {
        return await dispatch(paymentCreateInvoice(data)); // Ensure the action is awaited and returned
    };
    const topUpPayment = async (token: string, data: any) => {
        return await dispatch(paymentTopUp(token, data)); // Ensure the action is awaited and 
    };

    return {
        paymentDispatch,
        topUpPayment,
        dataTopUp,
        data,
        isloading,
        error
    };
};

export default useReduxPayment;
