// store.ts
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import mapReducer from './map.store';
import pixelReducer from './pixel.store';
import drawerReducer from './drawTools.store';
import transactionReducer from './transaction.store';
import promptReducer from './prompt.store';
import tourReduces from './tour.store';
import userReduces from './user.store';
import mapReduces from './map.store';
import oneApiReduces from './oneApi.store';
import paymentReducer from './payment.store';
import catalogReducer from './catalog.store';

const rootReducer = combineReducers({
  app: mapReducer,
  pixel: pixelReducer,
  drawer: drawerReducer,
  transaction: transactionReducer,
  prompt: promptReducer,
  tour: tourReduces,
  user: userReduces,
  map: mapReduces,
  oneapi: oneApiReduces,
  payment: paymentReducer,
  catalog: catalogReducer
});

const store = configureStore({
  reducer: rootReducer,
});
export type RootState = ReturnType<typeof store.getState>;
export default rootReducer;