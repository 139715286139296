import React, { useEffect, useState } from 'react';
import { Modal, Button, InputNumber, Row, Col, Typography,Form,Spin } from 'antd';
import useReduxDrawer from '../../hoc/hooks/useDrawer';
import logo from '../../asesst/Icons/product/Geosquare-logo.png'; // 
import { IoWalletOutline } from "react-icons/io5";
import { MdCancelPresentation } from "react-icons/md";
import useReduxPayment from '../../hoc/hooks/usePayment';
import { LoadingOutlined } from '@ant-design/icons';
import {
    auth,
  } from '../../firebase/firebaseConfig';

const { Text } = Typography;
interface PopupModalProps {
    visibled: boolean;
}
const initialState = {
    amount:0,
    currency: 'IDR',
    invoiceDuration: 86400, 
  };

export const ModalTopUp: React.FC<PopupModalProps> = ({ visibled }) => {
    const { isOpenModalTopUp, ModalTopUpClose } = useReduxDrawer();
    const [amount, setAmount] = useState<number | null>(null);
    const [activeButton, setActiveButton] = useState();
    const { topUpPayment, isloading } = useReduxPayment();
    const [formDataTopUp, setFormDataTopUp] = useState(initialState);
    const [form] = Form.useForm(); // Create a form instance

  const handleAmountChange = (value: number | null) => {
    setAmount(value);
  };

  const handleButtonClick = (value: number, buttonActive:any) => {
    setAmount(value);
    setActiveButton(buttonActive);
  };

  const handleCancel = () => {
    ModalTopUpClose(false)
    form.resetFields();
  };
  useEffect(() => {
    setFormDataTopUp({
        amount: amount || 0,
        currency: "IDR",
        invoiceDuration: 86400
    });
    // eslint-disable-next-line
  }, [amount]);

  const handlePay = async () => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error('User not authenticated');
    }
    const token = await user.getIdToken();
    try {
        const response = await topUpPayment(token, formDataTopUp);
        if (response && response.success) {
            window.open(response.data, '_blank'); // Open in a new tab
            handleCancel()
        }
      } catch (error) {
        console.error('Failed to checkout data');
        // message.error('Failed to checkout data. Please try again.');
      }
    };
  const buttonStyles = (buttonActive: any): React.CSSProperties => ({
        margin: '0 10px',
        display: 'flex',
        alignItems: 'center',
        width: '140px',
        height: '55px',
        marginTop:'5px',
        fontSize: '10px',
        // fontWeight: 700,
        backgroundColor: activeButton === buttonActive ? '#C4DCFD' : '#fff',
        color: activeButton === buttonActive ? '#4B83D9' : '#000',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    });
    const payButtonTextColor = amount ? 'white' : 'black';
  return (
    <>
      <Modal
        // title="Top Up Balance"
        open={isOpenModalTopUp}
        onCancel={handleCancel}
        footer={[
            <div style={{ position: 'relative', left: '50%', marginTop: '20px', marginBottom: '20px', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Button 
          style={{
            margin: '0 10px',
            display: 'flex',
            alignItems: 'center',
            width: '120px',
            height: '50px',
            marginTop:'10px',
            fontSize: '10px',
          }}
            key="cancel" 
            icon={<MdCancelPresentation size={24} color='red'/>}
            onClick={handleCancel}>
             <Text style={{ fontSize: '15px',color:'black'}}> Cancel</Text>
          </Button>,
          {isloading ? (
            <Button 
            type="primary" 
            icon={   <Spin indicator={<LoadingOutlined spin />} size="small"  style={{color:'white'}}/>}
             style={{
                margin: '0 10px',
                display: 'flex',
                alignItems: 'center',
                width: '120px',
                height: '50px',
                marginTop:'10px',
                fontSize: '10px',
               }}>
                <Text style={{ fontSize: '15px', color: 'white' }}> ...loading</Text>
             </Button>
          ) : (
          <Button
          icon={<IoWalletOutline size={24} />}
           key="pay" 
           style={{
            margin: '0 10px',
            display: 'flex',
            alignItems: 'center',
            width: '120px',
            height: '50px',
            marginTop:'10px',
            fontSize: '10px',
           }}
           type="primary"
           onClick={handlePay}
           disabled={amount === null} // Disable if amount is 0
           >
             <Text style={{ fontSize: '15px', color: payButtonTextColor }}> Pay</Text>
          </Button>
        )}
          </div>
        ]}
      >
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <img src={logo} alt="Geosquare Logo" style={{ width: '150px' }} />
          <Text style={{ display: 'block', textAlign: 'center', fontSize: '14px' }}>
             you will be redirected to the payment page with the selected amount.
      </Text>
        </div>
        <Row gutter={[16, 16]} justify="center" style={{ marginBottom: '24px' }}>
          <Col>
            <Button style={buttonStyles(200000)} onClick={() => handleButtonClick(200000,200000)}>
              <Text style={{ fontSize: '15px'}}> Rp.200.000</Text>
            </Button>
          </Col>
          <Col>
          <Button style={buttonStyles(500000)} onClick={() => handleButtonClick(500000,500000)}>
              <Text style={{ fontSize: '15px' }}>Rp.500.000</Text>
            </Button>
          </Col>
          <Col>
            <Button style={buttonStyles(1000000)} onClick={() => handleButtonClick(1000000,1000000)}>
              <Text style={{ fontSize: '15px' }}>Rp.1.000.000</Text>
            </Button>
          </Col>
          <Col>
            <Button style={buttonStyles(2000000)} onClick={() => handleButtonClick(2000000,2000000)}>
              <Text style={{ fontSize: '15px'}}>Rp.2.000.000</Text>
            </Button>
          </Col>
          <Col>
          <Button style={buttonStyles(5000000)} onClick={() => handleButtonClick(5000000,5000000)}>
              <Text style={{ fontSize: '15px' }}>Rp.5.000.000</Text>
            </Button>
          </Col>
        </Row>
        <Text style={{ display: 'block', marginBottom: '8px' }}>Other Nominal</Text>
        <InputNumber
          min={1}
          value={amount}
          onChange={handleAmountChange}
          style={{ width: '100%', marginBottom: '24px' }}
          formatter={value => `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value ? parseFloat(value.replace(/Rp.\s?|,/g, '')) : 0}
        />
      </Modal>
    </>
  );
};

