import React from 'react';
import { Space } from 'antd';
import { CardListCatalog } from '../../shared/map/card/card';

export const CardCatalog: React.FC<any> = ({ selectedCategory, location }) => {
    const cardsData = Object.values(selectedCategory).flat().map((item: any) => (
    {
        title: item?.alias,
        description: item?.description,
        price: item.price?.total_price != null 
        ? `Rp ${item?.price?.total_price.toLocaleString('id-ID')}` 
        : 'Free',
        size: item?.size < 50 ? '5x5' : '50x50',
        totalpixel: item?.price.total_pixel
        ? new Intl.NumberFormat('id-ID').format(item.price.total_pixel)
        : '',
        status: item?.status,
        areaSize:`${location?.area} km2` ,
        output: item?.output_type,
        year: item?.metadata?.year,
        address: location.address,
        centeroid: {
            lattitude: location?.centroid
            [0],
            longitude: location?.centroid
            [1]
        }
    }));
    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '305px',
                    top: '0%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                 <CardListCatalog dataCard={cardsData} />
            </Space>
        </React.Fragment>
    );
};
