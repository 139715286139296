// import { FeatureCollection } from 'geojson';
import { ThunkAction } from 'redux-thunk'
import pixelApi from '../../service/pixelApi'
import { Action } from 'redux'
import { AxiosError } from 'axios'
import { IDrawPixelData, PixelData } from '../../interface';
import { RootState } from '../store/config.strore';
import { 
  fetchPixelDataFailure, 
  fetchPixelDataStart, 
  // fetchPixelDataSuccess, 
  fetchPixelSubCategoryDataSuccess, 
  fetchPixelDrawDataSuccess,
  fetchPixelByIdDataSuccess,
  getDataSampleSuccess,
  getDataSampleInsightSuccess,
  getDataSampleSurveySuccess,
  getParamsData
 } from '../store/pixel.store';

export interface IFetchPixelDataAction extends Action<'FETCH_DATA_PIXEL'> {}

export interface IFetchPixelSucessAction
	extends Action<'FETCH_DATA_PIXEL_SUCCESS'> {
	pixel: PixelData
}

export interface IFetchPixelErrorAction
	extends Action<'FETCH_DATA_PIXEL_ERROR'> {
	error: AxiosError
}

export type pixelActions =
| IFetchPixelDataAction
| IFetchPixelSucessAction
| IFetchPixelErrorAction


// export const getPixelActionCreator = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
//   try {
//     dispatch(fetchPixelDataStart());
//     // Example asynchronous fetch
//     const pixel: PixelData = await pixelApi.getCategoryPixel();
//     if (pixel) {
//       dispatch(fetchPixelDataSuccess(pixel));
//     }
//     // dispatch(fetchPixelDataSuccess(pixel)); // Assuming you are setting only one pixel category
//   } catch (error) {
//     console.error('Error fetching pixel data:', error);
//     dispatch(fetchPixelDataFailure());
//   }
// };

export const getPixelSubCategoryAction = (id: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
  
    const pixel: PixelData = await pixelApi.getSubCategoryPixel(id);
    dispatch(fetchPixelSubCategoryDataSuccess(pixel)); 

  } catch (error) {
    console.error('Error fetching pixel data:', error);
    dispatch(fetchPixelDataFailure());
  }
};

export const getPixelDrawAction = (params: string, RequestBody: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
   
    // Dispatch fetchPixelDataStart immediately
    dispatch(fetchPixelDataStart());
    // Fetch pixel data
    const pixel: IDrawPixelData = await pixelApi.drawPixelDataApi(params, RequestBody);
    // Check if pixel data is valid and dispatch fetchPixelDrawDataSuccess
    if (pixel) {
      dispatch(fetchPixelDrawDataSuccess(pixel));
    }

  } catch (error) {
    console.error('Error fetching pixel data:', error);
    dispatch(fetchPixelDataFailure());
  }
};

export const getPixelByiD = (id: string, data_id: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
  dispatch(fetchPixelDataStart());
  
    const pixel: any = await pixelApi.resultPixelById (id, data_id);
    dispatch(fetchPixelByIdDataSuccess(pixel)); 

  } catch (error) {
    console.error('Error fetching pixel data:', error);
    dispatch(fetchPixelDataFailure());
  }
};

export const getSampleStaticData = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
  // dispatch(fetchPixelDataStart());
    dispatch(getDataSampleSuccess(data)); 
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const getSampleStaticDataInsight = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
  // dispatch(fetchPixelDataStart());
    dispatch(getDataSampleInsightSuccess(data)); 
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const getSampleStaticDataSurvey = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
  // dispatch(fetchPixelDataStart());
    dispatch(getDataSampleSurveySuccess(data)); 
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const getParamData = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
  // dispatch(fetchPixelDataStart());
    dispatch(getParamsData(data)); 
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};



