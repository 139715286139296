import React, { useEffect, useState } from 'react';
import { Typography, Card } from 'antd';
import Editor from '@monaco-editor/react';
import useReduxOneApi from '../../../../hoc/hooks/useOneApi';
const { Title } = Typography;
export const EditableTextArea: React.FC = () => {
    const {responseApiPoint} = useReduxOneApi()
    const [code,setCode] = useState(
`{
    "lat": ${responseApiPoint?.lat},
    "lng": ${responseApiPoint?.lng}
}`);

useEffect(() => {
    setCode(
`
// move point on the left side to see response latitude and longitude
var requestOptions = {
  method: 'GET',
  redirect: 'follow'
};
// sample response to get latitude and longitude by point
var longitude = ${responseApiPoint?.lng}
var latitude = ${responseApiPoint?.lat}

fetch("https://api.geosquare.ai/api/data/people_gen_z?longitude=${responseApiPoint?.lng}4&latitude=${responseApiPoint?.lat}&key=7e6b03ed085873b933d", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`

    );
  }, [responseApiPoint]);
    
  return (
    <Card style={{ height: '450px' }}>
     <Title level={3}>Example Request</Title>
    <Editor
        height="350px"
        language="javascript"
        theme="vs-dark"
        value={code}
        // onChange={handleEditorChange}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          automaticLayout: true,
        }}
      />
    </Card>
  );
};
