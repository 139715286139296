import React, { useEffect, useState } from 'react';
import { Card, Button, Modal } from 'antd';
import { CoffeeOutlined } from '@ant-design/icons';
import Text from '../../../style/styledComponent/Text';
import icAreaSize from '../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../../asesst/Icons/interactivemap/icon/grid-card.svg';
import icChart from '../../../asesst/Icons/interactivemap/icon/cart.svg';
import ImgdataCard from '../../../asesst/Icons/interactivemap/imgmodalbuy.svg';
import { v4 as uuidv4 } from 'uuid';
import { EnvironmentOutlined, AimOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
// import useReduxPayment from '../../../hoc/hooks/usePayment';
import useReduxTransaction from '../../../hoc/hooks/useTransaction';
import useReduxUser from '../../../hoc/hooks/useUser';

interface InsightCardProps {
  title: string;
  description: string;
  price: string;
  size: any,
  totalpixel: any,
  status: string,
  output: any,
  year: number
  areaSize: any
  address: any,
  centeroid: {
    lattitude: number,
    longitude: number
  }
}

interface CardListProps {
  dataCard: InsightCardProps[]; // Array of cards to be displayed
}

const FormBuyState = {
  name: '',
  email: '',
  number: '',
  item: '',
  data_id: '',
  polygon_id: '',
  output: '',
  output_status: '',
  order_id: '',
  totalPrice: 0,
  status_payment: 'waiting',
  status_data: 'process',
  type: '',
  category: '',
  totalUnit: 0,
  sizeGrid: 0,
  created_at: '', // Add date field
  deleted_at: '',
  updated_at: '',
  uid: '',
  alias: '',
  source: '',
  year: '',
  version: '',
  externalId: '',
  amount:'',
  payerEmail: '',
  description: '',
  currency: 'IDR',
  invoiceDuration: 86400, 
};
export const CardListCatalog: React.FC<CardListProps> = ({ dataCard }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<InsightCardProps | null>(null);
  const { formData } = useReduxTransaction();
  const [formDataBuy, setFormDataBuy] = useState(FormBuyState);
  // const { paymentDispatch, data, isloading} = useReduxPayment();
  // const [dataPay, setDataPay] = useState(null);
  const { user } = useReduxUser();
  const currentDate = new Date().toISOString();
  // Function to handle opening the modal
  const showModal = (item: InsightCardProps) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  };

  // Function to handle closing the modal
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  useEffect(() => {
    const uuid = uuidv4();
    const invoiceId = `GS-${uuid.split('-').pop()}`;
    setFormDataBuy({
      name: `${user?.displayName}`,
      email: `${user?.email}`,
      number: `${user?.phoneNumber || 0}`,
      item: formData?.alias,
      data_id: formData?.data_id,
      polygon_id: formData?.polygon_id,
      output: '',
      order_id: invoiceId,
      totalPrice: formData?.price?.total_price,
      type: formData?.type,
      category: formData?.category,
      status_payment: 'waiting',
      status_data: 'process',
      output_status: formData?.output_type?.length === 1 && formData.output_type[0] === 'getquote' ? 'getquote' : 'available',
      totalUnit: formData?.price?.total_pixel,
      sizeGrid: formData?.size,
      created_at: currentDate,
      deleted_at: '',
      updated_at: '',
      uid: `${user?.uid}`,
      alias: formData?.alias,
      source: formData?.metadata?.source,
      year: formData?.metadata?.year,
      version: formData?.metadata?.version,
      externalId: invoiceId,
      amount: formData?.price?.total_price,
      payerEmail: `${user?.email}`,
      description: formData?.category,
      currency: 'IDR',
      invoiceDuration: 86400, 
    });
    // eslint-disable-next-line
  }, [formData, user]);
console.log(formDataBuy);


  return (
    <div style={{ height: 'calc(100vh - 50px)', overflowY: 'auto', paddingRight: '8px' }}>
      {dataCard.map((item, index) => (
        <div
          key={index}
          className="custom-card"
          style={{
            cursor: 'pointer',
            transition: 'transform 0.3s ease', // Smooth transition
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateX(10px)'; // Move to the right by 10px
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateX(0)'; // Reset position
          }}
        >
          <Card
            style={{
              width: 280,
              backgroundColor: '#142943',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            <div style={{ display: 'flex', marginBottom: 16 }}>
              <div style={{ marginRight: 16 }}>
                <CoffeeOutlined style={{ fontSize: 30, color: 'white' }} />
              </div>
              <div>
                <Text style={{ color: 'white', margin: 0, fontSize: '16px' }}>{item.title}</Text>
                <Text style={{ color: 'lightgray', margin: 0, fontSize: '14px' }}>{item.description}</Text>
                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '5px' }}>
                  {/* <ArrowsAltOutlined style={{ color: 'white' }} /> */}
                  <img
                    alt="example"
                    src={icAreaSize}
                  />
                  <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{item.areaSize}
                  </Text>
                  <img
                    alt="example"
                    src={icGridTotal}
                  />
                  <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{item.totalpixel}</Text>
                </div>
                <div style={{ display: 'flex', justifyContent: 'left', gap: '10px', marginBottom: '5px', marginTop: '3px' }}>
                  <CoffeeOutlined style={{ color: 'white' }} /> <Text style={{ color: 'white', margin: 0, fontSize: '12px' }}>{item.year}</Text>
                </div>
                <h3 style={{ fontWeight: 'bold', color: 'white', margin: 0 }}>{item.price}</h3>
              </div>
              <div
                style={{
                  background: 'white',
                  padding: '5px',
                  width: '60px',
                  height: '20px',
                  borderRadius: '12px',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: '#316DBA', margin: 0, fontSize: '10px' }}>{item.size}</Text>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '3px' }}>
              <Button
                id='end-point'
                style={{
                  width: '0px',
                  backgroundColor: 'transparent', // Ubah warna tombol menjadi putih
                  color: '#316DBA', // Ubah warna teks menjadi hitam
                  display: 'flex',
                  borderColor: '#316DBA',
                  justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                  alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                }}
              >
                <img
                  src={icChart}
                  alt="Button Icon"
                  width={20}
                  height={20}
                />
              </Button>
              <Button 
              style={{ borderRadius: '8px', color: '#316DBA', backgroundColor: 'transparent', borderColor: '#316DBA' }}
              onClick={() => showModal(item)} // Show modal when clicked
              >View Detail</Button>
              <Button
                style={{ borderRadius: '8px', backgroundColor: '#4A7FC0', borderColor: 'transparent', color: 'white' }}
                onClick={() => showModal(item)} // Show modal when clicked
              >
                {item.price === 'Free' ? 'Get Data' : 'Buy Data'}
              </Button>
            </div>
          </Card>
        </div>
      ))}

      {/* Modal */}
      <Modal
       styles={{
        content: { backgroundColor: '#1E334D' },
        body: { backgroundColor: '#1E334D', color: 'white' },
      }}
      visible={isModalVisible}
        width={700}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        okText="Buy Now"
        cancelText="Cancel"
        closeIcon={<CloseOutlined style={{ color: 'white' }} />} // Set the close icon color to white
      >

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginLeft: '1rem', backgroundColor: 'white', borderRadius: "8px", alignContent: 'center' }}>
            <img
              src={ImgdataCard}
              alt="Animated GIF"
              style={{
                maxWidth: "100%",
                maxHeight: "100%"
              }}
            />
          </div>
            <div style={{ flex: 1, marginLeft: '1rem' }}>
              <span style={{ fontSize: '17px', marginBottom: '5px', textAlign: 'left', display: 'block', width: '100%', fontWeight: 'bold' }}>{selectedItem?.title}</span>
              <div
                style={{
                  border: '1px solid white', // Set border color to white
                  background: 'transparent', // Set background to transparent
                  paddingLeft: '15px',
                  paddingTop: '10px',
                  width: '350px',
                  height: '80px',
                  borderRadius: '12px',
                  marginBottom: '20px',
                }}
              >
                <EnvironmentOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
                <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Location Address</span>
                <p style={{ fontSize: '12px' }}>
                {selectedItem?.address} 
                </p>
              </div>
              <div
                style={{
                  border: '1px solid white', // Set border color to white
                  background: 'transparent', // Set background to transparent
                  paddingLeft: '15px',
                  paddingTop: '10px',
                  height: '80px',
                  width: '350px',
                  borderRadius: '12px',
                  marginBottom: '20px',
                }}
              >
                <AimOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
                <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Position</span>
                <p style={{ fontSize: '12px' }}>
                {selectedItem?.centeroid?.lattitude} , {selectedItem?.centeroid?.longitude}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '10px', marginTop: '5px', maxWidth: '350px', paddingBottom: '50px' }}>
                <div
                  style={{
                    border: '1px solid white', // Set border color to white
                    background: 'transparent', // Set background to transparent
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    fontSize: '12px',
                    width: '350px',
                    height: '70px',
                    borderRadius: '12px',
                    marginBottom: '20px',
                  }}
                >
                 <img
                      src={icAreaSize}
                      alt="Button Icon"
                      style={{marginRight:'10px'}}

                    />
                  <span style={{ fontWeight: 'bold' }}>Size of the area</span>
                  <p >
                  {selectedItem?.areaSize}
                  </p>
                </div>
                <div
                  style={{
                    fontSize: '12px',
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    height: '70px',
                    alignItems:'center',
                    width: '350px',
                    borderRadius: '12px',
                    marginBottom: '20px',
                    border: '1px solid white', // Set border color to white
                    background: 'transparent', // Set background to transparent
                  }}
                >
                 <img
                      src={icGridTotal}
                      alt="Button Icon"
                      style={{marginRight:'10px'}}

                    />
                  <span style={{ fontWeight: 'bold', color: 'white' }}>Square</span>
                  <p style={{ color: 'white' }}>
                  {selectedItem?.totalpixel},    {selectedItem?.size}m2
                  </p>
                </div>
              </div>
              {/* bottom box */}
              <div
                style={{
                  border: '1px solid white', // Set border color to white
                  background: 'transparent', // Set background to transparent
                  paddingLeft: '15px',
                  paddingTop: '10px',
                  width: '350px',
                  height: '70px',
                  borderRadius: '12px',
                  marginBottom: '20px',
                }}
              >
                {/* <EnvironmentOutlined style={{ fontSize: '18px', marginRight: '10px' }} /> */}
                <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Item</span>
                <p style={{ fontSize: '12px' }}>
                  Raw Data (.parquet, .csv, .sql, .xlsx)
                </p>
              </div>
              <div
                style={{
                  padding: '5px',
                  backgroundColor: '#1E334D',
                  borderRadius: '10px',
                }}
              >
                <div style={{ color: 'white' }}>
                  SubTotal
                </div>
                <div style={{ marginBottom: '5px', color: 'white' }}>
                  Items for  <b> {selectedItem?.price}</b>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '3px' }}>
                  <Button
                    id='end-point'
                    style={{
                      width: '50px',
                      height: '48px',
                      backgroundColor: 'transparent', // Ubah warna tombol menjadi putih
                      color: '#316DBA', // Ubah warna teks menjadi hitam
                      display: 'flex',
                      borderColor: '#316DBA',
                      justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                      alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                    }}
                  >
                    <img
                      src={icChart}
                      alt="Button Icon"
                      width={30}
                      height={30}
                    />
                  </Button>
                  <Button
                    style={{
                      height: '48px',
                      width: '100%',
                      color: 'white',
                      backgroundColor: '#4A7FC0',
                      borderColor: '#4A7FC0',
                    }}
                  >
                    Checkout
                  </Button>
                </div>
              </div>
            </div>
        </div>
      </Modal>
    </div>
  );
};
