// Loader.js
import React from 'react';
import { FaRegSquare } from 'react-icons/fa'; // Using FontAwesome globe icon

const LoaderMap = () => {
  return (
    <div className="loader">
      <FaRegSquare className="globe-icon" />
    </div>
  );
};

export default LoaderMap;
