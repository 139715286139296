import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import {
    auth,
    googleProvider,
    appleProvider,
    db
} from '../../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { 
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup
} from "firebase/auth";
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { FcGoogle } from 'react-icons/fc';
import { FaApple } from 'react-icons/fa';
import logo from '../../asesst/Icons/product/Geosquare-logo.png'
import useReduxUser from '../../hoc/hooks/useUser';
import Text from '../../style/styledComponent/Text';

export const Login: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { setUser } = useReduxUser();
    const navigate = useNavigate();

    const handleClick = () => {
        // Perform some logic, and then navigate
        navigate('/');
    };
  
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [setUser]);

    const onFinish = async (values: { email: string; password: string }) => {
        const { email, password } = values;
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const userDocRef = doc(db, 'usersGeosquare', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
              // Create new user document with default values if not exists
              await setDoc(userDocRef, {
                email: user.email,
                provider: 'none',
                photoURL: user.photoURL || null,
                phoneNumber: user.phoneNumber || null,
                displayName: user.displayName || null,
                name: user.displayName || null,
                lastLogin: new Date(),
                createdAt: new Date(),
                balance: 0, // Set default balance or other initial values
                total_spent: 0,
                total_balance:0
              });
            } else {
              // Update only the fields that need to be changed
              await setDoc(userDocRef, {
                lastLogin: new Date(), // Update last login date
              }, { merge: true });
            }
            message.success('Login successful');
            navigate('/'); // Redirect to home page or dashboard
        } catch (error) {
            console.error('Error logging in:', error);
            message.error('Failed to login, please check email and password');
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLogin = async () => {
        setLoading(true);
        try {
            // await signInWithPopup(auth, googleProvider);
            const userCredential = await signInWithPopup(auth, googleProvider);
            const user = userCredential.user;
            // console.log(user);
            
            // Check if user document exists
            const userDocRef = doc(db, 'usersGeosquare', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
              // Create new user document with default values if not exists
              await setDoc(userDocRef, {
                email: user.email,
                provider: 'google',
                photoURL: user.photoURL || null,
                phoneNumber: user.phoneNumber || null,
                displayName: user.displayName || null,
                name: user.displayName || null,
                lastLogin: new Date(),
                createdAt: new Date(),
                balance: 0, // Set default balance or other initial values
                total_spent: 0,
                total_balance:0
              });
            } else {
              // Update only the fields that need to be changed
              await setDoc(userDocRef, {
                lastLogin: new Date(), // Update last login date
              }, { merge: true });
            }
            message.success('Login with Google successful');
            navigate('/');
        } catch (error) {
            console.error('Error logging in with Google:', error);
            message.error('Failed to login with Google');
        } finally {
            setLoading(false);
        }
    };

    const handleAppleLogin = async () => {
        setLoading(true);
        try {
            const userCredential = await signInWithPopup(auth, appleProvider);
            const user = userCredential.user;
            // Check if user document exists
            const userDocRef = doc(db, 'usersGeosquare', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
              // Create new user document with default values if not exists
              await setDoc(userDocRef, {
                email: user.email,
                provider: 'apple',
                photoURL: user.photoURL || null,
                phoneNumber: user.phoneNumber || null,
                displayName: user.displayName || null,
                name: user.displayName || null,
                lastLogin: new Date(),
                createdAt: new Date(),
                balance: 0, // Set default balance or other initial values
                total_spent: 0,
                total_balance:0
              });
            } else {
              // Update only the fields that need to be changed
              await setDoc(userDocRef, {
                lastLogin: new Date(), // Update last login date
              }, { merge: true });
            }

            message.success('Login with Apple successful');
            navigate('/');
        } catch (error) {
            console.error('Error logging in with Apple:', error);
            message.error('Failed to login with Apple');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div style={{ width: 300, margin: '0 auto', padding: '20px 0', textAlign: 'center' }}>
            <img onClick={handleClick} src={logo} alt="Logo" style={{ width: '200px',cursor:'pointer' }} />
            <Form name="login" onFinish={onFinish}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input type="email" placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
               
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} block style={{fontFamily: '"Poppins", sans-serif',}}>
                        Login
                    </Button>
                </Form.Item>
            </Form>
         
            <div style={{ marginTop: '20px',fontFamily: '"Poppins", sans-serif', marginBottom:'20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span style={{ marginBottom: '10px',fontFamily: '"Poppins", sans-serif', }}>Don't have an account?</span>
            <span style={{fontFamily: '"Poppins", sans-serif', marginBottom: '10px', color: '#1890ff', cursor: 'pointer' }} onClick={() => navigate('/register')}>
                Register Here
            </span>
            </div>
            <Text style={{color:'black',fontFamily: '"Poppins", sans-serif',}}>Or Sign In Using</Text>
            <div style={{marginTop:'20px',}} >
            <FcGoogle 
               onClick={handleGoogleLogin}
                style={{ 
                    marginRight: 15 ,
                    cursor:'pointer',
                    transition: 'transform 0.3s ease'}} 
                    size={42}   
              onMouseEnter={(e) => (e.currentTarget.style.transform = 'translateY(-5px)')}
              onMouseLeave={(e) => (e.currentTarget.style.transform = 'translateY(0)')}/>
            <FaApple 
                onClick={handleAppleLogin}
                style={{ 
                    marginRight: 15, 
                    cursor:'pointer',
                    transition: 'transform 0.3s ease'
                }} 
                size={42}   
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'translateY(-5px)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'translateY(0)')}/>
            </div>
        </div>
    );
};
