// src/pages/ApiDocsOverviewPage.tsx
import React from 'react';
import { Layout, Card, Typography, Divider, Row } from 'antd';
import postmanIcon from "../../asesst/Icons/product/postman.svg";
const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

export const ApiDocsOverviewPage: React.FC = () => {
    
    const handleDocsApi = () => {
        window.open('https://api-docs.geosquare.ai/#24b64279-10b2-4b29-9752-6ce8d46f7332', '_blank');
      };
    return (
        <Layout style={{ padding: '24px', minHeight: '100vh' }}>
            <Content>
                <Card title="API Documentation Overview" style={{ marginBottom: '24px' }}>
                    <Title level={2}>Introduction</Title>
                    <Paragraph>
                        Welcome to the API documentation. This API allows you to interact with our platform programmatically. Below, you'll find details on how to authenticate, the available endpoints, and how to make requests.
                    </Paragraph>
                    
                    <Divider />

                    <Title level={2}>Authentication</Title>
                    <Paragraph>
                        To authenticate with the API, you will need an API key. You can generate an API key from your user profile. Include this API key in the `Key` params of your requests.
                    </Paragraph>
                    <Paragraph>
                        <Text code>Key:[YOUR_API_KEY]</Text>
                    </Paragraph>
                    {/* <Button type="primary" style={{ marginBottom: '16px' }}>
                        Go To Page API Key
                    </Button> */}

                    <Divider />

                    <Title level={2}>Base URL</Title>
                    <Paragraph>
                        All API requests should be made to the following base URL:
                    </Paragraph>
                    <Paragraph>
                        <Text code>https://api.geosquare.ai</Text>
                    </Paragraph>

                    <Divider />
                    <Row gutter={16} style={{ marginBottom: '24px' }}>
                            <Card onClick={handleDocsApi} style={{ textAlign: 'center', borderColor: '#ff6c37', cursor:'pointer' }}>
                            <img 
                                src={postmanIcon} 
                                alt="Postman" 
                                style={{ width: '50px', height: '50px', marginBottom: '16px' }} 
                            />
                            <p style={{ color: '#ff6c37', fontWeight: 'bold' }}>
                                Go to full documentation
                            </p>
                            </Card>
                        </Row>
                    {/* <Title level={2}>Endpoints</Title> */}

                    {/* <Title level={3}>1. Get User Information</Title>
                    <Paragraph>
                        <Text code>GET /user</Text>
                    </Paragraph>
                    <Paragraph>
                        This endpoint retrieves information about the authenticated user.
                    </Paragraph>
                    <Paragraph>
                        <strong>Example Request:</strong>
                        <pre>{`GET /user
                            Authorization: Bearer YOUR_API_KEY`}</pre>
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>Example Response:</strong>
                                                    <pre>{`{
                            "id": "user_id",
                            "name": "John Doe",
                            "email": "john.doe@example.com"
                            }`}</pre>
                    </Paragraph>

                    <Divider /> */}

                    {/* <Title level={3}>2. Get Transactions</Title>
                    <Paragraph>
                        <Text code>GET /transactions</Text>
                    </Paragraph>
                    <Paragraph>
                        This endpoint retrieves a list of transactions.
                    </Paragraph>
                    <Paragraph>
                        <strong>Example Request:</strong>
                        <pre>{`GET /transactions
                            Authorization: Bearer YOUR_API_KEY`}</pre>
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>Example Response:</strong>
                                                    <pre>{`[
                            {
                                "id": "transaction_id",
                                "type": "purchase",
                                "amount": 100,
                                "date": "2024-06-26T12:34:56Z"
                            },
                            ...
                            ]`}</pre>
                    </Paragraph> */}

                    <Divider />

                    {/* <Title level={3}>3. Create a Transaction</Title>
                    <Paragraph>
                        <Text code>POST /transactions</Text>
                    </Paragraph>
                    <Paragraph>
                        This endpoint creates a new transaction.
                    </Paragraph>
                    <Paragraph>
                        <strong>Example Request:</strong>
                        <pre>{`POST /transactions
                            Authorization: Bearer YOUR_API_KEY
                            Content-Type: application/json

                            {
                            "type": "purchase",
                            "amount": 100
                            }`}</pre>
                    </Paragraph>
                    <Paragraph>
                        <strong>Example Response:</strong>
                        <pre>{`{
                        "id": "new_transaction_id",
                        "type": "purchase",
                        "amount": 100,
                        "date": "2024-06-26T12:34:56Z"
                        }`}</pre>
                    </Paragraph> */}
                </Card>
            </Content>
        </Layout>
    );
};

