import React from 'react';
import { Space, Button, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'; // Import ikon yang diperlukan
// import type { TourProps } from 'antd';
interface DrawToolsSpaceProps {
    drawerOpen: boolean;
    onOpenDrawer: () => void; // Definisikan properti onOpenDrawer
    onCloseDrawer: () => void; // Tambahkan properti onCloseDrawer
}

export const DrawTools: React.FC<DrawToolsSpaceProps> = ({ drawerOpen, onOpenDrawer, onCloseDrawer }) => {
    // const ref1 = useRef(null);
    // const ref2 = useRef(null);
    // const ref3 = useRef(null);
    // const [open, setOpen] = useState<boolean>(false);

    return (
        <React.Fragment>
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    left: drawerOpen ? '390px' : '15px',
                    top: 24,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                  <Tooltip
                    title={drawerOpen ? <p style={{color:'black'}}>Close</p> :  <p style={{color:'black'}}>Open</p>}
					placement="right"
					color='white'
                >
                <Button
                    style={{
                        backgroundColor: 'white', // Ubah warna tombol menjadi putih
                        color: 'black', // Ubah warna teks menjadi hitam
                        height: "30px",
                        width: "30px",
                        display: 'flex',
                        justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                        alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                    }}
                    onClick={drawerOpen ? onCloseDrawer : onOpenDrawer}
                >
                    {drawerOpen ? <LeftOutlined /> : <RightOutlined />}
                </Button>
                </Tooltip>
            </Space>
        </React.Fragment>
    )
}