import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { 
    promptText
    } from '../action/prompt.action';

const useReduxPrompt = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
        prompt, 
        isloading,
        error,
    }  = useSelector((state: RootState) => state.prompt);

    const promptDispatch = async (prompt: any) => {
        dispatch(promptText(prompt));
    };
    return {
        promptDispatch,
        prompt,
        isloading,
        error
    }; // Return pixelState as well if needed
};

export default useReduxPrompt;
