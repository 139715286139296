// drawerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import Itransaction from '../interface/transactionData';

interface TransactionState {
    formData: any | null;
    formSubmitData: any | undefined | null;
    isloading: boolean; // Add loading property
    fetching: boolean;
    error: boolean;
}

const initialState: TransactionState = {
    formData: null,
    formSubmitData:null,
    fetching: false,
    error: false,
    isloading: false, // Initialize loading state
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    getDetailData: (state, action: PayloadAction<any>) => {
        state.isloading = false; 
        state.formData = action.payload;
      },
    postDataBuy: (state, action: PayloadAction<any>) => {
        state.isloading = false; 
        state.formSubmitData = action.payload;
      },
  },
});

export const { 
    getDetailData,
    postDataBuy
} = transactionSlice.actions;
export default transactionSlice.reducer;
