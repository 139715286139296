
import { api } from './api';
interface ApiKey {
    key: string;
    generatedAt: string;
}

const generateKeys = async (token: string): Promise<any> => {
    const url: string = '/api/apikey/create-apikey';
    try {
        const { data } = await api.post<any>(url, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    } catch (error) {
        console.error('Error occurred while generating keys:', error);
        throw error;
    }
};

const getKeys = async (token: string): Promise<ApiKey[]> => {
    const url: string = '/api/apikey'; // Replace with your API endpoint
    try {
        const { data } = await api.get<any>(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data?.data;
    } catch (error) {
        console.error('Error occurred while fetching data:', error);
        throw error;
    }
};

const getApiLogs = async (token: string, body: any): Promise<ApiKey[]> => {
    const url: string = '/api/logs'; // Replace with your API endpoint
    try {
        const { data } = await api.post<any>(url, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    } catch (error) {
        console.error('Error occurred while fetching data:', error);
        throw error;
    }
};

const apikeys = {
    generateKeys,
    getKeys,
    getApiLogs
};

export default apikeys;
