import React, { useState } from 'react';
import { Layout, Row, Card, Typography ,message, Modal, Col} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import postmanIcon from "../../asesst/Icons/product/postman.svg";
import { TableCategory } from '../../component/page/shared/table';
import { EditableTextAreaCatalog } from '../../component/page/component/componentApi/EditableTextAreaCatalog';
import Text from '../../style/styledComponent/Text';
import { Editor } from '@monaco-editor/react';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const EndpointDocumentationPageOrderCatalog: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
          message.success('URL copied to clipboard');
        });
      };
      const endpointUrl = 'https://api.geosquare.ai/api/data/order/catalog?key=[API_KEY]';
    
      const handleDocsApi = () => {
        window.open('https://api-docs.geosquare.ai/#24b64279-10b2-4b29-9752-6ce8d46f7332', '_blank');
      };
      
    const responseCode = `
    {
        "statusCode": 201,
        "message": "",
        "url": "/api/data/order/catalog",
        "pagination": {
          "perPage": 20,
          "totalPage": 4,
          "totalRows": "64",
          "previousPage": null,
          "nextPage": 2,
          "page": 1
        },
        "data": [
          {
            "data_id": "demography_population",
            "type": "data",
            "category": "demography",
            "product": "demographic_population",
            "subproduct": [
              null
            ],
            "description": "A map depicting population distribution in 5x5 sqm squares, highlighting the number of residents registered in each area.",
            "alias": "Demographic Population",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 5,
            "price": {
              "currency": "IDR",
              "total_pixel": 1817,
              "total_price": 127190
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_bachelor_grad",
            "type": "data",
            "category": "demography",
            "product": "demography_bachelor_grad",
            "subproduct": [
              null
            ],
            "description": "The educational attainment information within each 50x50 sqm square, categorized by the highest degree obtained, from No Schooling to Doctoral Graduate",
            "alias": "Demography Bachelor Graduate",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_christianity",
            "type": "data",
            "category": "demography",
            "product": "demography_christianity",
            "subproduct": [
              null
            ],
            "description": "The religious affiliations of the population within each 50x50 sqm square, categorized into Islam, Christianity, Hindu-Buddha, and Other Religions.",
            "alias": "Demography Religion Christianity",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_death_divorced",
            "type": "data",
            "category": "demography",
            "product": "demography_death_divorced",
            "subproduct": [
              null
            ],
            "description": "The marital status of the population within each 50x50 sqm square, categorized into Not Married, Married, Divorced, and Death Divorced",
            "alias": "Demography Marriage Status Death Divorced",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_diploma1_2_3_grad",
            "type": "data",
            "category": "demography",
            "product": "demography_diploma1_2_3_grad",
            "subproduct": [
              null
            ],
            "description": "The educational attainment information within each 50x50 sqm square, categorized by the highest degree obtained, from No Schooling to Doctoral Graduate",
            "alias": "Demography Diploma 1-2-3 Graduate",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_divorced",
            "type": "data",
            "category": "demography",
            "product": "demography_divorced",
            "subproduct": [
              null
            ],
            "description": "The marital status of the population within each 50x50 sqm square, categorized into Not Married, Married, Divorced, and Death Divorced",
            "alias": "Demography Marriage Status Divorced",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_doctoral_grad",
            "type": "data",
            "category": "demography",
            "product": "demography_doctoral_grad",
            "subproduct": [
              null
            ],
            "description": "The educational attainment information within each 50x50 sqm square, categorized by the highest degree obtained, from No Schooling to Doctoral Graduate",
            "alias": "Demography Doctoral Graduate",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_elementary_grad",
            "type": "data",
            "category": "demography",
            "product": "demography_elementary_grad",
            "subproduct": [
              null
            ],
            "description": "The educational attainment information within each 50x50 sqm square, categorized by the highest degree obtained, from No Schooling to Doctoral Graduate",
            "alias": "Demography Elementary Graduate",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_entrepreneur",
            "type": "data",
            "category": "demography",
            "product": "demography_entrepreneur",
            "subproduct": [
              null
            ],
            "description": "The occupational distribution within each 50x50 sqm square, categorized into various occupations such as Retired, Homemaker, Unemployed, and specific job sectors",
            "alias": "Demography Occupation Entrepreneur",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_fisherman",
            "type": "data",
            "category": "demography",
            "product": "demography_fhisherman",
            "subproduct": [
              null
            ],
            "description": "The occupational distribution within each 50x50 sqm square, categorized into various occupations such as Retired, Homemaker, Unemployed, and specific job sectors",
            "alias": "Demography Occupation Fisherman",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_fisherman",
            "type": "data",
            "category": "demography",
            "product": "demography_fisherman",
            "subproduct": [
              null
            ],
            "description": "The occupational distribution within each 50x50 sqm square, categorized into various occupations such as Retired, Homemaker, Unemployed, and specific job sectors",
            "alias": "Demography Fisherman",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_nurse",
            "type": "data",
            "category": "demography",
            "product": "demography_health",
            "subproduct": [
              null
            ],
            "description": "The occupational distribution within each 50x50 sqm square, categorized into various occupations such as Retired, Homemaker, Unemployed, and specific job sectors",
            "alias": "Demography Occupation Health",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_hindu_buddha",
            "type": "data",
            "category": "demography",
            "product": "demography_hindu_buddha",
            "subproduct": [
              null
            ],
            "description": "The religious affiliations of the population within each 50x50 sqm square, categorized into Islam, Christianity, Hindu-Buddha, and Other Religions.",
            "alias": "Demography Religion Hindu Budha",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_islam",
            "type": "data",
            "category": "demography",
            "product": "demography_islam",
            "subproduct": [
              null
            ],
            "description": "The religious affiliations of the population within each 50x50 sqm square, categorized into Islam, Christianity, Hindu-Buddha, and Other Religions.",
            "alias": "Demography Religion Islam",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_junior_highschool_grad",
            "type": "data",
            "category": "demography",
            "product": "demography_junior_highschool_grad",
            "subproduct": [
              null
            ],
            "description": "The educational attainment information within each 50x50 sqm square, categorized by the highest degree obtained, from No Schooling to Doctoral Graduate",
            "alias": "Demography Junior Highschool Graduate",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_lawyer",
            "type": "data",
            "category": "demography",
            "product": "demography_lawyer",
            "subproduct": [
              null
            ],
            "description": "The occupational distribution within each 50x50 sqm square, categorized into various occupations such as Retired, Homemaker, Unemployed, and specific job sectors",
            "alias": "Demography Occupation Lawyer",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_married",
            "type": "data",
            "category": "demography",
            "product": "demography_married",
            "subproduct": [
              null
            ],
            "description": "The marital status of the population within each 50x50 sqm square, categorized into Not Married, Married, Divorced, and Death Divorced",
            "alias": "Demography Marriage Status Married",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_master_grad",
            "type": "data",
            "category": "demography",
            "product": "demography_master_grad",
            "subproduct": [
              null
            ],
            "description": "The educational attainment information within each 50x50 sqm square, categorized by the highest degree obtained, from No Schooling to Doctoral Graduate",
            "alias": "Demography Master Graduate",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_not_graduated_elementary",
            "type": "data",
            "category": "demography",
            "product": "demography_not_graduated_elementary",
            "subproduct": [
              null
            ],
            "description": "The educational attainment information within each 50x50 sqm square, categorized by the highest degree obtained, from No Schooling to Doctoral Graduate",
            "alias": "Demography Not Graduated Elementary",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          },
          {
            "data_id": "demography_not_married",
            "type": "data",
            "category": "demography",
            "product": "demography_not_married",
            "subproduct": [
              null
            ],
            "description": "The marital status of the population within each 50x50 sqm square, categorized into Not Married, Married, Divorced, and Death Divorced",
            "alias": "Demography Marriage Status Not Married",
            "metadata": {
              "unit": "index",
              "year": 2023,
              "source": "Petain Data",
              "version": "1.0.0",
              "range_value": "1-10",
              "price_per_pixel": 500,
              "value_data_type": "integer"
            },
            "type_data": "integer",
            "size": 50,
            "price": {
              "currency": "IDR",
              "total_pixel": 556,
              "total_price": 38920
            },
            "polygon_id": "f69a8082-a22d-4901-b5a9-9f3afca8b5c9"
          }
        ]
      }
    `
    return (
        <Layout style={{ padding: '24px', minHeight: '100vh', background: '#fff' }}>
            <Content>
                <Title level={3}>Catalog Data Documentation</Title>
                <Row gutter={16} style={{ marginBottom: '24px' }}>
                <Col span={14}>
                <Card style={{ marginTop: '24px' }}>
                    <Title level={3}>Endpoint Documentation</Title>
                    {/* <Paragraph> */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom:'10px' }}>
                        
                            <Text css={{fontWeight:700, color:'Orange', marginRight:'10px'}}>POST</Text>
                    
                        {endpointUrl}
                            <CopyOutlined
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                                onClick={() => copyToClipboard(endpointUrl)}
                            />
                        </div>
                    {/* </Paragraph> */}
                    
                    <Paragraph>
                        <strong>Parameters:</strong>
                        <ul>
                        
                            <li><strong>key:</strong> This query parameter is an API key or access token required for authorization.</li>
                           
                          
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        <strong>Headers:</strong>
                        <ul>
                            <li><strong>Content-Type:</strong> application/json</li>
                        </ul>
                    <Title level={2}>Full Documentation </Title>
                    <Row gutter={16} style={{ marginBottom: '24px' }}>
                        <Card style={{ textAlign: 'center', borderColor: '#ff6c37', cursor:'pointer' }} onClick={handleDocsApi}>
                            <img
                                src={postmanIcon}
                                alt="Postman"
                                style={{ width: '50px', height: '50px', marginBottom: '16px' }}
                            />
                            <p style={{ color: '#ff6c37', fontWeight: 'bold' }}>
                                Go to full documentation
                            </p>
                        </Card>
                    </Row>
                    </Paragraph>
                   
                </Card>
                </Col>
                <Col span={10}>
                        <EditableTextAreaCatalog />
                    </Col>
                </Row>
                <Title level={4}>Example Response</Title>
                        <Editor
                            height="600px"
                            language="json"
                            theme="vs-dark"
                            value={responseCode}
                            // onChange={handleEditorChange}
                            options={{
                            minimap: { enabled: false },
                            scrollBeyondLastLine: false,
                            automaticLayout: true,
                            }}
                        />
            </Content>
            <Modal
            title='Identifier Data ID'
            visible={isModalOpen}
            width={1000}
            onCancel={handleCancel}
            footer={false}
        >
            <TableCategory/>
           
        </Modal>
        </Layout>
    );
};


