// drawerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DrawerState {
  isOpen: boolean;
  basemapUrl: string;
  isOpenModalBuy: boolean;
  isOpenModalQuote: boolean;
  isOpenCardMap: boolean;
  isOpenModalGetFree: boolean;
  isOpenModalTopUp: boolean;
  setGeom: any;
  geojsonData: any;
  geoLink: any;
}

const initialState: DrawerState = {
  isOpen: false,
  isOpenModalBuy: false,
  isOpenModalQuote: false,
  isOpenModalGetFree: false,
  isOpenModalTopUp: false,
  basemapUrl: 'https://a.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png', // URL basemap default
  isOpenCardMap: false,
  setGeom: null,
  geojsonData: null,
  geoLink: null
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer(state) {
      state.isOpen = true;
    },
    closeDrawer(state) {
      state.isOpen = false;
    },
    openModalBuy(state) {
      state.isOpenModalBuy = true;
    },
    closeModalBuy(state) {
      state.isOpenModalBuy = false;
    },
    openModalQuote(state) {
      state.isOpenModalQuote = true;
    },
    closeModalQuote(state) {
      state.isOpenModalQuote = false;
    },
    openModalFreedata(state) {
      state.isOpenModalGetFree = true;
    },
    closeModalFreedata(state) {
      state.isOpenModalGetFree = false;
    },
    setBaseMaps: (state, action: PayloadAction<any>) => {
      state.basemapUrl = action.payload;
    },
    openCardMap(state) {
      state.isOpenCardMap = true;
    },
    closeCardMap(state) {
      state.isOpenCardMap = false;
    },
    setGeomData:(state, action: PayloadAction<any>) => {
      state.setGeom = action.payload;;
    },
    setGeojsonData:(state, action: PayloadAction<any>) => {
      state.geojsonData = action.payload;;
    },
    setGeoLink:(state, action: PayloadAction<any>) => {
      state.geoLink = action.payload;;
    },
    openModalTopUp(state) {
      state.isOpenModalTopUp = true;
    },
    closeModalTopUp(state) {
      state.isOpenModalTopUp = false;
    },
  },
});

export const { 
  openDrawer,
  closeDrawer,
  openModalBuy,
  closeModalBuy,
  setBaseMaps ,
  openModalQuote,
  closeModalQuote,
  closeCardMap,
  openCardMap,
  setGeomData,
  setGeojsonData,
  setGeoLink,
  openModalFreedata,
  closeModalFreedata,
  openModalTopUp,
  closeModalTopUp
} = drawerSlice.actions;
export default drawerSlice.reducer;
