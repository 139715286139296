import React, { useState } from "react";
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
import Text from "../../../../style/styledComponent/Text";
import insightCard from '../../../../asesst/Icons/product/business-scene-top-view.jpg';
// import { IoIosPeople } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';
import { Title } from "../title/title";
import { TbGeometry } from "react-icons/tb";
import { GiDeliveryDrone } from "react-icons/gi";
// import { BsShop } from "react-icons/bs";


const staticData= [
    {
        id: uuidv4(),
        title: 'Land Area Survey',
        description: 'A service to measure and document the physical size and boundaries of a specific property or land area, typically used for legal, construction, and design purposes.',
        icon: <GiDeliveryDrone size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Aerial Survey & Mapping'
    },
    {
        id: uuidv4(),
        title: 'Ortophoto',
        description: 'A detailed, aerial photograph of an area, corrected to remove distortions and provide an accurate top-down view, useful for mapping and planning.',
        icon: <GiDeliveryDrone size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Aerial Survey & Mapping'
    },
    {
        id: uuidv4(),
        title: '2D Mapping',
        description: 'This service provides a flat representation of a geographical area, showing natural and man-made features with accurate dimensions from a top-down perspective.',
        icon: <TbGeometry size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Land/Topography Survey'
    },
    {
        id: uuidv4(),
        title: '3D Mapping',
        description: 'Creates three-dimensional depictions of structures and terrain to help visualize landscapes and buildings for planning, construction, and navigation.',
        icon: <TbGeometry size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Land/Topography Survey'
    },
    {
        id: uuidv4(),
        title: 'BIM Survey',
        description: 'A Building Information Modeling survey that collects data on a buildings physical and functional characteristics, supporting the management of building information in 3D digital formats.',
        icon: <TbGeometry size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Land/Topography Survey'
    },
   
];
const categoriesData = [
    { name: 'Topography Survey', icon: <TbGeometry size={20} /> },
    { name: 'Aerial Survey & Mapping', icon: <GiDeliveryDrone size={20} /> },
];

export const CardCatalogServices = () => {
    const [selectedCategories, setSelectedCategories] = useState<string[]>(categoriesData.map(cat => cat.name));

    const handleCheckboxChange = (category: string) => {
        setSelectedCategories(prevState =>
            prevState.includes(category)
                ? prevState.filter(cat => cat !== category)
                : [...prevState, category]
        );
    };

    const filteredData = staticData.filter(item => selectedCategories.includes(item.category));


    return (
        <>
            <Title title="On-Demand Services" subTitle="Need data on the go? Our On-Demand Services specialize in rapid, precise data collection via surveys, ensuring you have the most accurate and up-to-date information at your fingertips." />

            <div style={{
                 animation: `1s ease-in 1s 1 both ${fadein}`,
            }}>
                    <div className="category-grid" >
                    {categoriesData.map(category => (
                        <div className="checkbox" key={category.name}>
                            <label className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    checked={selectedCategories.includes(category.name)}
                                    onChange={() => handleCheckboxChange(category.name)}
                                />
                                <span className="checkbox-tile">
                                    <span className="checkbox-icon">
                                        {category.icon}
                                    </span>
                                    <span className="checkbox-label">{category.name}</span>
                                </span>
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            <Container
                css={{
                    display: "grid",
                    width: "100%",
                    paddingTop: '20px',
                    animation: `1s ease-in 1s 1 both ${fadein}`,
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gap: "1rem",
                    flex: "1"
                }}
            >
                {filteredData.map(item => (
                    <Container
                        key={item.id}
                        css={{
                            backgroundColor: 'white',
                            padding: '1rem',
                            borderRadius: '8px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            flexDirection: 'column',
                            alignItems: 'left',
                            textAlign: 'left',
                        }}
                    >
                          <span className="card-icon">
                          {item.icon && item.icon}
                          </span>
                  
                        <Text
                            css={{
                                display: 'block',
                                headline4: '700',
                                marginBottom: '0.5rem',
                                color: '#3983e0',
                            }}
                        >
                            {item.title}
                        </Text>
                        <Text css={{ fontSize: '0.875rem', color: 'gray' }}>
                            {item.description}
                        </Text>
                    </Container>
                ))}
            </Container>
        </>
    );
};
