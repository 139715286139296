import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import Editor from '@monaco-editor/react';
import useReduxOneApi from '../../../../hoc/hooks/useOneApi';
const { Title } = Typography;

export const EditableTextAreaCatalog: React.FC = () => {

    const { responseApiPolygon } = useReduxOneApi();
    const [code, setCode] = useState(JSON.stringify(responseApiPolygon, null, 2));
  
    useEffect(() => {
      // Update code whenever responseApiPolygon changes
      setCode(
        // JSON.stringify(responseApiPolygon, null, 2)
        `
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
        
   fetch("https://api.geosquare.ai/api/data/people_millenials?key=7e6b03ed085873b933d8877e&bbox=106.82499,-6.183,106.825,-6.1829", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));`
    );
    }, [responseApiPolygon]);
    
  return (
    // <Card style={{ height: '450px' }}>
     <>

      <Title level={3}>Example Request</Title><Editor
        height="480px"
        language="javascript"
        theme="vs-dark"
        value={code}
        // onChange={handleEditorChange}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          automaticLayout: true,
        }} /></>
 
  );
};
