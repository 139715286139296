
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { RootState } from '../store/config.strore';

import { 
   startTour,
   nextStep,
   prevStep,
   endTour
 } from '../store/tour.store';

 export const tourActionStart = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(startTour());
  } catch (error) {
    console.error('Error starting tour:', error);
  }
};

export const tourActionNextStep = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(nextStep());
  } catch (error) {
    console.error('Error advancing tour step:', error);
  }
};

export const tourActionPrevStep = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(prevStep());
  } catch (error) {
    console.error('Error returning to previous tour step:', error);
  }
};

export const tourActionEnd = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(endTour());
  } catch (error) {
    console.error('Error ending tour:', error);
  }
};