import React from 'react';
import { Space, Button, Tooltip } from 'antd';
import { MdLocationSearching } from "react-icons/md";

interface DrawToolsSpaceProps {
    drawerOpen: boolean;
    onClickLocation: () => void; // Definisikan properti onOpenDrawer
}
  
export const GeoMapsTools: React.FC<DrawToolsSpaceProps> = ({ drawerOpen, onClickLocation }) => {

	return (
		<React.Fragment>
			
            <Space
				direction='vertical'
				style={{
					position: 'absolute',
                    left: drawerOpen ? '390px' : '15px',
					top: 125,
					zIndex: 100,
					transition: '0.25s ease-in-out',
				}}
			>
                  <Tooltip
                    title={<p style={{color:'black'}}>Location Polygon</p>}
					placement="right"
					color='white'
                >
			 <Button 
               id='feature-2'
                style={{
                    backgroundColor: 'white', // Ubah warna tombol menjadi putih
                    color: 'black', // Ubah warna teks menjadi hitam
                    height:"30px",
                    width:"32px",
                    display: 'flex',
                    justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                    alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                }}
                onClick={onClickLocation}
                icon={
					<span className='anticon'>
						<MdLocationSearching />
					</span>
				}
             >
            </Button>
            </Tooltip>
			</Space>
            </React.Fragment>
            )
}