import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { 
   setUserAction,
   clearUserAction,
   initializeAuth,
   modalLoginActionOpen,
   modalLoginActionClose,
   modalLoginActionCardClose,
   modalLoginActionCardOpen,
   getUser
    } from '../action/user.action';
import { useEffect } from 'react';

const useReduxUser = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
         user, 
         isloading,
         isLoggedIn,
         isLoginModalVisible,
         isLoginModalCardVisible,
         dataUser
        }  = useSelector((state: RootState) => state.user);

    const setUser = async (data: any) => {
        dispatch(setUserAction(data));
    };

    const clearUser = async () => {
        dispatch(clearUserAction());
    };

    useEffect(() => {
        dispatch(initializeAuth());
      }, [dispatch]);
      
      const modalLoginOpen = async (status: any) => {
        dispatch(modalLoginActionOpen(status));
    };

    const modalLoginClose = async (status: any) => {
        dispatch(modalLoginActionClose(status));
    };
    const modalLoginCardOpen = async (status: any) => {
        dispatch(modalLoginActionCardOpen(status));
    };

    const modalLoginCardClose = async (status: any) => {
        dispatch(modalLoginActionCardClose(status));
    };
    const getDetailUser = async (data: any) => {
        dispatch(getUser(data));
    };
    return {
        setUser, 
        clearUser,
        user,
        isloading,
        isLoggedIn,
        modalLoginOpen,
        modalLoginClose,
        isLoginModalVisible,
        modalLoginCardOpen,
        modalLoginCardClose,
        isLoginModalCardVisible,
        dataUser,
        getDetailUser
        
    }; // Return pixelState as well if needed
};

export default useReduxUser;
