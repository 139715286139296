import React, { useState } from "react";
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
import Text from "../../../../style/styledComponent/Text";
import insightCard from '../../../../asesst/Icons/product/business-scene-top-view.jpg';
import { IoIosPeople } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';
import { Title } from "../title/title";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import { FaRegAddressBook, FaBusinessTime } from "react-icons/fa";


const staticData= [
    {
        id: uuidv4(),
        title: 'Baby Boomer',
        description: 'Footfall of individuals born between 1946 and 1964, typically characterized by their experience of post-World War II prosperity and growth..',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    {
        id: uuidv4(),
        title: 'Gen X',
        description: 'Footfall of people born between 1965 and 1980, known for their adaptability, tech-savviness, and value on work-life balance.',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    {
        id: uuidv4(),
        title: 'Millenial',
        description: 'Footfall of individuals born between 1981 and 1996, known for their comfort with digital technology and value on experiences.',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    {
        id: uuidv4(),
        title: 'High Income',
        description: 'Footfall of individuals or households with income significantly above the average for the region or country.',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    {
        id: uuidv4(),
        title: 'Mid Income',
        description: 'Footfall of individuals or households with income around the median level for the region or country.',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    {
        id: uuidv4(),
        title: 'Low Income',
        description: 'Footfall of individuals or households with income significantly below the average for the region or country..',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    {
        id: uuidv4(),
        title: 'Male',
        description: 'Footfall of individuals born as male.',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    {
        id: uuidv4(),
        title: 'Female',
        description: 'Footfall of individuals born as female..',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    {
        id: uuidv4(),
        title: 'Family with Kids',
        description: 'Footfall of households with one or more children under 18 years of age..',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    {
        id: uuidv4(),
        title: 'Young Mother',
        description: 'Footfall of mothers typically in their late teens to early thirties.',
        icon: <IoIosPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'People Movement'
    },
    // adreess
    {
        id: uuidv4(),
        title: 'Business Accessibility',
        description: 'The ease of accessing business-related facilities and services in an area..',
        icon: <FaBusinessTime size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Accessibility'
    },
    {
        id: uuidv4(),
        title: 'Address',
        description: 'A new readable address system of squares with 5m absolute accuracy.',
        icon: <FaRegAddressBook size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Address'
    },
    {
        id: uuidv4(),
        title: 'Demographic Population',
        description: 'A map depicting population distribution in 50x50 sqm squares, highlighting the number of residents registered in each area.',
        icon: <FaArrowsDownToPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Demography'
    },
    {
        id: uuidv4(),
        title: 'Demographic Household',
        description: 'Distribution of households, with each 50x50 sqm square indicating the number of family units in the vicinity.',
        icon: <FaArrowsDownToPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Demography'
    },
    {
        id: uuidv4(),
        title: 'Demographic Baby Boomer',
        description: 'Distribution of Baby Boomer, the group born from 1946-1964, across the area in 50x50 sqm squares..',
        icon: <FaArrowsDownToPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Demography'
    },
    {
        id: uuidv4(),
        title: 'Demographic Gen X',
        description: 'Distribution of Generation X, the group born from 1965-1980, across the area in 50x50 sqm squares.',
        icon: <FaArrowsDownToPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Demography'
    },
    {
        id: uuidv4(),
        title: 'Demographic Baby Boomer',
        description: 'Distribution of Baby Boomer, the group born from 1946-1964, across the area in 50x50 sqm squares.',
        icon: <FaArrowsDownToPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Demography'
    },
    {
        id: uuidv4(),
        title: 'Demographic Household',
        description: 'Distribution of households, with each 50x50 sqm square indicating the number of family units in the vicinity.',
        icon: <FaArrowsDownToPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Demography'
    },
    {
        id: uuidv4(),
        title: 'Demographic Population',
        description: 'A map depicting population distribution in 50x50 sqm squares, highlighting the number of residents registered in each area..',
        icon: <FaArrowsDownToPeople size={30} />, // Uncomment if using this icon
        imgSrc: insightCard, // Uncomment if using another image,
        category: 'Demography'
    },

];

// const categories = ['People Movement', 'Address', 'Demography'];
const categoriesData = [
    { name: 'People Movement', icon: <IoIosPeople size={20} /> },
    { name: 'Accessibility', icon: <FaBusinessTime size={20} /> },
    { name: 'Address', icon: <FaRegAddressBook size={20} /> },
    { name: 'Demography', icon: <FaArrowsDownToPeople size={20} /> },
];

export const CardCatalogData = () => {
    const [selectedCategories, setSelectedCategories] = useState<string[]>(categoriesData.map(cat => cat.name));

    const handleCheckboxChange = (category: string) => {
        setSelectedCategories(prevState =>
            prevState.includes(category)
                ? prevState.filter(cat => cat !== category)
                : [...prevState, category]
        );
    };

    const filteredData = staticData.filter(item => selectedCategories.includes(item.category));


    return (
        <>
            <Title title="Analysis-Ready Dataset" subTitle="Say goodbye to data prep headaches! Our Analysis-Ready Datasets are prepped and polished, ready for you to plug in and play. Get straight to insights without the hassle." />

            <div style={{
                 animation: `1s ease-in 1s 1 both ${fadein}`,
            }}>
                {/* <fieldset className="checkbox-group"> */}
                {/* <legend className="checkbox-group-legend">Choose</legend> */}
                {/* <div style={{ display: 'flex', gap: '20px' }}> */}
                <div className="category-grid" >
                    {categoriesData.map(category => (
                        <div className="checkbox" key={category.name}>
                            <label className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    checked={selectedCategories.includes(category.name)}
                                    onChange={() => handleCheckboxChange(category.name)}
                                />
                                <span className="checkbox-tile">
                                    <span className="checkbox-icon">
                                        {category.icon}
                                    </span>
                                    <span className="checkbox-label">{category.name}</span>
                                </span>
                            </label>
                        </div>
                    ))}
                </div>

                {/* </fieldset> */}
            </div>

            <Container
                css={{
                    display: "grid",
                    width: "100%",
                    paddingTop: '20px',
                    animation: `1s ease-in 1s 1 both ${fadein}`,
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gap: "1rem",
                    flex: "1"
                }}
            >
                {filteredData.map(item => (
                    <Container
                        key={item.id}
                        css={{
                            backgroundColor: 'white',
                            padding: '1rem',
                            borderRadius: '8px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            flexDirection: 'column',
                            alignItems: 'left',
                            textAlign: 'left',
                        }}
                    >
                          <span className="card-icon">
                          {item.icon && item.icon}
                          </span>
                  
                        <Text
                            css={{
                                display: 'block',
                                headline4: '700',
                                marginBottom: '0.5rem',
                                color: '#3983e0',
                            }}
                        >
                            {item.title}
                        </Text>
                        <Text css={{ fontSize: '0.875rem', color: 'gray' }}>
                            {item.description}
                        </Text>
                    </Container>
                ))}
            </Container>
        </>
    );
};
