import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { 
   getFormDetail,
   postBuy
    } from '../action/transaction.action';

const useReduxTransaction = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
         formData, 
         formSubmitData
        }  = useSelector((state: RootState) => state.transaction);
// console.log("isloading",isloading);


    const setDataForm = async (data: any) => {
        dispatch(getFormDetail(data));
    };

    const setPostBuy = async (data: any) => {
        dispatch(postBuy(data));
    };

    return {
        formData, 
        setDataForm,
        formSubmitData,
        setPostBuy
        
    }; // Return pixelState as well if needed
};

export default useReduxTransaction;
