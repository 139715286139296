import React, { useEffect, useState } from 'react';
import { Button, message,
    //  Form, Input, 
     Modal } from 'antd';
import { FcGoogle } from 'react-icons/fc';
import { FaApple } from 'react-icons/fa';
import logo from '../../asesst/Icons/product/Geosquare-logo.png'; // Import your logo image
import useReduxUser from '../../hoc/hooks/useUser';
// import { useNavigate } from 'react-router-dom';
import {
    auth,
    googleProvider,
    appleProvider
} from '../../firebase/firebaseConfig';
import {
    onAuthStateChanged,
    // signInWithEmailAndPassword,
    signInWithPopup
} from "firebase/auth";
import useReduxDrawer from '../../hoc/hooks/useDrawer';

interface PopupModalProps {
    visible: boolean;
}

const ModalLogin: React.FC<PopupModalProps> = ({ visible }) => {
    const [loading, setLoading] = useState(false);
    const { setUser,modalLoginClose } = useReduxUser();
    const { ModalBuyOpen } = useReduxDrawer();
    // const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [setUser]);

    // const onFinish = async (values: { email: string; password: string }) => {
    //     const { email, password } = values;
    //     setLoading(true);
    //     try {
    //         await signInWithEmailAndPassword(auth, email, password);
    //         message.success('Login successful');
    //         navigate('/'); // Redirect to home page or dashboard
    //     } catch (error) {
    //         console.error('Error logging in:', error);
    //         message.error('Failed to login');
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    const handleGoogleLogin = async () => {
        setLoading(true);
        try {
            await signInWithPopup(auth, googleProvider);
            message.success('Login with Google successful');
            ModalBuyOpen(true)
            modalLoginClose(true)
        } catch (error) {
            console.error('Error logging in with Google:', error);
            message.error('Failed to login with Google');
        } finally {
            setLoading(false);
        }
    };
    const handleAppleLogin = async () => {
        setLoading(true);
        try {
            await signInWithPopup(auth, appleProvider);
            message.success('Login with Apple successful');
            ModalBuyOpen(true)
            modalLoginClose(true)
        } catch (error) {
            console.error('Error logging in with Apple:', error);
            message.error('Failed to login with Apple');
        } finally {
            setLoading(false);
        }
    };
    const handleCancel = () => {
        modalLoginClose(false);
    };
    return (
        <Modal
            visible={visible}
            onCancel={handleCancel}
            footer={false}
        >
            <div style={{ maxWidth: 300, margin: '0 auto', padding: '20px 0', textAlign: 'center' }}>
                <img src={logo} alt="Logo" style={{ width: '200px' }} />
                {/* <Form name="login" onFinish={onFinish}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input type="email" placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            Login
                        </Button>
                    </Form.Item>
                </Form> */}
                <Button onClick={handleGoogleLogin} loading={loading} block icon={<FcGoogle style={{ marginRight: 8 }} />} style={{
                    marginBottom: "1rem"
                }}>
                    Login with Google
                </Button>
                <Button onClick={handleAppleLogin} loading={loading} block icon={<FaApple style={{ marginRight: 8 }} />}>
                    Login with Apple
                </Button>
            </div>
        </Modal>
    );
};

export default ModalLogin;
