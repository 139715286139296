
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface OneApiState {
    catalogData: any | null;
    isloading: boolean; // Add loading property
    responseApiPoint: any | null;
    responseApiPolygon: any | null;
    apiKeys: any;
    listApiKeys: any;
    apilogs: any;
    error: boolean;
}

const initialState: OneApiState = {
    catalogData: null,
    isloading: true,
    responseApiPoint:null,
    responseApiPolygon:null,
    apiKeys: null,
    error: false,
    listApiKeys: null,
    apilogs: null

   
};

const oneApiSlice = createSlice({
  name: 'oneapi',
  initialState,
  reducers: {
    getDetailDataCatalog: (state, action: PayloadAction<any>) => {
        state.isloading = false; 
        state.catalogData = action.payload;
      },
    getResponseApiPoint: (state, action: PayloadAction<any>) => {
        state.responseApiPoint = action.payload;
      },
      getResponseApiPolygon: (state, action: PayloadAction<any>) => {
        state.responseApiPolygon = action.payload;
      },
    fetchDataStart: (state) => {
        state.isloading = true; // Set loading to true when fetching starts
        state.error = false;
      },
    fetchDataFailure: (state) => {
        state.isloading = false; 
        state.error = true;
      },
    fetchKeyDataSucces: (state, action: PayloadAction<any>) => {
          state.isloading = false; 
          state.apiKeys = action.payload;
    },
    fetchListKeyDataSucces: (state, action: PayloadAction<any>) => {
      state.isloading = false; 
      state.listApiKeys = action.payload;
    },
    fetchListApilogs: (state, action: PayloadAction<any>) => {
      state.isloading = false; 
      state.apilogs = action.payload;
    },
  },
});

export const { 
    getDetailDataCatalog,
    getResponseApiPoint,
    getResponseApiPolygon,
    fetchKeyDataSucces,
    fetchDataFailure,
    fetchDataStart,
    fetchListKeyDataSucces,
    fetchListApilogs
} = oneApiSlice.actions;

export default oneApiSlice.reducer;
