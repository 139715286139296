
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { RootState } from '../store/config.strore';
import ombenApi from '../../service/ombenAIApi'
import { 
  fetchDataSucces,
   fetchDataFailure,
   fetchDataStart
 } from '../store/prompt.store';

 export const promptText = ( dataPrompt: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    // console.log(dataPrompt);
    
    try {
     dispatch(fetchDataStart());
    
      const omben: any = await ombenApi.postPrompt(dataPrompt);
      dispatch(fetchDataSucces(omben)); 
  
    } catch (error) {
      console.error('Error fetching pixel data:', error);
      dispatch(fetchDataFailure());
    }
  };