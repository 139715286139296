import React from 'react';
import { Modal, Button } from 'antd';
import step1Image from '../../asesst/Icons/tour/tourmain.jpg'
interface PopupModalProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ModalTour: React.FC<PopupModalProps> = ({ visible, onConfirm, onCancel }) => {
  return (
    <Modal
    //   title="Begin Tour"
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onConfirm}>
          Start Tour
        </Button>,
      ]}
    >
         <img
            src={step1Image} // Replace with the actual image URL or import the image
            alt="Tour"
            style={{ width: '100%', marginBottom: '1rem' }}
      />
      <p>Explore the main features and navigate easily from the home screen?</p>
    </Modal>
  );
};

export default ModalTour;
