import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, message } from 'antd';
import useReduxDrawer from '../../hoc/hooks/useDrawer';
import useReduxTransaction from '../../hoc/hooks/useTransaction';
import { v4 as uuidv4 } from 'uuid';
import Text from '../../style/styledComponent/Text';
import dataCard from '../../asesst/Icons/product/IMGcheckout.png';
import useReduxUser from '../../hoc/hooks/useUser';
// import useReduxPayment from '../../hooks/usePayment';
import { saveFormDataToFirestore } from '../../firebase/firebase.action';

interface ModalProps {
  modalOpen: boolean;
}

const initialState = {
  name: '',
  email: '',
  number: '',
  item: '',
  data_id: '',
  polygon_id: '',
  output: '',
  output_status: '',
  order_id: '',
  totalPrice: 0,
  status_payment: 'waiting',
  status_data: 'process',
  type: '',
  category: '',
  totalUnit: 0,
  sizeGrid: 0,
  created_at: '', // Add date field
  deleted_at: '',
  updated_at: '',
  uid: '',
  status:'PAID',
  alias: '',
  source: '',
  year: '',
  version: '',
  externalId: '',
  amount:'',
  payerEmail: '',
  description: '',
  currency: 'IDR',
  invoiceDuration: 86400, 
};

export const ModalFreeData: React.FC<ModalProps> = ({ modalOpen }) => {
  const { isOpenModalGetFree, ModalFreeClose } = useReduxDrawer();
  const { formData } = useReduxTransaction();
  const currentDate = new Date().toISOString();
  const { user } = useReduxUser();
  const [form] = Form.useForm(); // Create a form instance
  const [formDataBuy, setFormDataBuy] = useState(initialState);

  const resetForm = () => {
    form.resetFields();
    setFormDataBuy(initialState); // Reset formDataBuy state to initialState
  };

  const handleCancel = () => {
    ModalFreeClose(false);
    resetForm()
  };


  useEffect(() => {
    const uuid = uuidv4();
    const invoiceId = `GSF-${uuid.split('-').pop()}`;
    setFormDataBuy({
      name: `${user?.displayName}`,
      email: `${user?.email}`,
      number: `${user?.phoneNumber || 0}`,
      item: formData?.alias,
      data_id: formData?.data_id,
      polygon_id: formData?.polygon_id,
      output: '',
      order_id: invoiceId,
      totalPrice: formData?.price?.total_price,
      type: formData?.type,
      category: formData?.category,
      status_payment: 'waiting',
      status_data: 'process',
      output_status: formData?.output_type?.length === 1 && formData.output_type[0] === 'getquote' ? 'getquote' : 'available',
      totalUnit: formData?.price?.total_pixel,
      sizeGrid: formData?.size,
      created_at: currentDate,
      deleted_at: '',
      updated_at: '',
      uid: `${user?.uid}`,
      status:'PAID',
      alias: formData?.alias,
      source: formData?.metadata?.source,
      year: formData?.metadata?.year,
      version: formData?.metadata?.version,
      externalId: invoiceId,
      amount: formData?.price?.total_price,
      payerEmail: `${user?.email}`,
      description: formData?.category,
      currency: 'IDR',
      invoiceDuration: 86400, 
    });
    // eslint-disable-next-line
  }, [formData, user]);

  const handleSubmit = async () => {
    try {
    await saveFormDataToFirestore(formDataBuy);
    message.success('success submit data, Our team will contact you');
    ModalFreeClose(false);
    } catch (error) {
      console.error('Failed to checkout data');
      message.error('Failed to checkout data. Please try again.');
    }
  };

  function isCurency(price: any) {
    if (price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }

  // Function to handle form submission
  const onFinish = (values: any) => {
    handleSubmit();
  };
  // Function to handle form submission failure
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Modal
        open={isOpenModalGetFree}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginLeft: '1rem', backgroundColor: '#3983e0', borderRadius: "8px", alignContent: 'center' }}>
            <Text css={{
              paddingLeft: '1rem',
              paddingTop: '0.5rem',
              width: "170px",
              display: 'block',
              fontSize: "25px",
              lineHeight: "2rem",
              fontWeight: 'bold',
              marginBottom: '0.5rem',
              color: 'white',
            }}>
              {formData?.type.toUpperCase()} CHECKOUT FORM
            </Text>
            <Text
              css={{
                display: 'block',
                fontSize: "0.675rem",
                paddingLeft: '1rem',
              }}
            >
              You will receive an email notification with download instructions, and the files will be available for download via the link
            </Text>
            <img
              src={dataCard}
              alt="Animated GIF"
              style={{
                maxWidth: "100%",
                maxHeight: "100%"
              }}
            />
          </div>
          <div style={{ flex: 1, marginLeft: '1rem' }}>
            <span style={{ fontSize: '1.2rem', textAlign: 'left', display: 'block', width: '100%', fontWeight: 'bold' }}>Item Detail</span>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              form={form}
              onFinishFailed={onFinishFailed}
              style={{ flex: 1 }}
            >
              <Form.Item label="Type" style={{ marginBottom: '5px' }}>
                <span>{formData?.type}</span>
              </Form.Item>
              <Form.Item label="Category" style={{ marginBottom: '5px' }}>
                <span>{formData?.category}</span>
              </Form.Item>
              <Form.Item label="Selected" style={{ marginBottom: '5px' }}>
                <span>{`${isCurency(formData?.price?.total_pixel)} Squared`}</span>
              </Form.Item>
              <Form.Item label="Size of Grid" style={{ marginBottom: '5px' }}>
                <span>{`${formData?.size} meters`}</span>
              </Form.Item>
              <Form.Item label="Estimated Price" style={{ marginBottom: '5px' }}>
                <span>{`Free`}</span>
              </Form.Item>
              <span style={{ fontSize: '1.2rem', textAlign: 'left', display: 'block', width: '100%', fontWeight: 'bold', paddingTop: '0.5rem' }}>Personal Information</span>
              <Form.Item label=" Name:" style={{ marginBottom: '5px' }}>
                <span>{user?.displayName}</span>
              </Form.Item>
              <Form.Item label=" Email:" style={{ marginBottom: '5px' }}>
                <span>{user?.email}</span>
              </Form.Item>
              <Form.Item label="Phone number:" style={{ marginBottom: '5px' }}>
                <span>{user?.phoneNumber || '-'}</span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <Button htmlType="submit" type="primary" style={{ marginRight: '10px' }}>
                    Submit
                  </Button>
                <Button onClick={handleCancel} style={{ marginLeft: '10px', color: 'white', backgroundColor: '#eb4034' }}>Cancel</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
