import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
    getCatalogAction,
    getCatalogGeometryAction,
    getCatalogLocationAction
} from '../action/catalog.action';


const useReduxCatalog = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
        catalog,
        paramsCatalogGeometry,
        location,
        isloading
    } = useSelector((state: RootState) => state.catalog);

    const setCatalog = async (params: string, geom: any) => {
        dispatch(getCatalogAction(params, geom));
    };
    const setCatalogGeometry = async (geom: any) => {
        dispatch(getCatalogGeometryAction(geom));
    };
    const setCatalogLocation = async (data: any) => {
        dispatch(getCatalogLocationAction(data));
    };
    
    return {
        setCatalog,
        setCatalogGeometry,
        setCatalogLocation,
        catalog,
        location,
        isloading,
        paramsCatalogGeometry
    }; // Return pixelState as well if needed

}

export default useReduxCatalog;