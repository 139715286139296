import React, { useState } from 'react';
import { Layout, Row, Card, Typography ,message, Modal, Col} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import postmanIcon from "../../asesst/Icons/product/postman.svg";
import { TableCategory } from '../../component/page/shared/table';
import Text from '../../style/styledComponent/Text';
import { Editor } from '@monaco-editor/react';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const EndpointDocumentationPageDownloadData: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
          message.success('URL copied to clipboard');
        });
      };
      const endpointUrl = 'https://api.geosquare.ai/api/data/order/downloadData/:order_id?key=[API_KEY]&format=GeoJSON';
    
      const handleDocsApi = () => {
        window.open('https://api-docs.geosquare.ai/#24b64279-10b2-4b29-9752-6ce8d46f7332', '_blank');
      };
    const  requestCode = `
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              106.73663428958235,
              -6.218307293805334
            ],
            [
              106.73618725268459,
              -6.22029653914168
            ],
            [
              106.73829471234536,
              -6.221037213673636
            ],
            [
              106.73803926268954,
              -6.220084917655153
            ],
            [
              106.73855016200123,
              -6.219132619911601
            ],
            [
              106.73663428958235,
              -6.218307293805334
            ]
          ]
        ]
      }
    }
  ],
  "page": 1,
  "perPage": 20
});

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://api.geosquare.ai/api/data/order/downloadData/GS-API-McTYiZbVIgPkjrnbA3D6BfkU1k?key=50e528f7b0fc2b787454fdf932bc0&format=GeoJSON", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
    `
    const responseCode = `
    {
        "type": "FeatureCollection",
        "name": "geosquare.ai_demography_bachelor_grad_GS-API-McTYiZbVIgPkjrnbA3D6BfkU1k",
        "crs": {
          "type": "name",
          "properties": {
            "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
          }
        },
        "features": [
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J2X7",
              "value": 3,
              "color": "#452442",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73755703344014,
                    -6.221372435837459
                  ],
                  [
                    106.73755703344014,
                    -6.220923278195404
                  ],
                  [
                    106.73800619108219,
                    -6.220923278195404
                  ],
                  [
                    106.73800619108219,
                    -6.221372435837459
                  ],
                  [
                    106.73755703344014,
                    -6.221372435837459
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J2X8",
              "value": 1,
              "color": "#110c1f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73800619108219,
                    -6.221372435837459
                  ],
                  [
                    106.73800619108219,
                    -6.220923278195404
                  ],
                  [
                    106.73845534872424,
                    -6.220923278195404
                  ],
                  [
                    106.73845534872424,
                    -6.221372435837459
                  ],
                  [
                    106.73800619108219,
                    -6.221372435837459
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J737",
              "value": 3,
              "color": "#452442",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73576040287192,
                    -6.220474120553348
                  ],
                  [
                    106.73576040287192,
                    -6.220024962911292
                  ],
                  [
                    106.73620956051397,
                    -6.220024962911292
                  ],
                  [
                    106.73620956051397,
                    -6.220474120553348
                  ],
                  [
                    106.73576040287192,
                    -6.220474120553348
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J738",
              "value": 2,
              "color": "#21172c",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73620956051397,
                    -6.220474120553348
                  ],
                  [
                    106.73620956051397,
                    -6.220024962911292
                  ],
                  [
                    106.73665871815602,
                    -6.220024962911292
                  ],
                  [
                    106.73665871815602,
                    -6.220474120553348
                  ],
                  [
                    106.73620956051397,
                    -6.220474120553348
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J742",
              "value": 1,
              "color": "#110c1f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73665871815604,
                    -6.220923278195404
                  ],
                  [
                    106.73665871815604,
                    -6.220474120553348
                  ],
                  [
                    106.73710787579809,
                    -6.220474120553348
                  ],
                  [
                    106.73710787579809,
                    -6.220923278195404
                  ],
                  [
                    106.73665871815604,
                    -6.220923278195404
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J743",
              "value": 6,
              "color": "#993d53",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73710787579809,
                    -6.220923278195404
                  ],
                  [
                    106.73710787579809,
                    -6.220474120553348
                  ],
                  [
                    106.73755703344014,
                    -6.220474120553348
                  ],
                  [
                    106.73755703344014,
                    -6.220923278195404
                  ],
                  [
                    106.73710787579809,
                    -6.220923278195404
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J747",
              "value": 3,
              "color": "#452442",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73665871815604,
                    -6.220474120553348
                  ],
                  [
                    106.73665871815604,
                    -6.220024962911292
                  ],
                  [
                    106.73710787579809,
                    -6.220024962911292
                  ],
                  [
                    106.73710787579809,
                    -6.220474120553348
                  ],
                  [
                    106.73665871815604,
                    -6.220474120553348
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J748",
              "value": 6,
              "color": "#993d53",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73710787579809,
                    -6.220474120553348
                  ],
                  [
                    106.73710787579809,
                    -6.220024962911292
                  ],
                  [
                    106.73755703344014,
                    -6.220024962911292
                  ],
                  [
                    106.73755703344014,
                    -6.220474120553348
                  ],
                  [
                    106.73710787579809,
                    -6.220474120553348
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J752",
              "value": 5,
              "color": "#6f304f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73755703344014,
                    -6.220923278195404
                  ],
                  [
                    106.73755703344014,
                    -6.220474120553348
                  ],
                  [
                    106.73800619108219,
                    -6.220474120553348
                  ],
                  [
                    106.73800619108219,
                    -6.220923278195404
                  ],
                  [
                    106.73755703344014,
                    -6.220923278195404
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J753",
              "value": 5,
              "color": "#6f304f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73800619108219,
                    -6.220923278195404
                  ],
                  [
                    106.73800619108219,
                    -6.220474120553348
                  ],
                  [
                    106.73845534872424,
                    -6.220474120553348
                  ],
                  [
                    106.73845534872424,
                    -6.220923278195404
                  ],
                  [
                    106.73800619108219,
                    -6.220923278195404
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J757",
              "value": 6,
              "color": "#993d53",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73755703344014,
                    -6.220474120553348
                  ],
                  [
                    106.73755703344014,
                    -6.220024962911292
                  ],
                  [
                    106.73800619108219,
                    -6.220024962911292
                  ],
                  [
                    106.73800619108219,
                    -6.220474120553348
                  ],
                  [
                    106.73755703344014,
                    -6.220474120553348
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J758",
              "value": 5,
              "color": "#6f304f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73800619108219,
                    -6.220474120553348
                  ],
                  [
                    106.73800619108219,
                    -6.220024962911292
                  ],
                  [
                    106.73845534872424,
                    -6.220024962911292
                  ],
                  [
                    106.73845534872424,
                    -6.220474120553348
                  ],
                  [
                    106.73800619108219,
                    -6.220474120553348
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J783",
              "value": 3,
              "color": "#452442",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73620956051397,
                    -6.220024962911293
                  ],
                  [
                    106.73620956051397,
                    -6.219575805269239
                  ],
                  [
                    106.73665871815602,
                    -6.219575805269239
                  ],
                  [
                    106.73665871815602,
                    -6.220024962911293
                  ],
                  [
                    106.73620956051397,
                    -6.220024962911293
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J788",
              "value": 2,
              "color": "#21172c",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73620956051397,
                    -6.219575805269239
                  ],
                  [
                    106.73620956051397,
                    -6.219126647627183
                  ],
                  [
                    106.73665871815602,
                    -6.219126647627183
                  ],
                  [
                    106.73665871815602,
                    -6.219575805269239
                  ],
                  [
                    106.73620956051397,
                    -6.219575805269239
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J792",
              "value": 5,
              "color": "#6f304f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73665871815604,
                    -6.220024962911293
                  ],
                  [
                    106.73665871815604,
                    -6.219575805269239
                  ],
                  [
                    106.73710787579809,
                    -6.219575805269239
                  ],
                  [
                    106.73710787579809,
                    -6.220024962911293
                  ],
                  [
                    106.73665871815604,
                    -6.220024962911293
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J793",
              "value": 6,
              "color": "#993d53",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73710787579809,
                    -6.220024962911293
                  ],
                  [
                    106.73710787579809,
                    -6.219575805269239
                  ],
                  [
                    106.73755703344014,
                    -6.219575805269239
                  ],
                  [
                    106.73755703344014,
                    -6.220024962911293
                  ],
                  [
                    106.73710787579809,
                    -6.220024962911293
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J797",
              "value": 2,
              "color": "#21172c",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73665871815604,
                    -6.219575805269239
                  ],
                  [
                    106.73665871815604,
                    -6.219126647627183
                  ],
                  [
                    106.73710787579809,
                    -6.219126647627183
                  ],
                  [
                    106.73710787579809,
                    -6.219575805269239
                  ],
                  [
                    106.73665871815604,
                    -6.219575805269239
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J798",
              "value": 3,
              "color": "#452442",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73710787579809,
                    -6.219575805269239
                  ],
                  [
                    106.73710787579809,
                    -6.219126647627183
                  ],
                  [
                    106.73755703344014,
                    -6.219126647627183
                  ],
                  [
                    106.73755703344014,
                    -6.219575805269239
                  ],
                  [
                    106.73710787579809,
                    -6.219575805269239
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7C2",
              "value": 4,
              "color": "#5a2a49",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73755703344014,
                    -6.220024962911293
                  ],
                  [
                    106.73755703344014,
                    -6.219575805269239
                  ],
                  [
                    106.73800619108219,
                    -6.219575805269239
                  ],
                  [
                    106.73800619108219,
                    -6.220024962911293
                  ],
                  [
                    106.73755703344014,
                    -6.220024962911293
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7C3",
              "value": 3,
              "color": "#452442",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73800619108219,
                    -6.220024962911293
                  ],
                  [
                    106.73800619108219,
                    -6.219575805269239
                  ],
                  [
                    106.73845534872424,
                    -6.219575805269239
                  ],
                  [
                    106.73845534872424,
                    -6.220024962911293
                  ],
                  [
                    106.73800619108219,
                    -6.220024962911293
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7C7",
              "value": 1,
              "color": "#110c1f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73755703344014,
                    -6.219575805269239
                  ],
                  [
                    106.73755703344014,
                    -6.219126647627183
                  ],
                  [
                    106.73800619108219,
                    -6.219126647627183
                  ],
                  [
                    106.73800619108219,
                    -6.219575805269239
                  ],
                  [
                    106.73755703344014,
                    -6.219575805269239
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7C8",
              "value": 2,
              "color": "#21172c",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73800619108219,
                    -6.219575805269239
                  ],
                  [
                    106.73800619108219,
                    -6.219126647627183
                  ],
                  [
                    106.73845534872424,
                    -6.219126647627183
                  ],
                  [
                    106.73845534872424,
                    -6.219575805269239
                  ],
                  [
                    106.73800619108219,
                    -6.219575805269239
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7E7",
              "value": 6,
              "color": "#993d53",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73845534872426,
                    -6.219575805269239
                  ],
                  [
                    106.73845534872426,
                    -6.219126647627183
                  ],
                  [
                    106.73890450636631,
                    -6.219126647627183
                  ],
                  [
                    106.73890450636631,
                    -6.219575805269239
                  ],
                  [
                    106.73845534872426,
                    -6.219575805269239
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7G3",
              "value": 4,
              "color": "#5a2a49",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73620956051397,
                    -6.219126647627183
                  ],
                  [
                    106.73620956051397,
                    -6.218677489985128
                  ],
                  [
                    106.73665871815602,
                    -6.218677489985128
                  ],
                  [
                    106.73665871815602,
                    -6.219126647627183
                  ],
                  [
                    106.73620956051397,
                    -6.219126647627183
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7G8",
              "value": 5,
              "color": "#6f304f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73620956051397,
                    -6.218677489985128
                  ],
                  [
                    106.73620956051397,
                    -6.218228332343072
                  ],
                  [
                    106.73665871815602,
                    -6.218228332343072
                  ],
                  [
                    106.73665871815602,
                    -6.218677489985128
                  ],
                  [
                    106.73620956051397,
                    -6.218677489985128
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7H2",
              "value": 6,
              "color": "#993d53",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73665871815604,
                    -6.219126647627183
                  ],
                  [
                    106.73665871815604,
                    -6.218677489985128
                  ],
                  [
                    106.73710787579809,
                    -6.218677489985128
                  ],
                  [
                    106.73710787579809,
                    -6.219126647627183
                  ],
                  [
                    106.73665871815604,
                    -6.219126647627183
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7H3",
              "value": 2,
              "color": "#21172c",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73710787579809,
                    -6.219126647627183
                  ],
                  [
                    106.73710787579809,
                    -6.218677489985128
                  ],
                  [
                    106.73755703344014,
                    -6.218677489985128
                  ],
                  [
                    106.73755703344014,
                    -6.219126647627183
                  ],
                  [
                    106.73710787579809,
                    -6.219126647627183
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7H7",
              "value": 5,
              "color": "#6f304f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73665871815604,
                    -6.218677489985128
                  ],
                  [
                    106.73665871815604,
                    -6.218228332343072
                  ],
                  [
                    106.73710787579809,
                    -6.218228332343072
                  ],
                  [
                    106.73710787579809,
                    -6.218677489985128
                  ],
                  [
                    106.73665871815604,
                    -6.218677489985128
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7H8",
              "value": 3,
              "color": "#452442",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73710787579809,
                    -6.218677489985128
                  ],
                  [
                    106.73710787579809,
                    -6.218228332343072
                  ],
                  [
                    106.73755703344014,
                    -6.218228332343072
                  ],
                  [
                    106.73755703344014,
                    -6.218677489985128
                  ],
                  [
                    106.73710787579809,
                    -6.218677489985128
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7J2",
              "value": 2,
              "color": "#21172c",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73755703344014,
                    -6.219126647627183
                  ],
                  [
                    106.73755703344014,
                    -6.218677489985128
                  ],
                  [
                    106.73800619108219,
                    -6.218677489985128
                  ],
                  [
                    106.73800619108219,
                    -6.219126647627183
                  ],
                  [
                    106.73755703344014,
                    -6.219126647627183
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7J3",
              "value": 2,
              "color": "#21172c",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73800619108219,
                    -6.219126647627183
                  ],
                  [
                    106.73800619108219,
                    -6.218677489985128
                  ],
                  [
                    106.73845534872424,
                    -6.218677489985128
                  ],
                  [
                    106.73845534872424,
                    -6.219126647627183
                  ],
                  [
                    106.73800619108219,
                    -6.219126647627183
                  ]
                ]
              ]
            }
          },
          {
            "type": "Feature",
            "properties": {
              "gid": "J3N2M753J7L2",
              "value": 1,
              "color": "#110c1f",
              "line_color": "#e69431"
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [
                    106.73845534872426,
                    -6.219126647627183
                  ],
                  [
                    106.73845534872426,
                    -6.218677489985128
                  ],
                  [
                    106.73890450636631,
                    -6.218677489985128
                  ],
                  [
                    106.73890450636631,
                    -6.219126647627183
                  ],
                  [
                    106.73845534872426,
                    -6.219126647627183
                  ]
                ]
              ]
            }
          }
        ]
      }
    `
    return (
        <Layout style={{ padding: '24px', minHeight: '100vh', background: '#fff' }}>
            <Content>
                <Title level={3}>Download Data Documentation</Title>
                <Row gutter={16} style={{ marginBottom: '24px' }}>
                <Col span={16}>
                <Card style={{ marginTop: '24px' }}>
                    <Title level={3}>Endpoint Documentation</Title>
                    {/* <Paragraph> */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom:'10px' }}>
                        
                            <Text css={{fontWeight:700, color:'Green', marginRight:'10px'}}>GET</Text>
                    
                        {endpointUrl}
                            <CopyOutlined
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                                onClick={() => copyToClipboard(endpointUrl)}
                            />
                        </div>
                    {/* </Paragraph> */}
                    
                    <Paragraph>
                        <strong>Parameters:</strong>
                        <ul>
                            <li><strong>key:</strong> This query parameter is an API key or access token required for authorization.</li>
                            <li><strong>format:</strong> This query parameter is an format  for type you want and it is required for download request.</li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        <strong>Path Variable:</strong>
                        <ul>
                            <li><strong>order_id:</strong> This is a path parameter that specifies the unique identifier of the order object you want to. You can get order_id from response after create order.</li>
                          
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        <strong>Headers:</strong>
                        <ul>
                            <li><strong>Content-Type:</strong> application/json</li>
                        </ul>
                    <Title level={2}>Full Documentation </Title>
                    <Row gutter={16} style={{ marginBottom: '24px' }}>
                        <Card style={{ textAlign: 'center', borderColor: '#ff6c37', cursor:'pointer' }} onClick={handleDocsApi}>
                            <img
                                src={postmanIcon}
                                alt="Postman"
                                style={{ width: '50px', height: '50px', marginBottom: '16px' }}
                            />
                            <p style={{ color: '#ff6c37', fontWeight: 'bold' }}>
                                Go to full documentation
                            </p>
                        </Card>
                    </Row>
                    </Paragraph>
                   
                </Card>
                </Col>
                <Col span={8}>
                <Title level={4}>Example Request</Title>
                <Editor
                            height="480px"
                            language="javascript"
                            theme="vs-dark"
                            value={requestCode}
                            // onChange={handleEditorChange}
                            options={{
                            minimap: { enabled: false },
                            scrollBeyondLastLine: false,
                            automaticLayout: true,
                            }}
                        />
                    </Col>
                </Row>
                <Title level={4}>Example Response</Title>
                        <Editor
                            height="600px"
                            language="json"
                            theme="vs-dark"
                            value={responseCode}
                            // onChange={handleEditorChange}
                            options={{
                            minimap: { enabled: false },
                            scrollBeyondLastLine: false,
                            automaticLayout: true,
                            }}
                        />
            </Content>
            <Modal
            title='Identifier Data ID'
            visible={isModalOpen}
            width={1000}
            onCancel={handleCancel}
            footer={false}
        >
            <TableCategory/>
           
        </Modal>
        </Layout>
    );
};


