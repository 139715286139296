import {api, catalogApi} from './api';
import { PixelData } from '../interface'

const getCategoryPixel = async (): Promise<PixelData> => {
  const url: string = '/api/data/categories/pixel_data';
  const { data } = await api.get<PixelData>(url);
  return data;
};
const getSubCategoryPixel = async (id: string): Promise<PixelData> => {
	const url: string = `/api/data/subcategories?id_category=${id}`;
	const { data } = await api.get<PixelData>(url);
	return data;
  };

  const getCatalog = async (): Promise<PixelData> => {
    const url: string = '/api/catalog';
    const { data } = await catalogApi.get<any>(url);
    return data;
  };

  const drawPixelDataApi = async (params:string, RequestBody: any): Promise<any> => {
// console.log(RequestBody.coordinates);
  const coordinates = RequestBody.coordinates
	const url: string = `/api/catalog/available-data`;
	const dataFeatures = { 
    features: [{
       type: "Feature",
       properties: {},
       geometry: {
         type: "Polygon",
         coordinates
        } }],
        grouping: true,
        // page : 1,
        // perPage: 20,
        type: params 
      };

	const { data } = await api.post<any>(url, dataFeatures);
	// console.log(data);
	return data;
  };

  const resultPixelById = async (id: string, data_id: string): Promise<any> => {
    const url: string = `/api/data/tiles/${id}/${data_id}`;
    const { data } = await api.get<any>(url);
    // console.log(data);
    
    return data;
  }

const pixelApi = {
  getCategoryPixel,
  getSubCategoryPixel,
  drawPixelDataApi,
  resultPixelById,
  getCatalog
};

export default pixelApi;
