import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import {
    setLoadingData,
    setProgresData
} from '../action/map.action';

const useReduxMap = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
        isLoadingData,
        progressData
    } = useSelector((state: RootState) => state.map);

    const setIsloadingData = async (status: any) => {
        dispatch(setLoadingData(status));
    };
    const setProgressData = async (status: any) => {
        dispatch(setProgresData(status));
    };
    return {
        isLoadingData,
        setIsloadingData,
        setProgressData,
        progressData
    };
};

export default useReduxMap;