
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { RootState } from '../store/config.strore';
import {
  openDrawer,
  closeDrawer,
  setBaseMaps,
  closeModalBuy,
  openModalBuy,
  openModalQuote,
  closeModalQuote,
  openCardMap,
  closeCardMap,
  setGeomData,
  setGeojsonData,
  setGeoLink,
  openModalFreedata,
  closeModalFreedata,
  closeModalTopUp,
  openModalTopUp
} from '../store/drawTools.store';

export const drawerActionOpen = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(openDrawer(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const drawerActionClose = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(closeDrawer(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const setActiveBaseMaps = (value: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(setBaseMaps(value));
  } catch (error) {
    console.error('Error change basemaps:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const modalBuyActionOpen = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(openModalBuy(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const modalButActionClose = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(closeModalBuy(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const modalFreeActionOpen = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(openModalFreedata(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const modalFreeActionClose = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(closeModalFreedata(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};


export const modalQuotectionOpen = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(openModalQuote(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const modalQuoteActionClose = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(closeModalQuote(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const cardMapActionOpen = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(openCardMap(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const cardMapActionClose = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(closeCardMap(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const setGeomDatas = (value: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(setGeomData(value));
  } catch (error) {
    console.error('Error change basemaps:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const setGeojsonDatas = (value: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(setGeojsonData(value));
  } catch (error) {
    console.error('Error change basemaps:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const setGeoLinks = (value: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(setGeoLink(value));
  } catch (error) {
    console.error('Error change basemaps:', error);
    // dispatch(fetchPixelDataFailure());
  }
  
};
export const modalTopActionOpen = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(openModalTopUp(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const modalTopActionClose = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(closeModalTopUp(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};