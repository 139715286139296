
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { RootState } from '../store/config.strore';
import {
  setUser,
  clearUser,
  openModalLogin,
  closeModalLogin,
  closeModalCardLogin,
  openModalCardLogin,
  getUserDetail
} from '../store/user.store';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase/firebaseConfig';
import { getUserById } from '../../firebase/firebase.action';

export const setUserAction = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(setUser(data));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const clearUserAction = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(clearUser());
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const modalLoginActionOpen = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(openModalLogin(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const modalLoginActionClose = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(closeModalLogin(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const modalLoginActionCardOpen = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(openModalCardLogin(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};
export const modalLoginActionCardClose = (status: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(closeModalCardLogin(status));
  } catch (error) {
    console.error('Error fetching pixel data:', error);
    // dispatch(fetchPixelDataFailure());
  }
};

export const initializeAuth = (): ThunkAction<void, RootState, unknown, Action<string>> => (dispatch) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(setUserAction(user));
    } else {
      dispatch(setUserAction(null));
    }
  });
};


export const getUser = (token: string): ThunkAction<Promise<any>, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    const topUp = await getUserById(token);
    if (topUp !== null) {
      dispatch(getUserDetail(topUp));
    } else {
      console.error('TopUp data is null');
    }
    return topUp; // Pastikan data dikembalikan
  } catch (error) {
    console.error('Error fetching topup data:', error);
    throw error; // Pastikan error dilemparkan
  }
};