import React from 'react';
import { DrawerSideMenu } from '../../shared/map/drawer';
import iconCatalog from '../../asesst/Icons/interactivemap/maps.svg';
import iconAi from '../../asesst/Icons/interactivemap/ai.svg';
import iconChart from '../../asesst/Icons/interactivemap/icon/cart.svg';
import iconLayer from '../../asesst/Icons/interactivemap/mydata.svg';
interface SideMenuProps {
  map: maplibregl.Map | null; // Pass the map instance as a prop
}

export const SideMenu: React.FC<SideMenuProps> = ({map}) => {
  const menuItems = [
    { icon: iconCatalog, label: 'Catalogue' },
    { icon: iconLayer, label: 'My Data' },
    { icon: iconAi, label: 'AI Prompt' },
    { icon: iconChart, label: 'Chart' },
  ];

  return (
    <>
      <DrawerSideMenu map={map} menuItems={menuItems} />
    </>
  );
};
