// Loader.js
import { Progress } from 'antd';
import React from 'react';

interface LoaderProgressProps {
    percent: any;
}
export const LoaderProgress : React.FC<LoaderProgressProps> = ({ percent }) => {
  return (
    <div className="loader-progress">
            <Progress percent={percent} />
    </div>
  );
};

