import React, {  useState } from 'react';
import { Modal, Form, Input, Button, InputNumber } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";
import useReduxDrawer from '../../hoc/hooks/useDrawer';
import useReduxTransaction from '../../hoc/hooks/useTransaction';
import { v4 as uuidv4 } from 'uuid';
import {  saveQuoteDataToFirestore } from '../../firebase/firebase.action';
import { message } from 'antd';
import Text from '../../style/styledComponent/Text';
import dataCard from '../../asesst/Icons/product/IMGcheckout.png';
import useReduxUser from '../../hoc/hooks/useUser';
const { TextArea } = Input;


interface ModalProps {
    modalOpen: boolean;
    // dataBuy: any
}
const initialState = {
    name: '',
    email: '',
    number: '',
    item: '',
    type: '',
    category: '',
    order_id: '',
    notes: '',
    status_data: 'process',
    created_at:'',  // Add date field
    deleted_at:'',
    updated_at:'',
    uid:'',
    geom: [],
    totalUnit: 0,
};
export const ModalQuote: React.FC<ModalProps> = ({ modalOpen }) => {
    const { isOpenModalQuote, ModalQuoteClose, setGeom } = useReduxDrawer();
    const { formData } = useReduxTransaction();
    const currentDate = new Date().toISOString();
    const { user } = useReduxUser();

    const [form] = Form.useForm(); // Create a form instance
    const [formDataQuote, setFormDataQuote] = useState(initialState);
    const isFormFilled = () => {
        return (
            formDataQuote.name !== '' &&
            formDataQuote.email !== '' &&
            formDataQuote.number !== '' &&
            formDataQuote.notes !== ''

        );
    };

    const generateUUID = () => {
        return uuidv4();
    };

    const handleChangeText = (e: any) => {
        const { name, value } = e.target;
        setFormDataQuote({
            ...formDataQuote,
            [name]: value,
            item: formData?.alias,
            type: formData?.type,
            category: formData?.category,
            order_id: generateUUID(),
            created_at:currentDate,  // Add date field
            deleted_at:'',
            updated_at:'',
            uid: `${user?.uid}`,
            geom: setGeom,
            totalUnit: formData?.price?.total_pixel,
        });
    };

    const handleChange = (name: any, value: any | null) => {
        setFormDataQuote({
            ...formDataQuote,
            [name]: value,
            item: formData?.alias,
            type: formData?.type,
            category: formData?.category,
            order_id: generateUUID(),
            created_at:currentDate,  // Add date field
            deleted_at:'',
            updated_at:'',
            uid: `${user?.uid}`,
            geom: setGeom,
            totalUnit: formData?.price?.total_pixel,

        });
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // console.log(formDataQuote);

        try {
            await saveQuoteDataToFirestore(formDataQuote);
            message.success('success submit data, Our team will contact you');
            setFormDataQuote({
                name: '',
                email: '',
                number: '',
                type: '',
                category: '',
                item: '',
                order_id: '',
                notes: '',
                status_data: 'process',
                created_at:currentDate,  // Add date field
                deleted_at:'',
                updated_at:'',
                uid: `${user?.uid}`,
                geom: setGeom,
                totalUnit: formData?.price?.total_pixel,
            });
            //  form.resetFields(); // Reset the form fields
            // Tambahkan logika untuk menutup modal di sini
            ModalQuoteClose(false); // Contoh: Menutup modal setelah berhasil
            form.resetFields(); // Reset the form fields
        } catch (error) {
            console.error('Failed to checkout data');
            // Tambahkan logika untuk menangani kesalahan jika diperlukan
        }
    };
    const handleCancel = () => {
        ModalQuoteClose(false);
    };



    // Function to handle form submission
    const onFinish = (values: any) => {
        // console.log('Form submitted:', values);
        ModalQuoteClose(false);
    };

    // Function to handle form submission failure
    const onFinishFailed = (errorInfo: any) => {
        // console.log('Failed:', errorInfo);
    };


    function isCurency(price: any) {
        if (price) {
            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    }
    return (
        <div>
            <Modal
                // title={<span style={{ color: 'white', textAlign: 'center', width: '100%', display: 'block', backgroundColor: '#3489eb', padding: '10px 0' }}> {formData?.type.toUpperCase()} - Request Form</span>}
                open={isOpenModalQuote}
                onCancel={handleCancel}
                footer={null}
                width={800}
            >
                {/* <span style={{ fontSize: '0.8rem', textAlign: 'center', padding: '10px', display: 'block', width: '100%' }}>Our team is ready to learn more about your business needs.
                    Contact us today using the form below.</span> */}

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 1, marginLeft: '1rem', backgroundColor: '#3983e0',  borderRadius: "8px", alignContent:'center'}}>
                <Text css={{
                            paddingLeft: '1rem',
                            paddingTop:'0.5rem',
                            width: "170px",
                            display: 'block',
                            fontSize: "25px",
                            lineHeight:"2rem",
                            fontWeight:'bold',
                            marginBottom: '0.5rem',
                            color: 'white',
                        }}>
                            {formData?.type.toUpperCase()} REQUEST FORM
                        </Text>
                        <Text
                        css={{
                            display: 'block',
                            fontSize: "0.675rem",
                            paddingLeft: '1rem',
                        }}
                        >
                            You will receive an email notification with download instructions, and the files will be available for download via the link
                        </Text>
                        <img
                        src={dataCard} // Ganti dengan URL gambar GIF Anda
                        alt="Animated GIF"
                        style={{
                            alignItems:'center',
                            maxWidth: "100%",
                            maxHeight: "100%"
                        }}
                    />
                    {/* <span style={{ fontSize: '1.2rem', textAlign: 'left', display: 'block', width: '100%', fontWeight: 'bold' }}>Personal Information</span> */}
                        {/* <Form
                            form={form} // Set the form instance
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            style={{ flex: 1, marginRight: '1rem' }}
                        >
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Please input your name!' }]}
                            >
                                <Input name="name"
                                    value={formDataQuote.name}
                                    onChange={handleChangeText} // 
                                    placeholder="name" prefix={<UserOutlined />} />
                            </Form.Item>
                            <Form.Item
                                name="number"
                                rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <InputNumber
                                    onChange={(value) => handleChange("number", value)} // 
                                    name="number"
                                    value={formDataQuote.number}
                                    style={{ width: '100%' }} placeholder="phone number" prefix={<IoIosPhonePortrait />} />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input
                                    onChange={handleChangeText} name="email" value={formDataQuote.email} placeholder="email" prefix={<MdOutlineAlternateEmail />} />
                            </Form.Item>

                        </Form> */}

                    </div>
                    <div style={{ flex: 1, marginLeft: '1rem' }}>
                        <span style={{ fontSize: '1.2rem', textAlign: 'left', display: 'block', width: '100%',fontWeight: 'bold' }}>Item Detail</span>
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            style={{ flex: 1 }}
                        >
                            <Form.Item label="Type" style={{ marginBottom: '5px' }}>
                                <span>{formData?.type}</span>
                            </Form.Item>
                            <Form.Item label="Category" style={{ marginBottom: '5px' }}>
                                <span>{formData?.category}</span>
                            </Form.Item>
                            <Form.Item label="Selected" style={{ marginBottom: '5px' }}>
                            <span>{formData?.price?.total_pixel ? `${isCurency(formData.price.total_pixel)}  Squared` : '-'}</span>

                            </Form.Item>
                            <Form.Item label= {formData?.type === 'Survey' ?"Estimated Price": "Total Price"} style={{ marginBottom: '5px' }}>
                            <span style={{color:"#3489eb",fontSize: 15, fontWeight: 'bold'}}>{formData?.price?.total_price ? `Rp.${isCurency(formData.price.total_price)}` : '-'}</span>

                            </Form.Item>
                        </Form>
                        <span style={{ fontSize: '1.2rem', textAlign: 'left', display: 'block', width: '100%', fontWeight: 'bold' }}>Personal Information</span>
                        <Form
                            form={form} // Set the form instance
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            style={{ flex: 1, marginRight: '1rem' }}
                        >
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Please input your name!' }]}
                            >
                                <Input name="name"
                                    value={formDataQuote.name}
                                    onChange={handleChangeText} // 
                                    placeholder="name" prefix={<UserOutlined />} />
                            </Form.Item>
                            <Form.Item
                                name="number"
                                rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <InputNumber
                                    onChange={(value) => handleChange("number", value)} // 
                                    name="number"
                                    value={formDataQuote.number}
                                    style={{ width: '100%' }} placeholder="phone number" prefix={<IoIosPhonePortrait />} />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input
                                    onChange={handleChangeText} name="email" value={formDataQuote.email} placeholder="email" prefix={<MdOutlineAlternateEmail />} />
                            </Form.Item>

                        </Form>
                        <span style={{ fontSize: '1.2rem', textAlign: 'center', display: 'block', width: '100%' }}>Tell us the detail about your special request!</span>
                        <Form
                            name="notes"
                            initialValues={{ remember: false }}
                            style={{ flex: 1, marginRight: '1rem', }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name="notes"
                                rules={[{ required: true, message: 'Please input your notes!' }]}
                            >
                                <TextArea
                                    rows={5}
                                    onChange={handleChangeText} name="notes" value={formDataQuote.notes} placeholder="notes" />
                            </Form.Item>
                        </Form>
                        <Form.Item style={{ marginBottom: 0, display: 'flex', justifyContent: 'center', padding: '10px' }}>
                    <Button disabled={!isFormFilled()} onClick={handleSubmit} type="primary" style={{ marginRight: '10px' }}>Submit</Button>
                    <Button onClick={handleCancel} style={{ marginLeft: '10px', color: 'white', backgroundColor: '#eb4034' }}>Cancel</Button>
                </Form.Item>
                    </div>
                </div>

                {/* <span style={{ fontSize: '1.2rem', textAlign: 'center', display: 'block', width: '100%' }}>Tell us the detail about your special request!</span>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
                    <div style={{ flex: 1, marginLeft: '1rem' }}>
                        <Form
                            name="notes"
                            initialValues={{ remember: false }}
                            style={{ flex: 1, marginRight: '1rem', }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name="notes"
                                rules={[{ required: true, message: 'Please input your notes!' }]}
                            >
                                <TextArea
                                    rows={5}
                                    onChange={handleChangeText} name="notes" value={formDataQuote.notes} placeholder="notes" />
                            </Form.Item>
                        </Form>

                    </div>

                </div> */}

                {/* <Form.Item style={{ marginBottom: 0, display: 'flex', justifyContent: 'center', padding: '10px' }}>
                    <Button disabled={!isFormFilled()} onClick={handleSubmit} type="primary" style={{ marginRight: '10px' }}>Submit</Button>
                    <Button onClick={handleCancel} style={{ marginLeft: '10px', color: 'white', backgroundColor: '#eb4034' }}>Cancel</Button>
                </Form.Item> */}
            </Modal>
        </div>
    );
};

