import MapboxDraw from '@mapbox/mapbox-gl-draw';
export interface GeoJSONPolygon {
    type: 'Polygon';
    coordinates: number[][][];
}

export interface GeoJSONFeature {
    type: 'Feature';
    geometry: GeoJSONPolygon;
    properties: Record<string, any>;
}


export const initializeMapboxDraw = (): MapboxDraw => {
    return new MapboxDraw({
        displayControlsDefault: false,
        styles: [
            {
                id: "gl-draw-polygon-fill",
                type: "fill",
                paint: {
                    "fill-color": "#4A7FC0",
                    "fill-opacity": 0.1
                }
            },
            {
                id: "gl-draw-polygon-stroke-active",
                type: "line",
                layout: {
                    "line-cap": "round",
                    "line-join": "round"
                },
                paint: {
                    "line-color": "#4A7FC0",
                    "line-dasharray": [0.2, 0.2],
                    "line-width": 1
                }
            },
            {
                id: "gl-draw-polygon-and-line-vertex-active",
                type: "circle",
                paint: {
                    "circle-radius": 3,
                    "circle-color": "#4A7FC0",
                }
            }
        ]
    });
};


export const handleDrawClick = (map: maplibregl.Map | null, draw: MapboxDraw): any | null => {
    if (!map) return null;

    if (!(map as any).hasControl(draw)) {
        (map as any).addControl(draw);
    }

    draw.deleteAll();
    draw.changeMode('draw_polygon');
};

export const removeDrawControl = (map: maplibregl.Map | null, draw: MapboxDraw) => {
    if (map && (map as any).hasControl(draw)) {
        (map as any).removeControl(draw);
    }
};
