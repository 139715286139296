
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { RootState } from '../store/config.strore';
import { 
    getDetailData,
    postDataBuy
 } from '../store/transaction.store';

  export const getFormDetail = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
      try {
        dispatch(getDetailData(data)); 
      } catch (error) {
        console.error('Error fetching pixel data:', error);
        // dispatch(fetchPixelDataFailure());
      }
    };

    export const postBuy = (data: any): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
        try {
          dispatch(postDataBuy(data)); 
        } catch (error) {
          console.error('Error fetching pixel data:', error);
          // dispatch(fetchPixelDataFailure());
        }
      };




