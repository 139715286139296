import React, { useEffect, useState } from 'react';
import { Card, Space, Tooltip, message, Empty } from 'antd';
import useReduxUser from '../../hoc/hooks/useUser';
import useReduxDrawer from '../../hoc/hooks/useDrawer';
import { getAllGeoTransactionsSuccessdata } from '../../firebase/firebase.action';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import {
  //  loadParquet, 
  loadParquetBatch } from '../../utils/parquet';
import iconRetails from "../../asesst/Icons/product/Insigh-Minimarket Retail.png";
import iconGeneral from "../../asesst/Icons/product/General.png";
import iconFinaceBanking from "../../asesst/Icons/product/Finance and Banking.png";
import iconProperty from "../../asesst/Icons/product/Insigh-Property Selection.png";
import iconPeople from "../../asesst/Icons/product/Data & Image-People demography.png";
import iconAddress from "../../asesst/Icons/product/Address.png";
import iconAccesibility from "../../asesst/Icons/product/Data & Image-Accessibility rev.png";
import iconDemograph from "../../asesst/Icons/product/Data & Image-People demography.png";
import iconPlanning from "../../asesst/Icons/product/Data & Image-zone planning rev.png";
import iconSafety from "../../asesst/Icons/product/Safety.png";
import iconEconomics from "../../asesst/Icons/product/Economics.png";
import iconASM from "../../asesst/Icons/product/Service-Aerial survey.png";
import * as togeojson from '@tmcw/togeojson';
import iconlts from "../../asesst/Icons/product/Service-Land survey.png";

import { IoCloudUploadOutline,IoGridOutline } from "react-icons/io5";
import { TbCalendarTime } from "react-icons/tb";

import useReduxMap from '../../hoc/hooks/useMap';
import PetainGrid from '../../utils/petain-grid/petain-grid';
import { LegendColors } from '../../utils/legendType/legend-type';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  name: string;
  id: string;
  category: string;
  item: any;
  year:number | null,
  sizeGrid:number | null,
  createdAt: any | null
  isActive: boolean;
}
export const legendType = [
  'people',
  'demography',
  'safety',
  'food_beverage',
  'retail',
  'amenity',
  'planning',
  'hi-res-demography',
  'accessibility',
  'address'
  // Add more types as needed
] as const;

type LegendType = typeof legendType[number];

export const CardListData: React.FC<any> = () => {
  const { user } = useReduxUser();
  const [data, setData] = useState<any[]>([]);
  const [activeCardId, setActiveCardId] = useState<string | null>(null);
  const { setIsloadingData, setProgressData } = useReduxMap();
  const { setGeoJsonData,setGeoLink } = useReduxDrawer();
  const [
    file, 
    // setFile
  ] = useState<File | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const geoTransactions = await getAllGeoTransactionsSuccessdata(user?.email); // Use user.uid instead of user.email
          const formattedData = geoTransactions.map((doc: any) => ({
            key: doc.id,
            ...doc,
          }));
          setData(formattedData);

        } catch (error) {
          console.error('Failed to fetch geo transactions:', error);
        } finally {
          setIsloadingData(false);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  // console.log(data);


  const getCategoryImage = (category: any) => {
    switch (category) {
      case 'finance_banking':
        return iconFinaceBanking// replace with your image paths
      case 'food_beverage':
        return iconRetails
      case 'general':
        return iconGeneral
      case 'amenity':
        return iconSafety
      case 'property':
        return iconProperty// replace with your image paths
      case 'accessibility':
        return iconAccesibility
      case 'address':
        return iconAddress
      case 'economics':
        return iconEconomics
      case 'people':
        return iconPeople// replace with your image paths
      case 'planning':
        return iconPlanning
      case 'safety':
        return iconSafety
      case 'aerial_survey_mapping':
        return iconASM
      case 'land_topography_survey':
        return iconlts
      case 'demography':
        return iconDemograph
      default:
        return iconGeneral
    }
  }

  const getLegendColor = (value: any, legendColors: any[]) => {
    const colorItem = legendColors.find((item: { value: any; }) => item.value === value);
    return colorItem ? colorItem.color : '#ffffff'; // Default color if not found
  }
  const onClickShowData = async (item: any) => {
    try {
      setIsloadingData(true);
      setProgressData(0);
      const timeStart = new Date().getTime();
      const storage = getStorage();
      const grid_lib = new PetainGrid();
      const legendColors = LegendColors[item.category as LegendType] || [];

      if (item.table_ready) {
        const listRef = ref(storage, item?.table_location);
        // console.log(listRef);
        
        const listItem = await listAll(listRef);
        // console.log(listItem);
        const listUrl = await Promise.all(listItem.items.map(async (itemRef) => await getDownloadURL(itemRef)));

        // Start interval to update progress based on elapsed time
        const intervalId = setInterval(() => {
          const timeNow = new Date().getTime();
          const elapsedTime = timeNow - timeStart;
          const estimatedTotalTime = 10000; // Assume it takes 5 seconds to complete (example)
          const calculatedProgress = Math.min(100, Math.floor((elapsedTime / estimatedTotalTime) * 100));
          setProgressData(calculatedProgress);
        }, 100);

        const data = await loadParquetBatch(listUrl);

        clearInterval(intervalId); // Clear the interval once the data is loaded

        const geoJSON = data.map((item: any) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [grid_lib._gid_to_geometry(item.gid)],
            },
            properties: item,
          };
        });

        setProgressData(100);
        message.success(`success to load data`);
        const dataGeo = {
          type: 'FeatureCollection',
          features: geoJSON,
        };

         dataGeo.features.forEach(feature => {
          const value = feature.properties.value;
          feature.properties.color = getLegendColor(value, legendColors);
        });
        // setGeoJsonData(dataGeo);
        setGeoLink(item)
      }
      setIsloadingData(false);
    } catch (error) {
      message.error('Failed to fetch data, token has expired');
      setIsloadingData(false);
      setProgressData(0);  // Reset progress on error
    }
  }

  const CardMapList: React.FC<CardProps> = ({ name, category, item, id, isActive, year, sizeGrid, createdAt   }) => {

    const formatDate = (isoString: any) => {
      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = date.toLocaleString('default', { month: 'long' }); // Get month name
      const year = String(date.getFullYear()).slice(); // Get last two digits of the year
      return `${day} ${month} ${year}`;
  };    
    const containerStyle: React.CSSProperties = {
      backgroundColor: isActive ? '#c9d4d3' : '#fff',
      color: isActive ? '#000' : '#000',
      padding: '10px',
      alignItems: 'center',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease',
      cursor: 'pointer',
    };
  
    const imageStyle: React.CSSProperties = {
      width: '50px',
      height: '50px',
      marginRight: '10px',
      borderRadius: '5px',
      backgroundColor: '#d9d9d9', // Placeholder background color for the image
    };
  
    const textContainerStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    };
  
    const rowStyle: React.CSSProperties = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    };
  
    const colStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    };
  
    const colStyleCategory: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      alignContent:'center',
      paddingLeft:'5px',
      marginLeft: 'auto', // Make sure this div pushes to the far right
    };
  
    const textStyle: React.CSSProperties = {
      marginBottom: '0px',
      fontWeight: 'bold',
      textAlign:'left',
      fontSize: '16px',
      alignSelf: 'flex-start',
    };
  
    const handleCardClick = () => {
      setActiveCardId((prevId) => (prevId === id ? null : id));
      onClickShowData(item);
    };
  
    const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
      const card = event.currentTarget;
      card.style.transform = 'translateY(-5px)';
      card.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
    };
  
    const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
      const card = event.currentTarget;
      card.style.transform = 'translateY(0)';
      card.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
    };
  
    return (
      
      <Card
        style={containerStyle}
        bodyStyle={{ padding: 0, borderRadius: '0 0 8px 8px' }}
        onClick={handleCardClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div style={textContainerStyle}>
          <div style={rowStyle}>
            <img alt="example" src={getCategoryImage(category)} style={imageStyle} />
            <div style={colStyle}>
              <div style={textStyle}>{name}</div>
              <div style={textStyle}>{category}</div>
              <div style={{alignSelf: 'flex-start',}}>created: {formatDate(createdAt)}</div>
            </div>
            <div style={colStyleCategory}>
              <div><TbCalendarTime size={18} /> {year || '-'}</div>
              <div><IoGridOutline  size={18} /> {sizeGrid +'m' } </div>
            </div>
          </div>
        </div>
      </Card>
    );
  };
  
  const boxContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    // marginTop: '16px',
  };

  const boxStyle = {
    flexGrow: 1,
    padding: '5px',
    border: '1px solid #e8e8e8',
    cursor: 'pointer',
    borderRadius: '8px',
  };

  const boxIconStyle = {
    fontSize: '24px',
    marginLeft: '16px',
    cursor: 'pointer',
  };

  useEffect(() => {
    if (file) {
      setIsloadingData(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          try {
            const text = event.target.result as string;

            if (file.type === "application/vnd.google-earth.kml+xml" || file.name.endsWith(".kml")) {
              // Convert KML to GeoJSON
              const xml = new DOMParser().parseFromString(text, 'text/xml');
              const geojson = togeojson.kml(xml);
              setGeoJsonData(geojson);
              message.success('success upload data!');
            } else if (file.type === "application/geo+json" || file.name.endsWith(".geojson")) {
              // Parse GeoJSON directly
              const data = JSON.parse(text);
              setGeoJsonData(data);
              message.success('success upload data!');
            } else {
              throw new Error("Unsupported file format");
            }
          } catch (error) {
            console.error('Error parsing file:', error);
            message.error(`Error parsing file,${error}`);
          } finally {
            setIsloadingData(false);
          }
        }
      };
      reader.readAsText(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   message.error('feature is undermaintenance')
  //   if (event.target.files && event.target.files.length > 0) {
  //     // console.log(event.target.files[0]);
  //     setFile(event.target.files[0]);
  //   }
  // };
  const handleFileChange = () => {
    message.error('feature is undermaintenance')
  };

  const handleToDashboard = () => {
    navigate('/dashboard');
    window.location.reload();
}

  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <hr />
        <div style={boxContainerStyle}>
          <div style={boxStyle} onClick={handleToDashboard}>
            <h3>Dashboard</h3>
          </div>

          {/* <Space  */}
          <div onClick={handleFileChange}>
            <Tooltip
              title={<p style={{ color: 'black' }}>Upload Data</p>}
              placement="right"
              color='white'
            >
              {/* <input
              disabled
                type="file"
                accept=".geojson,.kml"
                // onChange={handleFileChange}
                
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0,
                  cursor: 'pointer',
                }}
              /> */}

              <IoCloudUploadOutline  style={boxIconStyle} />
            </Tooltip>
            </div>
          {/* </Space> */}
        </div>
        <hr />
        {data.length === 0 ? (
          <div style={{ textAlign: 'center', marginTop: '20px', color: '#888' }}>
            <Empty description=" Data Not Found" style={{ marginTop: '20px', marginBottom: '50px' }} />
          </div>
        ) : (
          data
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
          .map((item) => (
            <CardMapList
              isActive={activeCardId === item.id}
              sizeGrid={item?.sizeGrid || ''}
              createdAt={item?.created_at || ''}
              year={item?.year || ''}
              id={item.id}
              key={item.id}
              name={item.item}
              category={item.category}
              item={item}
            />
          ))
        )}
      </Space>
    </>
  )
}