import React from 'react';
import { MapProvider } from 'react-map-gl/maplibre';
// import _ from 'lodash';
import { Map } from '../component/componentMap';
import { XMapLayoutComponentLayerLoader } from '../component/componentLayerLoader';
import { SharedComponentDrawerTypeOne } from '../../../shared/drawer';
// import { DrawTools } from '../shared/drawtools';

export const XMapLayout: React.FC<{ children?: React.ReactNode }> = (props) => {
    return (
        <MapProvider>
            <Map>
                <XMapLayoutComponentLayerLoader>
                    {props.children}
                </XMapLayoutComponentLayerLoader>
            </Map>
            <SharedComponentDrawerTypeOne />
        </MapProvider>
    )
}