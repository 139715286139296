import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { CSSProperties } from 'react';
import Container from "../../../../../style/styledComponent/Container";
import { fadein } from "../../../../../style/keyframes";
import dataIcon from '../../../../../asesst/new/icon/mage_dashboard-plus.svg';
import bgImage from '../../../../../asesst/new/img/illustrationbg.svg';

const { Title, Text } = Typography;



const SectionGranullarMobile = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
            flexDirection: windowWidth <= 768 ? 'column' : 'row',
            textAlign: windowWidth <= 768 ? 'center' : 'left',

        },
        icon: {
            // marginRight: windowWidth <= 768 ? '' : '10px',
            // marginBottom: windowWidth <= 768 ? '10px' : '0',
            color: '#316DBA',
        },
        integrationText: {
            color: '#316DBA',
            marginRight: '10px',
            fontSize: '24px',
            fontWeight: '800',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: 'left',
        },
        title: {
            fontWeight: '800',  
            fontSize: windowWidth <= 768 ? '24px' : '38px',
            color: '#333',
            marginTop: '10px',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign:'left',
        },
        description: {
            fontFamily: '"Jakarta Sans", sans-serif',
            fontSize: '16px',
            color: '#5A5A5A',
            display: 'block',
            width: windowWidth <= 768 ? '100%' : '500px',
            lineHeight: '1.5',
            marginTop: '10px',
            textAlign:'left',
        },
        button: {
            width: '175px',
            height: '44px',
            marginTop: '20px',
            backgroundColor: '#316DBA',
            borderColor: '#316DBA',
            color: 'white',
        },
        mapContainer: {
            position: 'relative',
        },
        mapImage: {
            width: '100%',
            height: 'auto',
        },
        imageStyle: {
            width: '100%',
            height: 'auto',
        },
    };
    return (
        <Container
            dir={"column"}
            css={{
                opacity: "0",
                animation: `1s ease-in 1s 1 both ${fadein}`,
                width: "100%",
                padding: windowWidth <= 768 ? '10px 13%' : '20px 7%',
                backgroundColor: 'white',
                paddingRight: '50px', // Add padding to the right
                gridTemplateColumns: "1.5fr 1.5fr",
                '@media (max-width: 768px)': {
                    gridTemplateColumns: "1fr",
                    padding: "20px",
                }
            }}
        >
     
                <Container>
                    <Container
                        dir={"column"}
                        css={{
                            width: "100%",
                            height: "100%",
                            opacity: "0",
                            // padding: '50px 0',
                            animation: `1s ease-in 1s 1 both ${fadein}`,
                            alignItems:  'flex-start',
                        }}
                    >
                        {/* <div style={styles.integrationHeader}> */}
                            <img alt="example" src={dataIcon}  style={{margin:'0', paddingBottom:'10px'}}/>
                            <Text style={styles.integrationText}>Granular</Text>
                        {/* </div> */}

                        <Title style={styles.title}>Pay only for what you exactly need!</Title>
                        <Text style={styles.description}>
                            Access as small as a 5x5m area and<br></br> focus on just one theme at a time.
                        </Text>
                    </Container>
                </Container>
                <Container css={{ padding: '50px 0' }}>
                <img
                    src={bgImage}
                    alt="Animated GIF"
                    style={styles.imageStyle}
                />
            </Container>
        </Container>
    );
};

export default SectionGranullarMobile;
